/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {MStepAttributeEditDto} from './MStepAttributeEditDto';
import {MStepOutcomeCommunicationEditDto} from './MStepOutcomeCommunicationEditDto';
import {MStepOutcomeEditDto} from './MStepOutcomeEditDto';
import {MStepTextEditDto} from './MStepTextEditDto';
import {StatusType} from './StatusType';

/**
 * The MStepEditDto model module.
 * @module model/MStepEditDto
 * @version v3.76(UAT)
 */
export class MStepEditDto {
  /**
   * Constructs a new <code>MStepEditDto</code>.
   * @alias module:model/MStepEditDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>MStepEditDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MStepEditDto} obj Optional instance to populate.
   * @return {module:model/MStepEditDto} The populated <code>MStepEditDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MStepEditDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('isMandate'))
        obj.isMandate = ApiClient.convertToType(data['isMandate'], 'Boolean');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = StatusType.constructFromObject(data['status']);
      if (data.hasOwnProperty('isInternal'))
        obj.isInternal = ApiClient.convertToType(data['isInternal'], 'Boolean');
      if (data.hasOwnProperty('mStepText'))
        obj.mStepText = ApiClient.convertToType(data['mStepText'], [MStepTextEditDto]);
      if (data.hasOwnProperty('mStepAttribute'))
        obj.mStepAttribute = ApiClient.convertToType(data['mStepAttribute'], [MStepAttributeEditDto]);
      if (data.hasOwnProperty('mStepOutcome'))
        obj.mStepOutcome = ApiClient.convertToType(data['mStepOutcome'], [MStepOutcomeEditDto]);
      if (data.hasOwnProperty('mStepOutcomeCommunication'))
        obj.mStepOutcomeCommunication = ApiClient.convertToType(data['mStepOutcomeCommunication'], [MStepOutcomeCommunicationEditDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
MStepEditDto.prototype.id = undefined;

/**
 * @member {String} name
 */
MStepEditDto.prototype.name = undefined;

/**
 * @member {Boolean} isMandate
 */
MStepEditDto.prototype.isMandate = undefined;

/**
 * @member {String} description
 */
MStepEditDto.prototype.description = undefined;

/**
 * @member {module:model/StatusType} status
 */
MStepEditDto.prototype.status = undefined;

/**
 * @member {Boolean} isInternal
 */
MStepEditDto.prototype.isInternal = undefined;

/**
 * @member {Array.<module:model/MStepTextEditDto>} mStepText
 */
MStepEditDto.prototype.mStepText = undefined;

/**
 * @member {Array.<module:model/MStepAttributeEditDto>} mStepAttribute
 */
MStepEditDto.prototype.mStepAttribute = undefined;

/**
 * @member {Array.<module:model/MStepOutcomeEditDto>} mStepOutcome
 */
MStepEditDto.prototype.mStepOutcome = undefined;

/**
 * @member {Array.<module:model/MStepOutcomeCommunicationEditDto>} mStepOutcomeCommunication
 */
MStepEditDto.prototype.mStepOutcomeCommunication = undefined;

