/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The RSCandidateTrait model module.
 * @module model/RSCandidateTrait
 * @version v3.76(UAT)
 */
export class RSCandidateTrait {
  /**
   * Constructs a new <code>RSCandidateTrait</code>.
   * @alias module:model/RSCandidateTrait
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RSCandidateTrait</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RSCandidateTrait} obj Optional instance to populate.
   * @return {module:model/RSCandidateTrait} The populated <code>RSCandidateTrait</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RSCandidateTrait();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('weight'))
        obj.weight = ApiClient.convertToType(data['weight'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
RSCandidateTrait.prototype.id = undefined;

/**
 * @member {String} name
 */
RSCandidateTrait.prototype.name = undefined;

/**
 * @member {String} weight
 */
RSCandidateTrait.prototype.weight = undefined;

