/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {DepartmentDto} from './DepartmentDto';

/**
 * The RoleDto model module.
 * @module model/RoleDto
 * @version v3.76(UAT)
 */
export class RoleDto {
  /**
   * Constructs a new <code>RoleDto</code>.
   * @alias module:model/RoleDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RoleDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RoleDto} obj Optional instance to populate.
   * @return {module:model/RoleDto} The populated <code>RoleDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RoleDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('departmentId'))
        obj.departmentId = ApiClient.convertToType(data['departmentId'], 'Number');
      if (data.hasOwnProperty('department'))
        obj.department = DepartmentDto.constructFromObject(data['department']);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
RoleDto.prototype.id = undefined;

/**
 * @member {String} uniqueGuid
 */
RoleDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} name
 */
RoleDto.prototype.name = undefined;

/**
 * @member {String} description
 */
RoleDto.prototype.description = undefined;

/**
 * @member {Number} departmentId
 */
RoleDto.prototype.departmentId = undefined;

/**
 * @member {module:model/DepartmentDto} department
 */
RoleDto.prototype.department = undefined;

