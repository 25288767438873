/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CompanyDto} from './CompanyDto';
import {JobDescriptionDto} from './JobDescriptionDto';
import {MPathwayTypeDto} from './MPathwayTypeDto';
import {PathwayAttributeDto} from './PathwayAttributeDto';
import {PathwayOutcomeDto} from './PathwayOutcomeDto';
import {PathwayStepDto} from './PathwayStepDto';
import {SkillDto} from './SkillDto';
import {SkillLevel} from './SkillLevel';
import {StatusType} from './StatusType';

/**
 * The PathwayDto model module.
 * @module model/PathwayDto
 * @version v3.76(UAT)
 */
export class PathwayDto {
  /**
   * Constructs a new <code>PathwayDto</code>.
   * @alias module:model/PathwayDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PathwayDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PathwayDto} obj Optional instance to populate.
   * @return {module:model/PathwayDto} The populated <code>PathwayDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PathwayDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = StatusType.constructFromObject(data['status']);
      if (data.hasOwnProperty('isLive'))
        obj.isLive = ApiClient.convertToType(data['isLive'], 'Boolean');
      if (data.hasOwnProperty('isChargeable'))
        obj.isChargeable = ApiClient.convertToType(data['isChargeable'], 'Boolean');
      if (data.hasOwnProperty('amount'))
        obj.amount = ApiClient.convertToType(data['amount'], 'Number');
      if (data.hasOwnProperty('skillLevel'))
        obj.skillLevel = SkillLevel.constructFromObject(data['skillLevel']);
      if (data.hasOwnProperty('mPathwayTypeId'))
        obj.mPathwayTypeId = ApiClient.convertToType(data['mPathwayTypeId'], 'Number');
      if (data.hasOwnProperty('mPathwayType'))
        obj.mPathwayType = MPathwayTypeDto.constructFromObject(data['mPathwayType']);
      if (data.hasOwnProperty('skillId'))
        obj.skillId = ApiClient.convertToType(data['skillId'], 'Number');
      if (data.hasOwnProperty('skill'))
        obj.skill = SkillDto.constructFromObject(data['skill']);
      if (data.hasOwnProperty('jdId'))
        obj.jdId = ApiClient.convertToType(data['jdId'], 'Number');
      if (data.hasOwnProperty('jobDescription'))
        obj.jobDescription = JobDescriptionDto.constructFromObject(data['jobDescription']);
      if (data.hasOwnProperty('companyId'))
        obj.companyId = ApiClient.convertToType(data['companyId'], 'Number');
      if (data.hasOwnProperty('company'))
        obj.company = CompanyDto.constructFromObject(data['company']);
      if (data.hasOwnProperty('pathwayStep'))
        obj.pathwayStep = ApiClient.convertToType(data['pathwayStep'], [PathwayStepDto]);
      if (data.hasOwnProperty('pathwayAttribute'))
        obj.pathwayAttribute = ApiClient.convertToType(data['pathwayAttribute'], [PathwayAttributeDto]);
      if (data.hasOwnProperty('pathwayOutcome'))
        obj.pathwayOutcome = ApiClient.convertToType(data['pathwayOutcome'], [PathwayOutcomeDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
PathwayDto.prototype.id = undefined;

/**
 * @member {String} uniqueGuid
 */
PathwayDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} title
 */
PathwayDto.prototype.title = undefined;

/**
 * @member {String} description
 */
PathwayDto.prototype.description = undefined;

/**
 * @member {module:model/StatusType} status
 */
PathwayDto.prototype.status = undefined;

/**
 * @member {Boolean} isLive
 */
PathwayDto.prototype.isLive = undefined;

/**
 * @member {Boolean} isChargeable
 */
PathwayDto.prototype.isChargeable = undefined;

/**
 * @member {Number} amount
 */
PathwayDto.prototype.amount = undefined;

/**
 * @member {module:model/SkillLevel} skillLevel
 */
PathwayDto.prototype.skillLevel = undefined;

/**
 * @member {Number} mPathwayTypeId
 */
PathwayDto.prototype.mPathwayTypeId = undefined;

/**
 * @member {module:model/MPathwayTypeDto} mPathwayType
 */
PathwayDto.prototype.mPathwayType = undefined;

/**
 * @member {Number} skillId
 */
PathwayDto.prototype.skillId = undefined;

/**
 * @member {module:model/SkillDto} skill
 */
PathwayDto.prototype.skill = undefined;

/**
 * @member {Number} jdId
 */
PathwayDto.prototype.jdId = undefined;

/**
 * @member {module:model/JobDescriptionDto} jobDescription
 */
PathwayDto.prototype.jobDescription = undefined;

/**
 * @member {Number} companyId
 */
PathwayDto.prototype.companyId = undefined;

/**
 * @member {module:model/CompanyDto} company
 */
PathwayDto.prototype.company = undefined;

/**
 * @member {Array.<module:model/PathwayStepDto>} pathwayStep
 */
PathwayDto.prototype.pathwayStep = undefined;

/**
 * @member {Array.<module:model/PathwayAttributeDto>} pathwayAttribute
 */
PathwayDto.prototype.pathwayAttribute = undefined;

/**
 * @member {Array.<module:model/PathwayOutcomeDto>} pathwayOutcome
 */
PathwayDto.prototype.pathwayOutcome = undefined;

