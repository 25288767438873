/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {ClientAndAddressEditDto} from './ClientAndAddressEditDto';

/**
 * The ClientEditDto model module.
 * @module model/ClientEditDto
 * @version v3.76(UAT)
 */
export class ClientEditDto {
  /**
   * Constructs a new <code>ClientEditDto</code>.
   * @alias module:model/ClientEditDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ClientEditDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ClientEditDto} obj Optional instance to populate.
   * @return {module:model/ClientEditDto} The populated <code>ClientEditDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ClientEditDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('gstNumber'))
        obj.gstNumber = ApiClient.convertToType(data['gstNumber'], 'String');
      if (data.hasOwnProperty('isBranched'))
        obj.isBranched = ApiClient.convertToType(data['isBranched'], 'Boolean');
      if (data.hasOwnProperty('webSite'))
        obj.webSite = ApiClient.convertToType(data['webSite'], 'String');
      if (data.hasOwnProperty('companySize'))
        obj.companySize = ApiClient.convertToType(data['companySize'], 'String');
      if (data.hasOwnProperty('dateOfOnBoarding'))
        obj.dateOfOnBoarding = ApiClient.convertToType(data['dateOfOnBoarding'], 'Date');
      if (data.hasOwnProperty('typeOfService'))
        obj.typeOfService = ApiClient.convertToType(data['typeOfService'], 'String');
      if (data.hasOwnProperty('isActive'))
        obj.isActive = ApiClient.convertToType(data['isActive'], 'Boolean');
      if (data.hasOwnProperty('businessVerticalId'))
        obj.businessVerticalId = ApiClient.convertToType(data['businessVerticalId'], 'Number');
      if (data.hasOwnProperty('companyProfile'))
        obj.companyProfile = ApiClient.convertToType(data['companyProfile'], 'String');
      if (data.hasOwnProperty('clientAddresses'))
        obj.clientAddresses = ApiClient.convertToType(data['clientAddresses'], [ClientAndAddressEditDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
ClientEditDto.prototype.id = undefined;

/**
 * @member {String} name
 */
ClientEditDto.prototype.name = undefined;

/**
 * @member {String} gstNumber
 */
ClientEditDto.prototype.gstNumber = undefined;

/**
 * @member {Boolean} isBranched
 */
ClientEditDto.prototype.isBranched = undefined;

/**
 * @member {String} webSite
 */
ClientEditDto.prototype.webSite = undefined;

/**
 * @member {String} companySize
 */
ClientEditDto.prototype.companySize = undefined;

/**
 * @member {Date} dateOfOnBoarding
 */
ClientEditDto.prototype.dateOfOnBoarding = undefined;

/**
 * @member {String} typeOfService
 */
ClientEditDto.prototype.typeOfService = undefined;

/**
 * @member {Boolean} isActive
 */
ClientEditDto.prototype.isActive = undefined;

/**
 * @member {Number} businessVerticalId
 */
ClientEditDto.prototype.businessVerticalId = undefined;

/**
 * @member {String} companyProfile
 */
ClientEditDto.prototype.companyProfile = undefined;

/**
 * @member {Array.<module:model/ClientAndAddressEditDto>} clientAddresses
 */
ClientEditDto.prototype.clientAddresses = undefined;

