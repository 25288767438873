/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {MStepOutcomeDto} from './MStepOutcomeDto';
import {PathwayStepOutcomeCommunicationDto} from './PathwayStepOutcomeCommunicationDto';

/**
 * The PathwayStepOutcomeDto model module.
 * @module model/PathwayStepOutcomeDto
 * @version v3.76(UAT)
 */
export class PathwayStepOutcomeDto {
  /**
   * Constructs a new <code>PathwayStepOutcomeDto</code>.
   * @alias module:model/PathwayStepOutcomeDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PathwayStepOutcomeDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PathwayStepOutcomeDto} obj Optional instance to populate.
   * @return {module:model/PathwayStepOutcomeDto} The populated <code>PathwayStepOutcomeDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PathwayStepOutcomeDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('pathwayStepId'))
        obj.pathwayStepId = ApiClient.convertToType(data['pathwayStepId'], 'Number');
      if (data.hasOwnProperty('stepOutcomeId'))
        obj.stepOutcomeId = ApiClient.convertToType(data['stepOutcomeId'], 'Number');
      if (data.hasOwnProperty('stepOutcome'))
        obj.stepOutcome = MStepOutcomeDto.constructFromObject(data['stepOutcome']);
      if (data.hasOwnProperty('pathwayStepOutcomeCommunication'))
        obj.pathwayStepOutcomeCommunication = ApiClient.convertToType(data['pathwayStepOutcomeCommunication'], [PathwayStepOutcomeCommunicationDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
PathwayStepOutcomeDto.prototype.id = undefined;

/**
 * @member {String} uniqueGuid
 */
PathwayStepOutcomeDto.prototype.uniqueGuid = undefined;

/**
 * @member {Number} pathwayStepId
 */
PathwayStepOutcomeDto.prototype.pathwayStepId = undefined;

/**
 * @member {Number} stepOutcomeId
 */
PathwayStepOutcomeDto.prototype.stepOutcomeId = undefined;

/**
 * @member {module:model/MStepOutcomeDto} stepOutcome
 */
PathwayStepOutcomeDto.prototype.stepOutcome = undefined;

/**
 * @member {Array.<module:model/PathwayStepOutcomeCommunicationDto>} pathwayStepOutcomeCommunication
 */
PathwayStepOutcomeDto.prototype.pathwayStepOutcomeCommunication = undefined;

