/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The PageResult model module.
 * @module model/PageResult
 * @version v3.76(UAT)
 */
export class PageResult {
  /**
   * Constructs a new <code>PageResult</code>.
   * @alias module:model/PageResult
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PageResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PageResult} obj Optional instance to populate.
   * @return {module:model/PageResult} The populated <code>PageResult</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PageResult();
      if (data.hasOwnProperty('currentPage'))
        obj.currentPage = ApiClient.convertToType(data['currentPage'], 'Number');
      if (data.hasOwnProperty('totalPages'))
        obj.totalPages = ApiClient.convertToType(data['totalPages'], 'Number');
      if (data.hasOwnProperty('pageSize'))
        obj.pageSize = ApiClient.convertToType(data['pageSize'], 'Number');
      if (data.hasOwnProperty('totalCount'))
        obj.totalCount = ApiClient.convertToType(data['totalCount'], 'Number');
      if (data.hasOwnProperty('hasPrevious'))
        obj.hasPrevious = ApiClient.convertToType(data['hasPrevious'], 'Boolean');
      if (data.hasOwnProperty('hasNext'))
        obj.hasNext = ApiClient.convertToType(data['hasNext'], 'Boolean');
      if (data.hasOwnProperty('previousPage'))
        obj.previousPage = ApiClient.convertToType(data['previousPage'], 'String');
      if (data.hasOwnProperty('nextPage'))
        obj.nextPage = ApiClient.convertToType(data['nextPage'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} currentPage
 */
PageResult.prototype.currentPage = undefined;

/**
 * @member {Number} totalPages
 */
PageResult.prototype.totalPages = undefined;

/**
 * @member {Number} pageSize
 */
PageResult.prototype.pageSize = undefined;

/**
 * @member {Number} totalCount
 */
PageResult.prototype.totalCount = undefined;

/**
 * @member {Boolean} hasPrevious
 */
PageResult.prototype.hasPrevious = undefined;

/**
 * @member {Boolean} hasNext
 */
PageResult.prototype.hasNext = undefined;

/**
 * @member {String} previousPage
 */
PageResult.prototype.previousPage = undefined;

/**
 * @member {String} nextPage
 */
PageResult.prototype.nextPage = undefined;

