/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {ClientInterviewRoundCompactDtoApiResponse} from '../model/ClientInterviewRoundCompactDtoApiResponse';
import {ClientInterviewRoundCompactDtoIEnumerableApiResponse} from '../model/ClientInterviewRoundCompactDtoIEnumerableApiResponse';
import {ClientInterviewRoundCompactDtoPagedListApiResponse} from '../model/ClientInterviewRoundCompactDtoPagedListApiResponse';
import {ClientInterviewRoundCreateDto} from '../model/ClientInterviewRoundCreateDto';
import {ClientInterviewRoundDtoApiResponse} from '../model/ClientInterviewRoundDtoApiResponse';
import {ClientInterviewRoundDtoIEnumerableApiResponse} from '../model/ClientInterviewRoundDtoIEnumerableApiResponse';
import {ClientInterviewRoundEditDto} from '../model/ClientInterviewRoundEditDto';

/**
* ClientInterviewRound service.
* @module api/ClientInterviewRoundApi
* @version v3.76(UAT)
*/
export class ClientInterviewRoundApi {

    /**
    * Constructs a new ClientInterviewRoundApi. 
    * @alias module:api/ClientInterviewRoundApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiClientInterviewRoundGet operation.
     * @callback moduleapi/ClientInterviewRoundApi~apiClientInterviewRoundGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClientInterviewRoundCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/ClientInterviewRoundApi~apiClientInterviewRoundGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiClientInterviewRoundGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClientInterviewRoundCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/ClientInterviewRound', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiClientInterviewRoundGetAllByPageGet operation.
     * @callback moduleapi/ClientInterviewRoundApi~apiClientInterviewRoundGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClientInterviewRoundCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/ClientInterviewRoundApi~apiClientInterviewRoundGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiClientInterviewRoundGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClientInterviewRoundCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/ClientInterviewRound/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiClientInterviewRoundGetAllRoundByClientIdClientIdGet operation.
     * @callback moduleapi/ClientInterviewRoundApi~apiClientInterviewRoundGetAllRoundByClientIdClientIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClientInterviewRoundDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} clientId 
     * @param {module:api/ClientInterviewRoundApi~apiClientInterviewRoundGetAllRoundByClientIdClientIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiClientInterviewRoundGetAllRoundByClientIdClientIdGet(clientId, callback) {
      
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling apiClientInterviewRoundGetAllRoundByClientIdClientIdGet");
      }

      let pathParams = {
        'clientId': clientId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClientInterviewRoundDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/ClientInterviewRound/GetAllRoundByClientId/{clientId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiClientInterviewRoundGetByGuidGuidGet operation.
     * @callback moduleapi/ClientInterviewRoundApi~apiClientInterviewRoundGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClientInterviewRoundDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/ClientInterviewRoundApi~apiClientInterviewRoundGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiClientInterviewRoundGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiClientInterviewRoundGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClientInterviewRoundDtoApiResponse;

      return this.apiClient.callApi(
        '/api/ClientInterviewRound/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiClientInterviewRoundIdDelete operation.
     * @callback moduleapi/ClientInterviewRoundApi~apiClientInterviewRoundIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClientInterviewRoundCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/ClientInterviewRoundApi~apiClientInterviewRoundIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiClientInterviewRoundIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiClientInterviewRoundIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClientInterviewRoundCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/ClientInterviewRound/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiClientInterviewRoundIdGet operation.
     * @callback moduleapi/ClientInterviewRoundApi~apiClientInterviewRoundIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClientInterviewRoundDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/ClientInterviewRoundApi~apiClientInterviewRoundIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiClientInterviewRoundIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiClientInterviewRoundIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClientInterviewRoundDtoApiResponse;

      return this.apiClient.callApi(
        '/api/ClientInterviewRound/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiClientInterviewRoundPost operation.
     * @callback moduleapi/ClientInterviewRoundApi~apiClientInterviewRoundPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClientInterviewRoundDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/ClientInterviewRoundCreateDto} opts.body 
     * @param {module:api/ClientInterviewRoundApi~apiClientInterviewRoundPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiClientInterviewRoundPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClientInterviewRoundDtoApiResponse;

      return this.apiClient.callApi(
        '/api/ClientInterviewRound', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiClientInterviewRoundPut operation.
     * @callback moduleapi/ClientInterviewRoundApi~apiClientInterviewRoundPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClientInterviewRoundDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/ClientInterviewRoundEditDto} opts.body 
     * @param {module:api/ClientInterviewRoundApi~apiClientInterviewRoundPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiClientInterviewRoundPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClientInterviewRoundDtoApiResponse;

      return this.apiClient.callApi(
        '/api/ClientInterviewRound', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}