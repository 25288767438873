/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {DocumentDto} from './DocumentDto';
import {ParserType} from './ParserType';

/**
 * The ParserDocumentEditDto model module.
 * @module model/ParserDocumentEditDto
 * @version v3.76(UAT)
 */
export class ParserDocumentEditDto {
  /**
   * Constructs a new <code>ParserDocumentEditDto</code>.
   * @alias module:model/ParserDocumentEditDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ParserDocumentEditDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ParserDocumentEditDto} obj Optional instance to populate.
   * @return {module:model/ParserDocumentEditDto} The populated <code>ParserDocumentEditDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ParserDocumentEditDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('isParsed'))
        obj.isParsed = ApiClient.convertToType(data['isParsed'], 'Boolean');
      if (data.hasOwnProperty('parserType'))
        obj.parserType = ParserType.constructFromObject(data['parserType']);
      if (data.hasOwnProperty('status'))
        obj.status = ApiClient.convertToType(data['status'], 'String');
      if (data.hasOwnProperty('retryLimit'))
        obj.retryLimit = ApiClient.convertToType(data['retryLimit'], 'Number');
      if (data.hasOwnProperty('retryDelayInSeconds'))
        obj.retryDelayInSeconds = ApiClient.convertToType(data['retryDelayInSeconds'], 'Number');
      if (data.hasOwnProperty('mongoDBObjectId'))
        obj.mongoDBObjectId = ApiClient.convertToType(data['mongoDBObjectId'], 'String');
      if (data.hasOwnProperty('candidateId'))
        obj.candidateId = ApiClient.convertToType(data['candidateId'], 'Number');
      if (data.hasOwnProperty('documentId'))
        obj.documentId = ApiClient.convertToType(data['documentId'], 'Number');
      if (data.hasOwnProperty('document'))
        obj.document = DocumentDto.constructFromObject(data['document']);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
ParserDocumentEditDto.prototype.id = undefined;

/**
 * @member {String} uniqueGuid
 */
ParserDocumentEditDto.prototype.uniqueGuid = undefined;

/**
 * @member {Boolean} isParsed
 */
ParserDocumentEditDto.prototype.isParsed = undefined;

/**
 * @member {module:model/ParserType} parserType
 */
ParserDocumentEditDto.prototype.parserType = undefined;

/**
 * @member {String} status
 */
ParserDocumentEditDto.prototype.status = undefined;

/**
 * @member {Number} retryLimit
 */
ParserDocumentEditDto.prototype.retryLimit = undefined;

/**
 * @member {Number} retryDelayInSeconds
 */
ParserDocumentEditDto.prototype.retryDelayInSeconds = undefined;

/**
 * @member {String} mongoDBObjectId
 */
ParserDocumentEditDto.prototype.mongoDBObjectId = undefined;

/**
 * @member {Number} candidateId
 */
ParserDocumentEditDto.prototype.candidateId = undefined;

/**
 * @member {Number} documentId
 */
ParserDocumentEditDto.prototype.documentId = undefined;

/**
 * @member {module:model/DocumentDto} document
 */
ParserDocumentEditDto.prototype.document = undefined;

