/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The RSCandidateAddress model module.
 * @module model/RSCandidateAddress
 * @version v3.76(UAT)
 */
export class RSCandidateAddress {
  /**
   * Constructs a new <code>RSCandidateAddress</code>.
   * @alias module:model/RSCandidateAddress
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RSCandidateAddress</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RSCandidateAddress} obj Optional instance to populate.
   * @return {module:model/RSCandidateAddress} The populated <code>RSCandidateAddress</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RSCandidateAddress();
      if (data.hasOwnProperty('city'))
        obj.city = ApiClient.convertToType(data['city'], 'String');
      if (data.hasOwnProperty('state'))
        obj.state = ApiClient.convertToType(data['state'], 'String');
      if (data.hasOwnProperty('country'))
        obj.country = ApiClient.convertToType(data['country'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} city
 */
RSCandidateAddress.prototype.city = undefined;

/**
 * @member {String} state
 */
RSCandidateAddress.prototype.state = undefined;

/**
 * @member {String} country
 */
RSCandidateAddress.prototype.country = undefined;

