/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CandidateDto} from './CandidateDto';
import {JdAndTaggedCandidateStatusType} from './JdAndTaggedCandidateStatusType';
import {JobDescriptionDto} from './JobDescriptionDto';

/**
 * The JdAndTaggedCandidateDto model module.
 * @module model/JdAndTaggedCandidateDto
 * @version v3.76(UAT)
 */
export class JdAndTaggedCandidateDto {
  /**
   * Constructs a new <code>JdAndTaggedCandidateDto</code>.
   * @alias module:model/JdAndTaggedCandidateDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>JdAndTaggedCandidateDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/JdAndTaggedCandidateDto} obj Optional instance to populate.
   * @return {module:model/JdAndTaggedCandidateDto} The populated <code>JdAndTaggedCandidateDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new JdAndTaggedCandidateDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = JdAndTaggedCandidateStatusType.constructFromObject(data['status']);
      if (data.hasOwnProperty('isSelfResponded'))
        obj.isSelfResponded = ApiClient.convertToType(data['isSelfResponded'], 'Boolean');
      if (data.hasOwnProperty('userId'))
        obj.userId = ApiClient.convertToType(data['userId'], 'Number');
      if (data.hasOwnProperty('remarks'))
        obj.remarks = ApiClient.convertToType(data['remarks'], 'String');
      if (data.hasOwnProperty('candidateId'))
        obj.candidateId = ApiClient.convertToType(data['candidateId'], 'Number');
      if (data.hasOwnProperty('candidate'))
        obj.candidate = CandidateDto.constructFromObject(data['candidate']);
      if (data.hasOwnProperty('jobDescriptionId'))
        obj.jobDescriptionId = ApiClient.convertToType(data['jobDescriptionId'], 'Number');
      if (data.hasOwnProperty('jobDescription'))
        obj.jobDescription = JobDescriptionDto.constructFromObject(data['jobDescription']);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
JdAndTaggedCandidateDto.prototype.id = undefined;

/**
 * @member {String} uniqueGuid
 */
JdAndTaggedCandidateDto.prototype.uniqueGuid = undefined;

/**
 * @member {module:model/JdAndTaggedCandidateStatusType} status
 */
JdAndTaggedCandidateDto.prototype.status = undefined;

/**
 * @member {Boolean} isSelfResponded
 */
JdAndTaggedCandidateDto.prototype.isSelfResponded = undefined;

/**
 * @member {Number} userId
 */
JdAndTaggedCandidateDto.prototype.userId = undefined;

/**
 * @member {String} remarks
 */
JdAndTaggedCandidateDto.prototype.remarks = undefined;

/**
 * @member {Number} candidateId
 */
JdAndTaggedCandidateDto.prototype.candidateId = undefined;

/**
 * @member {module:model/CandidateDto} candidate
 */
JdAndTaggedCandidateDto.prototype.candidate = undefined;

/**
 * @member {Number} jobDescriptionId
 */
JdAndTaggedCandidateDto.prototype.jobDescriptionId = undefined;

/**
 * @member {module:model/JobDescriptionDto} jobDescription
 */
JdAndTaggedCandidateDto.prototype.jobDescription = undefined;

