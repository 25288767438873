/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The CandidateManualSearchDto model module.
 * @module model/CandidateManualSearchDto
 * @version v3.76(UAT)
 */
export class CandidateManualSearchDto {
  /**
   * Constructs a new <code>CandidateManualSearchDto</code>.
   * @alias module:model/CandidateManualSearchDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CandidateManualSearchDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CandidateManualSearchDto} obj Optional instance to populate.
   * @return {module:model/CandidateManualSearchDto} The populated <code>CandidateManualSearchDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CandidateManualSearchDto();
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
      if (data.hasOwnProperty('mobile'))
        obj.mobile = ApiClient.convertToType(data['mobile'], 'String');
      if (data.hasOwnProperty('jobDescriptionId'))
        obj.jobDescriptionId = ApiClient.convertToType(data['jobDescriptionId'], 'Number');
      if (data.hasOwnProperty('minExperience'))
        obj.minExperience = ApiClient.convertToType(data['minExperience'], 'Number');
      if (data.hasOwnProperty('maxExperience'))
        obj.maxExperience = ApiClient.convertToType(data['maxExperience'], 'Number');
      if (data.hasOwnProperty('skills'))
        obj.skills = ApiClient.convertToType(data['skills'], ['String']);
      if (data.hasOwnProperty('companies'))
        obj.companies = ApiClient.convertToType(data['companies'], ['String']);
      if (data.hasOwnProperty('projectTitle'))
        obj.projectTitle = ApiClient.convertToType(data['projectTitle'], 'String');
      if (data.hasOwnProperty('isprod'))
        obj.isprod = ApiClient.convertToType(data['isprod'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} name
 */
CandidateManualSearchDto.prototype.name = undefined;

/**
 * @member {String} email
 */
CandidateManualSearchDto.prototype.email = undefined;

/**
 * @member {String} mobile
 */
CandidateManualSearchDto.prototype.mobile = undefined;

/**
 * @member {Number} jobDescriptionId
 */
CandidateManualSearchDto.prototype.jobDescriptionId = undefined;

/**
 * @member {Number} minExperience
 */
CandidateManualSearchDto.prototype.minExperience = undefined;

/**
 * @member {Number} maxExperience
 */
CandidateManualSearchDto.prototype.maxExperience = undefined;

/**
 * @member {Array.<String>} skills
 */
CandidateManualSearchDto.prototype.skills = undefined;

/**
 * @member {Array.<String>} companies
 */
CandidateManualSearchDto.prototype.companies = undefined;

/**
 * @member {String} projectTitle
 */
CandidateManualSearchDto.prototype.projectTitle = undefined;

/**
 * @member {Boolean} isprod
 */
CandidateManualSearchDto.prototype.isprod = undefined;

