/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {MStepOutcomeCommunicationDto} from './MStepOutcomeCommunicationDto';

/**
 * The PathwayStepOutcomeCommunicationDto model module.
 * @module model/PathwayStepOutcomeCommunicationDto
 * @version v3.76(UAT)
 */
export class PathwayStepOutcomeCommunicationDto {
  /**
   * Constructs a new <code>PathwayStepOutcomeCommunicationDto</code>.
   * @alias module:model/PathwayStepOutcomeCommunicationDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PathwayStepOutcomeCommunicationDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PathwayStepOutcomeCommunicationDto} obj Optional instance to populate.
   * @return {module:model/PathwayStepOutcomeCommunicationDto} The populated <code>PathwayStepOutcomeCommunicationDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PathwayStepOutcomeCommunicationDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('pathwayStepOutcomeId'))
        obj.pathwayStepOutcomeId = ApiClient.convertToType(data['pathwayStepOutcomeId'], 'Number');
      if (data.hasOwnProperty('stepOutcomeCommunicationId'))
        obj.stepOutcomeCommunicationId = ApiClient.convertToType(data['stepOutcomeCommunicationId'], 'Number');
      if (data.hasOwnProperty('stepOutcomeCommunication'))
        obj.stepOutcomeCommunication = MStepOutcomeCommunicationDto.constructFromObject(data['stepOutcomeCommunication']);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
PathwayStepOutcomeCommunicationDto.prototype.id = undefined;

/**
 * @member {String} uniqueGuid
 */
PathwayStepOutcomeCommunicationDto.prototype.uniqueGuid = undefined;

/**
 * @member {Number} pathwayStepOutcomeId
 */
PathwayStepOutcomeCommunicationDto.prototype.pathwayStepOutcomeId = undefined;

/**
 * @member {Number} stepOutcomeCommunicationId
 */
PathwayStepOutcomeCommunicationDto.prototype.stepOutcomeCommunicationId = undefined;

/**
 * @member {module:model/MStepOutcomeCommunicationDto} stepOutcomeCommunication
 */
PathwayStepOutcomeCommunicationDto.prototype.stepOutcomeCommunication = undefined;

