/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {JobTitleCompactDtoApiResponse} from '../model/JobTitleCompactDtoApiResponse';
import {JobTitleCompactDtoIEnumerableApiResponse} from '../model/JobTitleCompactDtoIEnumerableApiResponse';
import {JobTitleCompactDtoPagedListApiResponse} from '../model/JobTitleCompactDtoPagedListApiResponse';
import {JobTitleCreateDto} from '../model/JobTitleCreateDto';
import {JobTitleDtoApiResponse} from '../model/JobTitleDtoApiResponse';
import {JobTitleEditDto} from '../model/JobTitleEditDto';

/**
* JobTitle service.
* @module api/JobTitleApi
* @version v3.76(UAT)
*/
export class JobTitleApi {

    /**
    * Constructs a new JobTitleApi. 
    * @alias module:api/JobTitleApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiJobTitleGet operation.
     * @callback moduleapi/JobTitleApi~apiJobTitleGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/JobTitleApi~apiJobTitleGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitle', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitleGetAllByNameGet operation.
     * @callback moduleapi/JobTitleApi~apiJobTitleGetAllByNameGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {module:api/JobTitleApi~apiJobTitleGetAllByNameGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleGetAllByNameGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'name': opts['name']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitle/GetAllByName', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitleGetAllByPageGet operation.
     * @callback moduleapi/JobTitleApi~apiJobTitleGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/JobTitleApi~apiJobTitleGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitle/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitleGetByExistOrCreateIfNotExistsNameGet operation.
     * @callback moduleapi/JobTitleApi~apiJobTitleGetByExistOrCreateIfNotExistsNameGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} name 
     * @param {module:api/JobTitleApi~apiJobTitleGetByExistOrCreateIfNotExistsNameGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleGetByExistOrCreateIfNotExistsNameGet(name, callback) {
      
      let postBody = null;
      // verify the required parameter 'name' is set
      if (name === undefined || name === null) {
        throw new Error("Missing the required parameter 'name' when calling apiJobTitleGetByExistOrCreateIfNotExistsNameGet");
      }

      let pathParams = {
        'name': name
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitle/GetByExistOrCreateIfNotExists/{name}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitleGetByGuidGuidGet operation.
     * @callback moduleapi/JobTitleApi~apiJobTitleGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/JobTitleApi~apiJobTitleGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiJobTitleGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitle/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitleIdDelete operation.
     * @callback moduleapi/JobTitleApi~apiJobTitleIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/JobTitleApi~apiJobTitleIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiJobTitleIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitle/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitleIdGet operation.
     * @callback moduleapi/JobTitleApi~apiJobTitleIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/JobTitleApi~apiJobTitleIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiJobTitleIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitle/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitlePost operation.
     * @callback moduleapi/JobTitleApi~apiJobTitlePostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/JobTitleCreateDto} opts.body 
     * @param {module:api/JobTitleApi~apiJobTitlePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitlePost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitle', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitlePut operation.
     * @callback moduleapi/JobTitleApi~apiJobTitlePutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/JobTitleEditDto} opts.body 
     * @param {module:api/JobTitleApi~apiJobTitlePutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitlePut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitle', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}