/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {JobTitleAliasCompactDtoApiResponse} from '../model/JobTitleAliasCompactDtoApiResponse';
import {JobTitleAliasCompactDtoIEnumerableApiResponse} from '../model/JobTitleAliasCompactDtoIEnumerableApiResponse';
import {JobTitleAliasCompactDtoPagedListApiResponse} from '../model/JobTitleAliasCompactDtoPagedListApiResponse';
import {JobTitleAliasCreateDto} from '../model/JobTitleAliasCreateDto';
import {JobTitleAliasDtoApiResponse} from '../model/JobTitleAliasDtoApiResponse';
import {JobTitleAliasEditDto} from '../model/JobTitleAliasEditDto';

/**
* JobTitleAlias service.
* @module api/JobTitleAliasApi
* @version v3.76(UAT)
*/
export class JobTitleAliasApi {

    /**
    * Constructs a new JobTitleAliasApi. 
    * @alias module:api/JobTitleAliasApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiJobTitleAliasGet operation.
     * @callback moduleapi/JobTitleAliasApi~apiJobTitleAliasGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleAliasCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/JobTitleAliasApi~apiJobTitleAliasGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleAliasGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleAliasCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitleAlias', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitleAliasGetAllByEntityIdEntityIdGet operation.
     * @callback moduleapi/JobTitleAliasApi~apiJobTitleAliasGetAllByEntityIdEntityIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleAliasCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} entityId 
     * @param {module:api/JobTitleAliasApi~apiJobTitleAliasGetAllByEntityIdEntityIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleAliasGetAllByEntityIdEntityIdGet(entityId, callback) {
      
      let postBody = null;
      // verify the required parameter 'entityId' is set
      if (entityId === undefined || entityId === null) {
        throw new Error("Missing the required parameter 'entityId' when calling apiJobTitleAliasGetAllByEntityIdEntityIdGet");
      }

      let pathParams = {
        'entityId': entityId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleAliasCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitleAlias/GetAllByEntityId/{entityId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitleAliasGetAllByPageGet operation.
     * @callback moduleapi/JobTitleAliasApi~apiJobTitleAliasGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleAliasCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/JobTitleAliasApi~apiJobTitleAliasGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleAliasGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleAliasCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitleAlias/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitleAliasGetByGuidGuidGet operation.
     * @callback moduleapi/JobTitleAliasApi~apiJobTitleAliasGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleAliasDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/JobTitleAliasApi~apiJobTitleAliasGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleAliasGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiJobTitleAliasGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleAliasDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitleAlias/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitleAliasIdDelete operation.
     * @callback moduleapi/JobTitleAliasApi~apiJobTitleAliasIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleAliasCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/JobTitleAliasApi~apiJobTitleAliasIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleAliasIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiJobTitleAliasIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleAliasCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitleAlias/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitleAliasIdGet operation.
     * @callback moduleapi/JobTitleAliasApi~apiJobTitleAliasIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleAliasDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/JobTitleAliasApi~apiJobTitleAliasIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleAliasIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiJobTitleAliasIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleAliasDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitleAlias/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitleAliasPost operation.
     * @callback moduleapi/JobTitleAliasApi~apiJobTitleAliasPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleAliasDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/JobTitleAliasCreateDto} opts.body 
     * @param {module:api/JobTitleAliasApi~apiJobTitleAliasPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleAliasPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleAliasDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitleAlias', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobTitleAliasPut operation.
     * @callback moduleapi/JobTitleAliasApi~apiJobTitleAliasPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobTitleAliasDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/JobTitleAliasEditDto} opts.body 
     * @param {module:api/JobTitleAliasApi~apiJobTitleAliasPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobTitleAliasPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobTitleAliasDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobTitleAlias', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}