/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {PathwayStepAttributeCreateDto} from './PathwayStepAttributeCreateDto';
import {PathwayStepOutcomeCreateDto} from './PathwayStepOutcomeCreateDto';
import {PathwayStepTextCreateDto} from './PathwayStepTextCreateDto';

/**
 * The PathwayStepCreateDto model module.
 * @module model/PathwayStepCreateDto
 * @version v3.76(UAT)
 */
export class PathwayStepCreateDto {
  /**
   * Constructs a new <code>PathwayStepCreateDto</code>.
   * @alias module:model/PathwayStepCreateDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PathwayStepCreateDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PathwayStepCreateDto} obj Optional instance to populate.
   * @return {module:model/PathwayStepCreateDto} The populated <code>PathwayStepCreateDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PathwayStepCreateDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('code'))
        obj.code = ApiClient.convertToType(data['code'], 'String');
      if (data.hasOwnProperty('sequenceNumber'))
        obj.sequenceNumber = ApiClient.convertToType(data['sequenceNumber'], 'Number');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('isActive'))
        obj.isActive = ApiClient.convertToType(data['isActive'], 'Boolean');
      if (data.hasOwnProperty('isManualTrigger'))
        obj.isManualTrigger = ApiClient.convertToType(data['isManualTrigger'], 'Boolean');
      if (data.hasOwnProperty('pathwayId'))
        obj.pathwayId = ApiClient.convertToType(data['pathwayId'], 'Number');
      if (data.hasOwnProperty('stepId'))
        obj.stepId = ApiClient.convertToType(data['stepId'], 'Number');
      if (data.hasOwnProperty('pathwayStepText'))
        obj.pathwayStepText = ApiClient.convertToType(data['pathwayStepText'], [PathwayStepTextCreateDto]);
      if (data.hasOwnProperty('pathwayStepOutcome'))
        obj.pathwayStepOutcome = ApiClient.convertToType(data['pathwayStepOutcome'], [PathwayStepOutcomeCreateDto]);
      if (data.hasOwnProperty('pathwayStepAttribute'))
        obj.pathwayStepAttribute = ApiClient.convertToType(data['pathwayStepAttribute'], [PathwayStepAttributeCreateDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
PathwayStepCreateDto.prototype.id = undefined;

/**
 * @member {String} uniqueGuid
 */
PathwayStepCreateDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} code
 */
PathwayStepCreateDto.prototype.code = undefined;

/**
 * @member {Number} sequenceNumber
 */
PathwayStepCreateDto.prototype.sequenceNumber = undefined;

/**
 * @member {String} description
 */
PathwayStepCreateDto.prototype.description = undefined;

/**
 * @member {Boolean} isActive
 */
PathwayStepCreateDto.prototype.isActive = undefined;

/**
 * @member {Boolean} isManualTrigger
 */
PathwayStepCreateDto.prototype.isManualTrigger = undefined;

/**
 * @member {Number} pathwayId
 */
PathwayStepCreateDto.prototype.pathwayId = undefined;

/**
 * @member {Number} stepId
 */
PathwayStepCreateDto.prototype.stepId = undefined;

/**
 * @member {Array.<module:model/PathwayStepTextCreateDto>} pathwayStepText
 */
PathwayStepCreateDto.prototype.pathwayStepText = undefined;

/**
 * @member {Array.<module:model/PathwayStepOutcomeCreateDto>} pathwayStepOutcome
 */
PathwayStepCreateDto.prototype.pathwayStepOutcome = undefined;

/**
 * @member {Array.<module:model/PathwayStepAttributeCreateDto>} pathwayStepAttribute
 */
PathwayStepCreateDto.prototype.pathwayStepAttribute = undefined;

