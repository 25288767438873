/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from './ApiClient';
import {AcceptanceType} from './model/AcceptanceType';
import {AcceptedStatus} from './model/AcceptedStatus';
import {AddressCreateDto} from './model/AddressCreateDto';
import {AddressDto} from './model/AddressDto';
import {AddressEditDto} from './model/AddressEditDto';
import {AddressType} from './model/AddressType';
import {ApiClientContractBody} from './model/ApiClientContractBody';
import {ApiClientContractBody1} from './model/ApiClientContractBody1';
import {ApiParserDocumentBody} from './model/ApiParserDocumentBody';
import {AssessmentCompactDto} from './model/AssessmentCompactDto';
import {AssessmentCompactDtoApiResponse} from './model/AssessmentCompactDtoApiResponse';
import {AssessmentCompactDtoIEnumerableApiResponse} from './model/AssessmentCompactDtoIEnumerableApiResponse';
import {AssessmentCreateDto} from './model/AssessmentCreateDto';
import {AssessmentDto} from './model/AssessmentDto';
import {AssessmentDtoApiResponse} from './model/AssessmentDtoApiResponse';
import {AssessmentDtoIEnumerableApiResponse} from './model/AssessmentDtoIEnumerableApiResponse';
import {AssessmentEditDto} from './model/AssessmentEditDto';
import {AssessmentSearchDto} from './model/AssessmentSearchDto';
import {AssessmentSearchDtoIEnumerableApiResponse} from './model/AssessmentSearchDtoIEnumerableApiResponse';
import {AssessmentSkillCompactDto} from './model/AssessmentSkillCompactDto';
import {AssessmentSkillCompactDtoApiResponse} from './model/AssessmentSkillCompactDtoApiResponse';
import {AssessmentSkillCreateDto} from './model/AssessmentSkillCreateDto';
import {AssessmentSkillDto} from './model/AssessmentSkillDto';
import {AssessmentSkillFilterDto} from './model/AssessmentSkillFilterDto';
import {AssociatedEmployerCompactDto} from './model/AssociatedEmployerCompactDto';
import {AssociatedEmployerCompactDtoIEnumerableApiResponse} from './model/AssociatedEmployerCompactDtoIEnumerableApiResponse';
import {AttributeType} from './model/AttributeType';
import {BooleanApiResponse} from './model/BooleanApiResponse';
import {BusinessVerticalCompactDto} from './model/BusinessVerticalCompactDto';
import {BusinessVerticalCompactDtoApiResponse} from './model/BusinessVerticalCompactDtoApiResponse';
import {BusinessVerticalCompactDtoIEnumerableApiResponse} from './model/BusinessVerticalCompactDtoIEnumerableApiResponse';
import {BusinessVerticalCompactDtoPagedListApiResponse} from './model/BusinessVerticalCompactDtoPagedListApiResponse';
import {BusinessVerticalCreateDto} from './model/BusinessVerticalCreateDto';
import {BusinessVerticalDto} from './model/BusinessVerticalDto';
import {BusinessVerticalDtoApiResponse} from './model/BusinessVerticalDtoApiResponse';
import {BusinessVerticalEditDto} from './model/BusinessVerticalEditDto';
import {CandidateAboutDto} from './model/CandidateAboutDto';
import {CandidateAboutDtoApiResponse} from './model/CandidateAboutDtoApiResponse';
import {CandidateActivityAndHobbieDto} from './model/CandidateActivityAndHobbieDto';
import {CandidateActivityAndHobbieDtoApiResponse} from './model/CandidateActivityAndHobbieDtoApiResponse';
import {CandidateAndAddressCompactDto} from './model/CandidateAndAddressCompactDto';
import {CandidateAndAddressDto} from './model/CandidateAndAddressDto';
import {CandidateAndLanguageDto} from './model/CandidateAndLanguageDto';
import {CandidateAssessmentDto} from './model/CandidateAssessmentDto';
import {CandidateAssessmentReportDto} from './model/CandidateAssessmentReportDto';
import {CandidateAutoSearchDto} from './model/CandidateAutoSearchDto';
import {CandidateBackGroundImageDto} from './model/CandidateBackGroundImageDto';
import {CandidateBackGroundImageDtoApiResponse} from './model/CandidateBackGroundImageDtoApiResponse';
import {CandidateBaseDto} from './model/CandidateBaseDto';
import {CandidateCertificateCompactDto} from './model/CandidateCertificateCompactDto';
import {CandidateCertificateCompactDtoApiResponse} from './model/CandidateCertificateCompactDtoApiResponse';
import {CandidateCertificateCreateDto} from './model/CandidateCertificateCreateDto';
import {CandidateCertificateDto} from './model/CandidateCertificateDto';
import {CandidateCertificateDtoApiResponse} from './model/CandidateCertificateDtoApiResponse';
import {CandidateCertificateDtoIEnumerableApiResponse} from './model/CandidateCertificateDtoIEnumerableApiResponse';
import {CandidateCertificateEditDto} from './model/CandidateCertificateEditDto';
import {CandidateCertificateFilterDto} from './model/CandidateCertificateFilterDto';
import {CandidateCertificateFilterDtoIEnumerableApiResponse} from './model/CandidateCertificateFilterDtoIEnumerableApiResponse';
import {CandidateCertificateInfoDto} from './model/CandidateCertificateInfoDto';
import {CandidateCertificateInfoDtoApiResponse} from './model/CandidateCertificateInfoDtoApiResponse';
import {CandidateCollegeImageDto} from './model/CandidateCollegeImageDto';
import {CandidateCollegeImageDtoApiResponse} from './model/CandidateCollegeImageDtoApiResponse';
import {CandidateCompactDto} from './model/CandidateCompactDto';
import {CandidateCompactDtoIEnumerableApiResponse} from './model/CandidateCompactDtoIEnumerableApiResponse';
import {CandidateCompactDtoPagedListApiResponse} from './model/CandidateCompactDtoPagedListApiResponse';
import {CandidateCompactSkillPlatformDto} from './model/CandidateCompactSkillPlatformDto';
import {CandidateCompactSkillPlatformDtoIEnumerableApiResponse} from './model/CandidateCompactSkillPlatformDtoIEnumerableApiResponse';
import {CandidateConfigurationCompactDto} from './model/CandidateConfigurationCompactDto';
import {CandidateConfigurationCompactDtoApiResponse} from './model/CandidateConfigurationCompactDtoApiResponse';
import {CandidateConfigurationCompactDtoIEnumerableApiResponse} from './model/CandidateConfigurationCompactDtoIEnumerableApiResponse';
import {CandidateConfigurationCreateDto} from './model/CandidateConfigurationCreateDto';
import {CandidateConfigurationDto} from './model/CandidateConfigurationDto';
import {CandidateConfigurationDtoApiResponse} from './model/CandidateConfigurationDtoApiResponse';
import {CandidateConfigurationEditDto} from './model/CandidateConfigurationEditDto';
import {CandidateContactDto} from './model/CandidateContactDto';
import {CandidateContactDtoApiResponse} from './model/CandidateContactDtoApiResponse';
import {CandidateDetailsDto} from './model/CandidateDetailsDto';
import {CandidateDetailsDtoApiResponse} from './model/CandidateDetailsDtoApiResponse';
import {CandidateDetailsEditDto} from './model/CandidateDetailsEditDto';
import {CandidateDto} from './model/CandidateDto';
import {CandidateDtoApiResponse} from './model/CandidateDtoApiResponse';
import {CandidateDtoIEnumerableApiResponse} from './model/CandidateDtoIEnumerableApiResponse';
import {CandidateDtoPagedListApiResponse} from './model/CandidateDtoPagedListApiResponse';
import {CandidateEmailDto} from './model/CandidateEmailDto';
import {CandidateEmployerCompactDto} from './model/CandidateEmployerCompactDto';
import {CandidateEmployerCompactDtoApiResponse} from './model/CandidateEmployerCompactDtoApiResponse';
import {CandidateEmployerCreateDto} from './model/CandidateEmployerCreateDto';
import {CandidateEmployerDto} from './model/CandidateEmployerDto';
import {CandidateEmployerDtoApiResponse} from './model/CandidateEmployerDtoApiResponse';
import {CandidateEmployerDtoIEnumerableApiResponse} from './model/CandidateEmployerDtoIEnumerableApiResponse';
import {CandidateEmployerEditDto} from './model/CandidateEmployerEditDto';
import {CandidateEmployerProjectDto} from './model/CandidateEmployerProjectDto';
import {CandidateEmployerProjectDtoIEnumerableApiResponse} from './model/CandidateEmployerProjectDtoIEnumerableApiResponse';
import {CandidateHeadLineDto} from './model/CandidateHeadLineDto';
import {CandidateHeadLineDtoApiResponse} from './model/CandidateHeadLineDtoApiResponse';
import {CandidateManualSearchDto} from './model/CandidateManualSearchDto';
import {CandidateNonTechnicalSkillCreateDto} from './model/CandidateNonTechnicalSkillCreateDto';
import {CandidateOtherPersonalInfoDto} from './model/CandidateOtherPersonalInfoDto';
import {CandidateOtherPersonalInfoDtoApiResponse} from './model/CandidateOtherPersonalInfoDtoApiResponse';
import {CandidateOtherPersonalInfoEditDto} from './model/CandidateOtherPersonalInfoEditDto';
import {CandidatePersonalInfoDto} from './model/CandidatePersonalInfoDto';
import {CandidatePersonalInfoDtoApiResponse} from './model/CandidatePersonalInfoDtoApiResponse';
import {CandidatePersonalInfoEditDto} from './model/CandidatePersonalInfoEditDto';
import {CandidatePhoneDto} from './model/CandidatePhoneDto';
import {CandidateProfileDto} from './model/CandidateProfileDto';
import {CandidateProfileDtoApiResponse} from './model/CandidateProfileDtoApiResponse';
import {CandidateProfileImageDto} from './model/CandidateProfileImageDto';
import {CandidateProfileImageDtoApiResponse} from './model/CandidateProfileImageDtoApiResponse';
import {CandidateProfileViewDto} from './model/CandidateProfileViewDto';
import {CandidateProfileViewDtoApiResponse} from './model/CandidateProfileViewDtoApiResponse';
import {CandidateProjectsCompactDto} from './model/CandidateProjectsCompactDto';
import {CandidateProjectsCompactDtoApiResponse} from './model/CandidateProjectsCompactDtoApiResponse';
import {CandidateProjectsCreateDto} from './model/CandidateProjectsCreateDto';
import {CandidateProjectsDto} from './model/CandidateProjectsDto';
import {CandidateProjectsDtoApiResponse} from './model/CandidateProjectsDtoApiResponse';
import {CandidateProjectsDtoIEnumerableApiResponse} from './model/CandidateProjectsDtoIEnumerableApiResponse';
import {CandidateProjectsEditDto} from './model/CandidateProjectsEditDto';
import {CandidateQualificationCompactDto} from './model/CandidateQualificationCompactDto';
import {CandidateQualificationCompactDtoApiResponse} from './model/CandidateQualificationCompactDtoApiResponse';
import {CandidateQualificationCreateDto} from './model/CandidateQualificationCreateDto';
import {CandidateQualificationDto} from './model/CandidateQualificationDto';
import {CandidateQualificationDtoApiResponse} from './model/CandidateQualificationDtoApiResponse';
import {CandidateQualificationDtoIEnumerableApiResponse} from './model/CandidateQualificationDtoIEnumerableApiResponse';
import {CandidateQualificationEditDto} from './model/CandidateQualificationEditDto';
import {CandidateRegisterDto} from './model/CandidateRegisterDto';
import {CandidateResumeDto} from './model/CandidateResumeDto';
import {CandidateResumeDtoApiResponse} from './model/CandidateResumeDtoApiResponse';
import {CandidateSkillCompactDto} from './model/CandidateSkillCompactDto';
import {CandidateSkillCompactDtoApiResponse} from './model/CandidateSkillCompactDtoApiResponse';
import {CandidateSkillCreateDto} from './model/CandidateSkillCreateDto';
import {CandidateSkillDto} from './model/CandidateSkillDto';
import {CandidateSkillDtoApiResponse} from './model/CandidateSkillDtoApiResponse';
import {CandidateSkillDtoIEnumerableApiResponse} from './model/CandidateSkillDtoIEnumerableApiResponse';
import {CandidateSkillEditDto} from './model/CandidateSkillEditDto';
import {CandidateSkillPlatformCompactDto} from './model/CandidateSkillPlatformCompactDto';
import {CandidateSkillPlatformCompactDtoApiResponse} from './model/CandidateSkillPlatformCompactDtoApiResponse';
import {CandidateSkillPlatformCreateDto} from './model/CandidateSkillPlatformCreateDto';
import {CandidateSkillPlatformDto} from './model/CandidateSkillPlatformDto';
import {CandidateSkillPlatformDtoApiResponse} from './model/CandidateSkillPlatformDtoApiResponse';
import {CandidateSkillPlatformEditDto} from './model/CandidateSkillPlatformEditDto';
import {CandidateSkillsDto} from './model/CandidateSkillsDto';
import {CandidateSkillsDtoApiResponse} from './model/CandidateSkillsDtoApiResponse';
import {CandidateSkillsEditDto} from './model/CandidateSkillsEditDto';
import {CandidateSourcingSequenceDto} from './model/CandidateSourcingSequenceDto';
import {CandidateTraitDto} from './model/CandidateTraitDto';
import {CandidateViewDto} from './model/CandidateViewDto';
import {CandidateViewDtoIEnumerableApiResponse} from './model/CandidateViewDtoIEnumerableApiResponse';
import {CandidateWebsiteCompactDto} from './model/CandidateWebsiteCompactDto';
import {CandidateWebsiteCompactDtoApiResponse} from './model/CandidateWebsiteCompactDtoApiResponse';
import {CandidateWebsiteCompactDtoIEnumerableApiResponse} from './model/CandidateWebsiteCompactDtoIEnumerableApiResponse';
import {CandidateWebsiteCreateDto} from './model/CandidateWebsiteCreateDto';
import {CandidateWebsiteDto} from './model/CandidateWebsiteDto';
import {CandidateWebsiteDtoApiResponse} from './model/CandidateWebsiteDtoApiResponse';
import {CandidateWebsiteEditDto} from './model/CandidateWebsiteEditDto';
import {CertificateAliasCompactDto} from './model/CertificateAliasCompactDto';
import {CertificateAliasCompactDtoApiResponse} from './model/CertificateAliasCompactDtoApiResponse';
import {CertificateAliasCompactDtoIEnumerableApiResponse} from './model/CertificateAliasCompactDtoIEnumerableApiResponse';
import {CertificateAliasCompactDtoPagedListApiResponse} from './model/CertificateAliasCompactDtoPagedListApiResponse';
import {CertificateAliasCreateDto} from './model/CertificateAliasCreateDto';
import {CertificateAliasDto} from './model/CertificateAliasDto';
import {CertificateAliasDtoApiResponse} from './model/CertificateAliasDtoApiResponse';
import {CertificateAliasEditDto} from './model/CertificateAliasEditDto';
import {CertificateCompactDto} from './model/CertificateCompactDto';
import {CertificateCompactDtoApiResponse} from './model/CertificateCompactDtoApiResponse';
import {CertificateCompactDtoIEnumerableApiResponse} from './model/CertificateCompactDtoIEnumerableApiResponse';
import {CertificateCompactDtoPagedListApiResponse} from './model/CertificateCompactDtoPagedListApiResponse';
import {CertificateCreateDto} from './model/CertificateCreateDto';
import {CertificateDto} from './model/CertificateDto';
import {CertificateDtoApiResponse} from './model/CertificateDtoApiResponse';
import {CertificateEditDto} from './model/CertificateEditDto';
import {CertificateSearchDto} from './model/CertificateSearchDto';
import {CertificateSearchDtoIEnumerableApiResponse} from './model/CertificateSearchDtoIEnumerableApiResponse';
import {Certification} from './model/Certification';
import {ChatGPTMapDto} from './model/ChatGPTMapDto';
import {ChatGPTMapDtoApiResponse} from './model/ChatGPTMapDtoApiResponse';
import {CityCompactDto} from './model/CityCompactDto';
import {CityCompactDtoApiResponse} from './model/CityCompactDtoApiResponse';
import {CityCompactDtoIEnumerableApiResponse} from './model/CityCompactDtoIEnumerableApiResponse';
import {CityCompactDtoPagedListApiResponse} from './model/CityCompactDtoPagedListApiResponse';
import {CityCreateDto} from './model/CityCreateDto';
import {CityDto} from './model/CityDto';
import {CityDtoApiResponse} from './model/CityDtoApiResponse';
import {CityEditDto} from './model/CityEditDto';
import {CityLocationDto} from './model/CityLocationDto';
import {CityLocationDtoIEnumerableApiResponse} from './model/CityLocationDtoIEnumerableApiResponse';
import {ClientAndAddressCompactDto} from './model/ClientAndAddressCompactDto';
import {ClientAndAddressCreateDto} from './model/ClientAndAddressCreateDto';
import {ClientAndAddressDto} from './model/ClientAndAddressDto';
import {ClientAndAddressEditDto} from './model/ClientAndAddressEditDto';
import {ClientCompactDto} from './model/ClientCompactDto';
import {ClientCompactDtoApiResponse} from './model/ClientCompactDtoApiResponse';
import {ClientCompactDtoIEnumerableApiResponse} from './model/ClientCompactDtoIEnumerableApiResponse';
import {ClientCompactDtoPagedListApiResponse} from './model/ClientCompactDtoPagedListApiResponse';
import {ClientContractCompactDto} from './model/ClientContractCompactDto';
import {ClientContractCompactDtoApiResponse} from './model/ClientContractCompactDtoApiResponse';
import {ClientContractCompactDtoIEnumerableApiResponse} from './model/ClientContractCompactDtoIEnumerableApiResponse';
import {ClientContractDto} from './model/ClientContractDto';
import {ClientContractDtoApiResponse} from './model/ClientContractDtoApiResponse';
import {ClientContractDtoIEnumerableApiResponse} from './model/ClientContractDtoIEnumerableApiResponse';
import {ClientCreateDto} from './model/ClientCreateDto';
import {ClientDashboardDto} from './model/ClientDashboardDto';
import {ClientDashboardDtoApiResponse} from './model/ClientDashboardDtoApiResponse';
import {ClientDashboardDtoIEnumerableApiResponse} from './model/ClientDashboardDtoIEnumerableApiResponse';
import {ClientDto} from './model/ClientDto';
import {ClientDtoApiResponse} from './model/ClientDtoApiResponse';
import {ClientEditDto} from './model/ClientEditDto';
import {ClientFilterDto} from './model/ClientFilterDto';
import {ClientFilterDtoIEnumerableApiResponse} from './model/ClientFilterDtoIEnumerableApiResponse';
import {ClientInterviewRoundCompactDto} from './model/ClientInterviewRoundCompactDto';
import {ClientInterviewRoundCompactDtoApiResponse} from './model/ClientInterviewRoundCompactDtoApiResponse';
import {ClientInterviewRoundCompactDtoIEnumerableApiResponse} from './model/ClientInterviewRoundCompactDtoIEnumerableApiResponse';
import {ClientInterviewRoundCompactDtoPagedListApiResponse} from './model/ClientInterviewRoundCompactDtoPagedListApiResponse';
import {ClientInterviewRoundCreateDto} from './model/ClientInterviewRoundCreateDto';
import {ClientInterviewRoundDto} from './model/ClientInterviewRoundDto';
import {ClientInterviewRoundDtoApiResponse} from './model/ClientInterviewRoundDtoApiResponse';
import {ClientInterviewRoundDtoIEnumerableApiResponse} from './model/ClientInterviewRoundDtoIEnumerableApiResponse';
import {ClientInterviewRoundEditDto} from './model/ClientInterviewRoundEditDto';
import {ClientLogInDto} from './model/ClientLogInDto';
import {ClientStatus} from './model/ClientStatus';
import {CompanyAliasCompactDto} from './model/CompanyAliasCompactDto';
import {CompanyAliasCompactDtoApiResponse} from './model/CompanyAliasCompactDtoApiResponse';
import {CompanyAliasCompactDtoIEnumerableApiResponse} from './model/CompanyAliasCompactDtoIEnumerableApiResponse';
import {CompanyAliasCompactDtoPagedListApiResponse} from './model/CompanyAliasCompactDtoPagedListApiResponse';
import {CompanyAliasCreateDto} from './model/CompanyAliasCreateDto';
import {CompanyAliasDto} from './model/CompanyAliasDto';
import {CompanyAliasDtoApiResponse} from './model/CompanyAliasDtoApiResponse';
import {CompanyAliasEditDto} from './model/CompanyAliasEditDto';
import {CompanyCompactDto} from './model/CompanyCompactDto';
import {CompanyCompactDtoApiResponse} from './model/CompanyCompactDtoApiResponse';
import {CompanyCompactDtoIEnumerableApiResponse} from './model/CompanyCompactDtoIEnumerableApiResponse';
import {CompanyCompactDtoPagedListApiResponse} from './model/CompanyCompactDtoPagedListApiResponse';
import {CompanyCreateDto} from './model/CompanyCreateDto';
import {CompanyDto} from './model/CompanyDto';
import {CompanyDtoApiResponse} from './model/CompanyDtoApiResponse';
import {CompanyDuration} from './model/CompanyDuration';
import {CompanyEditDto} from './model/CompanyEditDto';
import {CompanyType} from './model/CompanyType';
import {ContactCompactDto} from './model/ContactCompactDto';
import {ContactCompactDtoIEnumerableApiResponse} from './model/ContactCompactDtoIEnumerableApiResponse';
import {ContactCreateDto} from './model/ContactCreateDto';
import {ContactDto} from './model/ContactDto';
import {ContactDtoApiResponse} from './model/ContactDtoApiResponse';
import {ContactDtoIEnumerableApiResponse} from './model/ContactDtoIEnumerableApiResponse';
import {ContactEditDto} from './model/ContactEditDto';
import {ContactInfo} from './model/ContactInfo';
import {ContactType} from './model/ContactType';
import {ContentCompactDto} from './model/ContentCompactDto';
import {ContentCompactDtoApiResponse} from './model/ContentCompactDtoApiResponse';
import {ContentCompactDtoIEnumerableApiResponse} from './model/ContentCompactDtoIEnumerableApiResponse';
import {ContentCompactDtoPagedListApiResponse} from './model/ContentCompactDtoPagedListApiResponse';
import {ContentCreateDto} from './model/ContentCreateDto';
import {ContentDto} from './model/ContentDto';
import {ContentDtoApiResponse} from './model/ContentDtoApiResponse';
import {ContentEditDto} from './model/ContentEditDto';
import {CountryCompactDto} from './model/CountryCompactDto';
import {CountryCompactDtoApiResponse} from './model/CountryCompactDtoApiResponse';
import {CountryCompactDtoIEnumerableApiResponse} from './model/CountryCompactDtoIEnumerableApiResponse';
import {CountryCompactDtoPagedListApiResponse} from './model/CountryCompactDtoPagedListApiResponse';
import {CountryCreateDto} from './model/CountryCreateDto';
import {CountryDto} from './model/CountryDto';
import {CountryDtoApiResponse} from './model/CountryDtoApiResponse';
import {CountryEditDto} from './model/CountryEditDto';
import {CountryFilterDto} from './model/CountryFilterDto';
import {CountryFilterDtoIEnumerableApiResponse} from './model/CountryFilterDtoIEnumerableApiResponse';
import {CourseCompactDto} from './model/CourseCompactDto';
import {CourseCompactDtoApiResponse} from './model/CourseCompactDtoApiResponse';
import {CourseCompactDtoIEnumerableApiResponse} from './model/CourseCompactDtoIEnumerableApiResponse';
import {CourseCompactDtoPagedListApiResponse} from './model/CourseCompactDtoPagedListApiResponse';
import {CourseCreateDto} from './model/CourseCreateDto';
import {CourseDto} from './model/CourseDto';
import {CourseDtoApiResponse} from './model/CourseDtoApiResponse';
import {CourseEditDto} from './model/CourseEditDto';
import {CustomCandidateStatusDto} from './model/CustomCandidateStatusDto';
import {CustomCandidateStatusDtoApiResponse} from './model/CustomCandidateStatusDtoApiResponse';
import {DegreeAliasCompactDto} from './model/DegreeAliasCompactDto';
import {DegreeAliasCompactDtoApiResponse} from './model/DegreeAliasCompactDtoApiResponse';
import {DegreeAliasCompactDtoIEnumerableApiResponse} from './model/DegreeAliasCompactDtoIEnumerableApiResponse';
import {DegreeAliasCompactDtoPagedListApiResponse} from './model/DegreeAliasCompactDtoPagedListApiResponse';
import {DegreeAliasCreateDto} from './model/DegreeAliasCreateDto';
import {DegreeAliasDto} from './model/DegreeAliasDto';
import {DegreeAliasDtoApiResponse} from './model/DegreeAliasDtoApiResponse';
import {DegreeAliasEditDto} from './model/DegreeAliasEditDto';
import {DegreeCompactDto} from './model/DegreeCompactDto';
import {DegreeCompactDtoApiResponse} from './model/DegreeCompactDtoApiResponse';
import {DegreeCompactDtoIEnumerableApiResponse} from './model/DegreeCompactDtoIEnumerableApiResponse';
import {DegreeCompactDtoPagedListApiResponse} from './model/DegreeCompactDtoPagedListApiResponse';
import {DegreeCreateDto} from './model/DegreeCreateDto';
import {DegreeDto} from './model/DegreeDto';
import {DegreeDtoApiResponse} from './model/DegreeDtoApiResponse';
import {DegreeEditDto} from './model/DegreeEditDto';
import {DepartmentDto} from './model/DepartmentDto';
import {Document} from './model/Document';
import {DocumentCompactDto} from './model/DocumentCompactDto';
import {DocumentCompactDtoApiResponse} from './model/DocumentCompactDtoApiResponse';
import {DocumentCompactDtoIEnumerableApiResponse} from './model/DocumentCompactDtoIEnumerableApiResponse';
import {DocumentCompactDtoPagedListApiResponse} from './model/DocumentCompactDtoPagedListApiResponse';
import {DocumentDto} from './model/DocumentDto';
import {DocumentDtoApiResponse} from './model/DocumentDtoApiResponse';
import {DocumentUploadBody} from './model/DocumentUploadBody';
import {DummyCompactDto} from './model/DummyCompactDto';
import {DummyCompactDtoApiResponse} from './model/DummyCompactDtoApiResponse';
import {DummyCompactDtoIEnumerableApiResponse} from './model/DummyCompactDtoIEnumerableApiResponse';
import {DummyCompactDtoPagedListApiResponse} from './model/DummyCompactDtoPagedListApiResponse';
import {DummyCreateDto} from './model/DummyCreateDto';
import {DummyDto} from './model/DummyDto';
import {DummyDtoApiResponse} from './model/DummyDtoApiResponse';
import {DummyEditDto} from './model/DummyEditDto';
import {Education} from './model/Education';
import {EducationInstituteCompactDto} from './model/EducationInstituteCompactDto';
import {EducationInstituteCompactDtoApiResponse} from './model/EducationInstituteCompactDtoApiResponse';
import {EducationInstituteCompactDtoIEnumerableApiResponse} from './model/EducationInstituteCompactDtoIEnumerableApiResponse';
import {EducationInstituteCompactDtoPagedListApiResponse} from './model/EducationInstituteCompactDtoPagedListApiResponse';
import {EducationInstituteCreateDto} from './model/EducationInstituteCreateDto';
import {EducationInstituteDto} from './model/EducationInstituteDto';
import {EducationInstituteDtoApiResponse} from './model/EducationInstituteDtoApiResponse';
import {EducationInstituteEditDto} from './model/EducationInstituteEditDto';
import {EmployerDto} from './model/EmployerDto';
import {EmploymentStatusType} from './model/EmploymentStatusType';
import {EmploymentType} from './model/EmploymentType';
import {FieldOfStudyCompactDto} from './model/FieldOfStudyCompactDto';
import {FieldOfStudyCompactDtoApiResponse} from './model/FieldOfStudyCompactDtoApiResponse';
import {FieldOfStudyCompactDtoIEnumerableApiResponse} from './model/FieldOfStudyCompactDtoIEnumerableApiResponse';
import {FieldOfStudyCompactDtoPagedListApiResponse} from './model/FieldOfStudyCompactDtoPagedListApiResponse';
import {FieldOfStudyCreateDto} from './model/FieldOfStudyCreateDto';
import {FieldOfStudyDto} from './model/FieldOfStudyDto';
import {FieldOfStudyDtoApiResponse} from './model/FieldOfStudyDtoApiResponse';
import {FieldOfStudyEditDto} from './model/FieldOfStudyEditDto';
import {FileType} from './model/FileType';
import {FilesUploadBody} from './model/FilesUploadBody';
import {FunctionalAreaCompactDto} from './model/FunctionalAreaCompactDto';
import {FunctionalAreaCompactDtoApiResponse} from './model/FunctionalAreaCompactDtoApiResponse';
import {FunctionalAreaCompactDtoIEnumerableApiResponse} from './model/FunctionalAreaCompactDtoIEnumerableApiResponse';
import {FunctionalAreaCompactDtoPagedListApiResponse} from './model/FunctionalAreaCompactDtoPagedListApiResponse';
import {FunctionalAreaCreateDto} from './model/FunctionalAreaCreateDto';
import {FunctionalAreaDto} from './model/FunctionalAreaDto';
import {FunctionalAreaDtoApiResponse} from './model/FunctionalAreaDtoApiResponse';
import {FunctionalAreaEditDto} from './model/FunctionalAreaEditDto';
import {GenderType} from './model/GenderType';
import {GlobalConfigrationCompactDto} from './model/GlobalConfigrationCompactDto';
import {GlobalConfigrationCompactDtoApiResponse} from './model/GlobalConfigrationCompactDtoApiResponse';
import {GlobalConfigrationCompactDtoIEnumerableApiResponse} from './model/GlobalConfigrationCompactDtoIEnumerableApiResponse';
import {GlobalConfigrationCreateDto} from './model/GlobalConfigrationCreateDto';
import {GlobalConfigrationEditDto} from './model/GlobalConfigrationEditDto';
import {GlobalConfigurationDto} from './model/GlobalConfigurationDto';
import {GlobalConfigurationDtoApiResponse} from './model/GlobalConfigurationDtoApiResponse';
import {Hobby} from './model/Hobby';
import {HotSkillCompactDto} from './model/HotSkillCompactDto';
import {HotSkillCompactDtoApiResponse} from './model/HotSkillCompactDtoApiResponse';
import {HotSkillCompactDtoIEnumerableApiResponse} from './model/HotSkillCompactDtoIEnumerableApiResponse';
import {HotSkillCompactDtoPagedListApiResponse} from './model/HotSkillCompactDtoPagedListApiResponse';
import {HotSkillCreateDto} from './model/HotSkillCreateDto';
import {HotSkillDto} from './model/HotSkillDto';
import {HotSkillDtoApiResponse} from './model/HotSkillDtoApiResponse';
import {HotSkillEditDto} from './model/HotSkillEditDto';
import {IntegrationCompactDto} from './model/IntegrationCompactDto';
import {IntegrationCompactDtoIEnumerableApiResponse} from './model/IntegrationCompactDtoIEnumerableApiResponse';
import {IntegrationDto} from './model/IntegrationDto';
import {InterviewStatus} from './model/InterviewStatus';
import {JDClientInterviewRoundPanelCreateDto} from './model/JDClientInterviewRoundPanelCreateDto';
import {JDClientInterviewRoundPanelDto} from './model/JDClientInterviewRoundPanelDto';
import {JdAndAddressCompactDto} from './model/JdAndAddressCompactDto';
import {JdAndAssessmentCompactDto} from './model/JdAndAssessmentCompactDto';
import {JdAndAssessmentCompactDtoIEnumerableApiResponse} from './model/JdAndAssessmentCompactDtoIEnumerableApiResponse';
import {JdAndAssessmentCreateDto} from './model/JdAndAssessmentCreateDto';
import {JdAndAssessmentDto} from './model/JdAndAssessmentDto';
import {JdAndAssessmentDtoIEnumerableApiResponse} from './model/JdAndAssessmentDtoIEnumerableApiResponse';
import {JdAndCandidateStatusDto} from './model/JdAndCandidateStatusDto';
import {JdAndCandidateStatusDtoApiResponse} from './model/JdAndCandidateStatusDtoApiResponse';
import {JdAndCandidateStatusDtoIEnumerableApiResponse} from './model/JdAndCandidateStatusDtoIEnumerableApiResponse';
import {JdAndTaggedCandidateDto} from './model/JdAndTaggedCandidateDto';
import {JdAndTaggedCandidateDtoApiResponse} from './model/JdAndTaggedCandidateDtoApiResponse';
import {JdAndTaggedCandidateDtoIEnumerableApiResponse} from './model/JdAndTaggedCandidateDtoIEnumerableApiResponse';
import {JdAndTaggedCandidateStatusType} from './model/JdAndTaggedCandidateStatusType';
import {JdClientInterviewCompactDto} from './model/JdClientInterviewCompactDto';
import {JdClientInterviewCompactDtoApiResponse} from './model/JdClientInterviewCompactDtoApiResponse';
import {JdClientInterviewCompactDtoIEnumerableApiResponse} from './model/JdClientInterviewCompactDtoIEnumerableApiResponse';
import {JdClientInterviewCreateDto} from './model/JdClientInterviewCreateDto';
import {JdClientInterviewDto} from './model/JdClientInterviewDto';
import {JdClientInterviewDtoApiResponse} from './model/JdClientInterviewDtoApiResponse';
import {JdClientInterviewRoundsCreateDto} from './model/JdClientInterviewRoundsCreateDto';
import {JdClientInterviewRoundsDto} from './model/JdClientInterviewRoundsDto';
import {JdClientInterviewRoundsDtoApiResponse} from './model/JdClientInterviewRoundsDtoApiResponse';
import {JdClientInterviewRoundsEditDto} from './model/JdClientInterviewRoundsEditDto';
import {JdInterviewRoundsCompactDto} from './model/JdInterviewRoundsCompactDto';
import {JdInterviewRoundsCompactDtoApiResponse} from './model/JdInterviewRoundsCompactDtoApiResponse';
import {JdInterviewRoundsCompactDtoIEnumerableApiResponse} from './model/JdInterviewRoundsCompactDtoIEnumerableApiResponse';
import {JdInterviewRoundsCreateDto} from './model/JdInterviewRoundsCreateDto';
import {JdInterviewRoundsDto} from './model/JdInterviewRoundsDto';
import {JdInterviewRoundsDtoApiResponse} from './model/JdInterviewRoundsDtoApiResponse';
import {JdInterviewRoundsEditDto} from './model/JdInterviewRoundsEditDto';
import {JdInterviewRoundsPanelCompactDto} from './model/JdInterviewRoundsPanelCompactDto';
import {JdInterviewRoundsPanelCompactDtoApiResponse} from './model/JdInterviewRoundsPanelCompactDtoApiResponse';
import {JdInterviewRoundsPanelCreateDto} from './model/JdInterviewRoundsPanelCreateDto';
import {JdInterviewRoundsPanelDto} from './model/JdInterviewRoundsPanelDto';
import {JdInterviewRoundsPanelDtoApiResponse} from './model/JdInterviewRoundsPanelDtoApiResponse';
import {JdInterviewRoundsPanelDtoIEnumerableApiResponse} from './model/JdInterviewRoundsPanelDtoIEnumerableApiResponse';
import {JdInterviewRoundsPanelEditDto} from './model/JdInterviewRoundsPanelEditDto';
import {JdSkillCompactDto} from './model/JdSkillCompactDto';
import {JdSkillCompactDtoApiResponse} from './model/JdSkillCompactDtoApiResponse';
import {JdSkillDto} from './model/JdSkillDto';
import {JdSkillDtoApiResponse} from './model/JdSkillDtoApiResponse';
import {JdSkillEditDto} from './model/JdSkillEditDto';
import {JdSkillPlatformCompactDto} from './model/JdSkillPlatformCompactDto';
import {JdSkillPlatformCompactDtoApiResponse} from './model/JdSkillPlatformCompactDtoApiResponse';
import {JdSkillPlatformCreateDto} from './model/JdSkillPlatformCreateDto';
import {JdSkillPlatformDto} from './model/JdSkillPlatformDto';
import {JdSkillPlatformDtoApiResponse} from './model/JdSkillPlatformDtoApiResponse';
import {JdSkillPlatformEditDto} from './model/JdSkillPlatformEditDto';
import {JdSkillType} from './model/JdSkillType';
import {JdSoftSkillDto} from './model/JdSoftSkillDto';
import {JdSoftSkillDtoIEnumerableApiResponse} from './model/JdSoftSkillDtoIEnumerableApiResponse';
import {JdSoftSkillEditDto} from './model/JdSoftSkillEditDto';
import {JdTraitCompactDto} from './model/JdTraitCompactDto';
import {JdTraitCompactDtoApiResponse} from './model/JdTraitCompactDtoApiResponse';
import {JdTraitCreateDto} from './model/JdTraitCreateDto';
import {JdTraitDto} from './model/JdTraitDto';
import {JdTraitDtoApiResponse} from './model/JdTraitDtoApiResponse';
import {JdTraitEditDto} from './model/JdTraitEditDto';
import {JobDescriptionCompactDto} from './model/JobDescriptionCompactDto';
import {JobDescriptionCompactDtoApiResponse} from './model/JobDescriptionCompactDtoApiResponse';
import {JobDescriptionCompactDtoIEnumerableApiResponse} from './model/JobDescriptionCompactDtoIEnumerableApiResponse';
import {JobDescriptionCreateDto} from './model/JobDescriptionCreateDto';
import {JobDescriptionDto} from './model/JobDescriptionDto';
import {JobDescriptionDtoApiResponse} from './model/JobDescriptionDtoApiResponse';
import {JobDescriptionDtoIEnumerableApiResponse} from './model/JobDescriptionDtoIEnumerableApiResponse';
import {JobDescriptionEditDto} from './model/JobDescriptionEditDto';
import {JobDescriptionFilterDto} from './model/JobDescriptionFilterDto';
import {JobDescriptionFilterDtoIEnumerableApiResponse} from './model/JobDescriptionFilterDtoIEnumerableApiResponse';
import {JobDescriptionSearchDto} from './model/JobDescriptionSearchDto';
import {JobDescriptionSearchDtoIEnumerableApiResponse} from './model/JobDescriptionSearchDtoIEnumerableApiResponse';
import {JobDescriptionSkillDto} from './model/JobDescriptionSkillDto';
import {JobDescriptionSkillDtoApiResponse} from './model/JobDescriptionSkillDtoApiResponse';
import {JobDescriptionStatusDto} from './model/JobDescriptionStatusDto';
import {JobProfileType} from './model/JobProfileType';
import {JobTitleAliasCompactDto} from './model/JobTitleAliasCompactDto';
import {JobTitleAliasCompactDtoApiResponse} from './model/JobTitleAliasCompactDtoApiResponse';
import {JobTitleAliasCompactDtoIEnumerableApiResponse} from './model/JobTitleAliasCompactDtoIEnumerableApiResponse';
import {JobTitleAliasCompactDtoPagedListApiResponse} from './model/JobTitleAliasCompactDtoPagedListApiResponse';
import {JobTitleAliasCreateDto} from './model/JobTitleAliasCreateDto';
import {JobTitleAliasDto} from './model/JobTitleAliasDto';
import {JobTitleAliasDtoApiResponse} from './model/JobTitleAliasDtoApiResponse';
import {JobTitleAliasEditDto} from './model/JobTitleAliasEditDto';
import {JobTitleAndSkillCompactDto} from './model/JobTitleAndSkillCompactDto';
import {JobTitleAndSkillCompactDtoApiResponse} from './model/JobTitleAndSkillCompactDtoApiResponse';
import {JobTitleAndSkillCompactDtoIEnumerableApiResponse} from './model/JobTitleAndSkillCompactDtoIEnumerableApiResponse';
import {JobTitleAndSkillCompactDtoPagedListApiResponse} from './model/JobTitleAndSkillCompactDtoPagedListApiResponse';
import {JobTitleAndSkillCreateDto} from './model/JobTitleAndSkillCreateDto';
import {JobTitleAndSkillDto} from './model/JobTitleAndSkillDto';
import {JobTitleAndSkillDtoApiResponse} from './model/JobTitleAndSkillDtoApiResponse';
import {JobTitleAndSkillEditDto} from './model/JobTitleAndSkillEditDto';
import {JobTitleCompactDto} from './model/JobTitleCompactDto';
import {JobTitleCompactDtoApiResponse} from './model/JobTitleCompactDtoApiResponse';
import {JobTitleCompactDtoIEnumerableApiResponse} from './model/JobTitleCompactDtoIEnumerableApiResponse';
import {JobTitleCompactDtoPagedListApiResponse} from './model/JobTitleCompactDtoPagedListApiResponse';
import {JobTitleCreateDto} from './model/JobTitleCreateDto';
import {JobTitleDto} from './model/JobTitleDto';
import {JobTitleDtoApiResponse} from './model/JobTitleDtoApiResponse';
import {JobTitleEditDto} from './model/JobTitleEditDto';
import {Language} from './model/Language';
import {LanguageCompactDto} from './model/LanguageCompactDto';
import {LanguageCompactDtoApiResponse} from './model/LanguageCompactDtoApiResponse';
import {LanguageCompactDtoIEnumerableApiResponse} from './model/LanguageCompactDtoIEnumerableApiResponse';
import {LanguageCompactDtoPagedListApiResponse} from './model/LanguageCompactDtoPagedListApiResponse';
import {LanguageCreateDto} from './model/LanguageCreateDto';
import {LanguageDto} from './model/LanguageDto';
import {LanguageDtoApiResponse} from './model/LanguageDtoApiResponse';
import {LanguageEditDto} from './model/LanguageEditDto';
import {LanguageFilterDto} from './model/LanguageFilterDto';
import {LanguageFilterDtoIEnumerableApiResponse} from './model/LanguageFilterDtoIEnumerableApiResponse';
import {LogInDto} from './model/LogInDto';
import {MPathwayAttributeCompactDto} from './model/MPathwayAttributeCompactDto';
import {MPathwayAttributeCompactDtoApiResponse} from './model/MPathwayAttributeCompactDtoApiResponse';
import {MPathwayAttributeCompactDtoIEnumerableApiResponse} from './model/MPathwayAttributeCompactDtoIEnumerableApiResponse';
import {MPathwayAttributeCompactDtoPagedListApiResponse} from './model/MPathwayAttributeCompactDtoPagedListApiResponse';
import {MPathwayAttributeCreateDto} from './model/MPathwayAttributeCreateDto';
import {MPathwayAttributeDto} from './model/MPathwayAttributeDto';
import {MPathwayAttributeDtoApiResponse} from './model/MPathwayAttributeDtoApiResponse';
import {MPathwayAttributeEditDto} from './model/MPathwayAttributeEditDto';
import {MPathwayOutcomeAttributeCompactDto} from './model/MPathwayOutcomeAttributeCompactDto';
import {MPathwayOutcomeAttributeCompactDtoApiResponse} from './model/MPathwayOutcomeAttributeCompactDtoApiResponse';
import {MPathwayOutcomeAttributeCompactDtoIEnumerableApiResponse} from './model/MPathwayOutcomeAttributeCompactDtoIEnumerableApiResponse';
import {MPathwayOutcomeAttributeCompactDtoPagedListApiResponse} from './model/MPathwayOutcomeAttributeCompactDtoPagedListApiResponse';
import {MPathwayOutcomeAttributeCreateDto} from './model/MPathwayOutcomeAttributeCreateDto';
import {MPathwayOutcomeAttributeDto} from './model/MPathwayOutcomeAttributeDto';
import {MPathwayOutcomeAttributeDtoApiResponse} from './model/MPathwayOutcomeAttributeDtoApiResponse';
import {MPathwayOutcomeAttributeEditDto} from './model/MPathwayOutcomeAttributeEditDto';
import {MPathwayOutcomeCompactDto} from './model/MPathwayOutcomeCompactDto';
import {MPathwayOutcomeCompactDtoApiResponse} from './model/MPathwayOutcomeCompactDtoApiResponse';
import {MPathwayOutcomeCompactDtoIEnumerableApiResponse} from './model/MPathwayOutcomeCompactDtoIEnumerableApiResponse';
import {MPathwayOutcomeCompactDtoPagedListApiResponse} from './model/MPathwayOutcomeCompactDtoPagedListApiResponse';
import {MPathwayOutcomeCreateDto} from './model/MPathwayOutcomeCreateDto';
import {MPathwayOutcomeDto} from './model/MPathwayOutcomeDto';
import {MPathwayOutcomeDtoApiResponse} from './model/MPathwayOutcomeDtoApiResponse';
import {MPathwayOutcomeEditDto} from './model/MPathwayOutcomeEditDto';
import {MPathwayOutcomeFilterDto} from './model/MPathwayOutcomeFilterDto';
import {MPathwayOutcomeFilterDtoIEnumerableApiResponse} from './model/MPathwayOutcomeFilterDtoIEnumerableApiResponse';
import {MPathwayType} from './model/MPathwayType';
import {MPathwayTypeCompactDto} from './model/MPathwayTypeCompactDto';
import {MPathwayTypeCompactDtoIEnumerableApiResponse} from './model/MPathwayTypeCompactDtoIEnumerableApiResponse';
import {MPathwayTypeCompactDtoPagedListApiResponse} from './model/MPathwayTypeCompactDtoPagedListApiResponse';
import {MPathwayTypeCreateDto} from './model/MPathwayTypeCreateDto';
import {MPathwayTypeDto} from './model/MPathwayTypeDto';
import {MPathwayTypeDtoApiResponse} from './model/MPathwayTypeDtoApiResponse';
import {MPathwayTypeDtoIEnumerableApiResponse} from './model/MPathwayTypeDtoIEnumerableApiResponse';
import {MPathwayTypeEditDto} from './model/MPathwayTypeEditDto';
import {MPathwayTypeFilterDto} from './model/MPathwayTypeFilterDto';
import {MPathwayTypeFilterDtoIEnumerableApiResponse} from './model/MPathwayTypeFilterDtoIEnumerableApiResponse';
import {MStepAttributeCompactDto} from './model/MStepAttributeCompactDto';
import {MStepAttributeCompactDtoApiResponse} from './model/MStepAttributeCompactDtoApiResponse';
import {MStepAttributeCompactDtoIEnumerableApiResponse} from './model/MStepAttributeCompactDtoIEnumerableApiResponse';
import {MStepAttributeCompactDtoPagedListApiResponse} from './model/MStepAttributeCompactDtoPagedListApiResponse';
import {MStepAttributeCreateDto} from './model/MStepAttributeCreateDto';
import {MStepAttributeDto} from './model/MStepAttributeDto';
import {MStepAttributeDtoApiResponse} from './model/MStepAttributeDtoApiResponse';
import {MStepAttributeEditDto} from './model/MStepAttributeEditDto';
import {MStepCompactDto} from './model/MStepCompactDto';
import {MStepCompactDtoApiResponse} from './model/MStepCompactDtoApiResponse';
import {MStepCompactDtoIEnumerableApiResponse} from './model/MStepCompactDtoIEnumerableApiResponse';
import {MStepCompactDtoPagedListApiResponse} from './model/MStepCompactDtoPagedListApiResponse';
import {MStepCreateDto} from './model/MStepCreateDto';
import {MStepDto} from './model/MStepDto';
import {MStepDtoApiResponse} from './model/MStepDtoApiResponse';
import {MStepEditDto} from './model/MStepEditDto';
import {MStepFilterDto} from './model/MStepFilterDto';
import {MStepFilterDtoIEnumerableApiResponse} from './model/MStepFilterDtoIEnumerableApiResponse';
import {MStepOutcomeCommunicationCompactDto} from './model/MStepOutcomeCommunicationCompactDto';
import {MStepOutcomeCommunicationCompactDtoApiResponse} from './model/MStepOutcomeCommunicationCompactDtoApiResponse';
import {MStepOutcomeCommunicationCompactDtoIEnumerableApiResponse} from './model/MStepOutcomeCommunicationCompactDtoIEnumerableApiResponse';
import {MStepOutcomeCommunicationCompactDtoPagedListApiResponse} from './model/MStepOutcomeCommunicationCompactDtoPagedListApiResponse';
import {MStepOutcomeCommunicationCreateDto} from './model/MStepOutcomeCommunicationCreateDto';
import {MStepOutcomeCommunicationDto} from './model/MStepOutcomeCommunicationDto';
import {MStepOutcomeCommunicationDtoApiResponse} from './model/MStepOutcomeCommunicationDtoApiResponse';
import {MStepOutcomeCommunicationEditDto} from './model/MStepOutcomeCommunicationEditDto';
import {MStepOutcomeCompactDto} from './model/MStepOutcomeCompactDto';
import {MStepOutcomeCompactDtoApiResponse} from './model/MStepOutcomeCompactDtoApiResponse';
import {MStepOutcomeCompactDtoIEnumerableApiResponse} from './model/MStepOutcomeCompactDtoIEnumerableApiResponse';
import {MStepOutcomeCompactDtoPagedListApiResponse} from './model/MStepOutcomeCompactDtoPagedListApiResponse';
import {MStepOutcomeCreateDto} from './model/MStepOutcomeCreateDto';
import {MStepOutcomeDto} from './model/MStepOutcomeDto';
import {MStepOutcomeDtoApiResponse} from './model/MStepOutcomeDtoApiResponse';
import {MStepOutcomeEditDto} from './model/MStepOutcomeEditDto';
import {MStepOutcomeFilterDto} from './model/MStepOutcomeFilterDto';
import {MStepOutcomeFilterDtoIEnumerableApiResponse} from './model/MStepOutcomeFilterDtoIEnumerableApiResponse';
import {MStepTextCompactDto} from './model/MStepTextCompactDto';
import {MStepTextCompactDtoApiResponse} from './model/MStepTextCompactDtoApiResponse';
import {MStepTextCompactDtoIEnumerableApiResponse} from './model/MStepTextCompactDtoIEnumerableApiResponse';
import {MStepTextCompactDtoPagedListApiResponse} from './model/MStepTextCompactDtoPagedListApiResponse';
import {MStepTextCreateDto} from './model/MStepTextCreateDto';
import {MStepTextDto} from './model/MStepTextDto';
import {MStepTextDtoApiResponse} from './model/MStepTextDtoApiResponse';
import {MStepTextEditDto} from './model/MStepTextEditDto';
import {NormalizationStatus} from './model/NormalizationStatus';
import {OntologyCompactDto} from './model/OntologyCompactDto';
import {OntologyCompactDtoApiResponse} from './model/OntologyCompactDtoApiResponse';
import {OntologyCompactDtoIEnumerableApiResponse} from './model/OntologyCompactDtoIEnumerableApiResponse';
import {OntologyCompactDtoPagedListApiResponse} from './model/OntologyCompactDtoPagedListApiResponse';
import {OntologyCreateDto} from './model/OntologyCreateDto';
import {OntologyDto} from './model/OntologyDto';
import {OntologyDtoApiResponse} from './model/OntologyDtoApiResponse';
import {OntologyEditDto} from './model/OntologyEditDto';
import {Overview} from './model/Overview';
import {PageResult} from './model/PageResult';
import {ParsedData} from './model/ParsedData';
import {ParserCompactDto} from './model/ParserCompactDto';
import {ParserCompactDtoApiResponse} from './model/ParserCompactDtoApiResponse';
import {ParserCompactDtoIEnumerableApiResponse} from './model/ParserCompactDtoIEnumerableApiResponse';
import {ParserCompactDtoPagedListApiResponse} from './model/ParserCompactDtoPagedListApiResponse';
import {ParserCreateDto} from './model/ParserCreateDto';
import {ParserDocumentCompactDto} from './model/ParserDocumentCompactDto';
import {ParserDocumentCompactDtoApiResponse} from './model/ParserDocumentCompactDtoApiResponse';
import {ParserDocumentCompactDtoIEnumerableApiResponse} from './model/ParserDocumentCompactDtoIEnumerableApiResponse';
import {ParserDocumentCompactDtoPagedListApiResponse} from './model/ParserDocumentCompactDtoPagedListApiResponse';
import {ParserDocumentDto} from './model/ParserDocumentDto';
import {ParserDocumentDtoApiResponse} from './model/ParserDocumentDtoApiResponse';
import {ParserDocumentEditDto} from './model/ParserDocumentEditDto';
import {ParserDocumentParseRChilliJSONBody} from './model/ParserDocumentParseRChilliJSONBody';
import {ParserDto} from './model/ParserDto';
import {ParserDtoApiResponse} from './model/ParserDtoApiResponse';
import {ParserEditDto} from './model/ParserEditDto';
import {ParserType} from './model/ParserType';
import {PathwayAttributeCreateDto} from './model/PathwayAttributeCreateDto';
import {PathwayAttributeDto} from './model/PathwayAttributeDto';
import {PathwayCompactDto} from './model/PathwayCompactDto';
import {PathwayCompactDtoIEnumerableApiResponse} from './model/PathwayCompactDtoIEnumerableApiResponse';
import {PathwayCreateDto} from './model/PathwayCreateDto';
import {PathwayDto} from './model/PathwayDto';
import {PathwayDtoApiResponse} from './model/PathwayDtoApiResponse';
import {PathwayOutcomeAttributeCreateDto} from './model/PathwayOutcomeAttributeCreateDto';
import {PathwayOutcomeAttributeDto} from './model/PathwayOutcomeAttributeDto';
import {PathwayOutcomeCreateDto} from './model/PathwayOutcomeCreateDto';
import {PathwayOutcomeDto} from './model/PathwayOutcomeDto';
import {PathwayStepAttributeCreateDto} from './model/PathwayStepAttributeCreateDto';
import {PathwayStepAttributeDto} from './model/PathwayStepAttributeDto';
import {PathwayStepCreateDto} from './model/PathwayStepCreateDto';
import {PathwayStepDto} from './model/PathwayStepDto';
import {PathwayStepOutcomeCommunicationCreateDto} from './model/PathwayStepOutcomeCommunicationCreateDto';
import {PathwayStepOutcomeCommunicationDto} from './model/PathwayStepOutcomeCommunicationDto';
import {PathwayStepOutcomeCreateDto} from './model/PathwayStepOutcomeCreateDto';
import {PathwayStepOutcomeDto} from './model/PathwayStepOutcomeDto';
import {PathwayStepTextCreateDto} from './model/PathwayStepTextCreateDto';
import {PathwayStepTextDto} from './model/PathwayStepTextDto';
import {ProgressiveStatus} from './model/ProgressiveStatus';
import {ProjSkill} from './model/ProjSkill';
import {ProjectDetail} from './model/ProjectDetail';
import {ProjectDto} from './model/ProjectDto';
import {ProjectSkillCreateDto} from './model/ProjectSkillCreateDto';
import {ProjectSkillDto} from './model/ProjectSkillDto';
import {ProjectSkillEditDto} from './model/ProjectSkillEditDto';
import {QualificationDto} from './model/QualificationDto';
import {RSAdditionalDetails} from './model/RSAdditionalDetails';
import {RSCandidateAddress} from './model/RSCandidateAddress';
import {RSCandidateCertificate} from './model/RSCandidateCertificate';
import {RSCandidatePersonalInfoDto} from './model/RSCandidatePersonalInfoDto';
import {RSCandidateTrait} from './model/RSCandidateTrait';
import {RSExperienceTimeLine} from './model/RSExperienceTimeLine';
import {RSExpertise} from './model/RSExpertise';
import {RSNormalizationStatus} from './model/RSNormalizationStatus';
import {RSProfileCompletion} from './model/RSProfileCompletion';
import {RSProfileHeadLine} from './model/RSProfileHeadLine';
import {RSProfileImage} from './model/RSProfileImage';
import {RSProject} from './model/RSProject';
import {RSQualificationTimeLine} from './model/RSQualificationTimeLine';
import {RSSocialMediaWebsite} from './model/RSSocialMediaWebsite';
import {RSSoftSkill} from './model/RSSoftSkill';
import {RSTechnicalSkill} from './model/RSTechnicalSkill';
import {RSTechnicalSkillPlatform} from './model/RSTechnicalSkillPlatform';
import {RSTimeLine} from './model/RSTimeLine';
import {RSWebsite} from './model/RSWebsite';
import {RoleDto} from './model/RoleDto';
import {SalaryDto} from './model/SalaryDto';
import {Skill} from './model/Skill';
import {SkillCompactDto} from './model/SkillCompactDto';
import {SkillCompactDtoApiResponse} from './model/SkillCompactDtoApiResponse';
import {SkillCompactDtoIEnumerableApiResponse} from './model/SkillCompactDtoIEnumerableApiResponse';
import {SkillCompactDtoPagedListApiResponse} from './model/SkillCompactDtoPagedListApiResponse';
import {SkillCreateDto} from './model/SkillCreateDto';
import {SkillData} from './model/SkillData';
import {SkillDto} from './model/SkillDto';
import {SkillDtoApiResponse} from './model/SkillDtoApiResponse';
import {SkillEditDto} from './model/SkillEditDto';
import {SkillFilterDto} from './model/SkillFilterDto';
import {SkillFilterDtoIEnumerableApiResponse} from './model/SkillFilterDtoIEnumerableApiResponse';
import {SkillLevel} from './model/SkillLevel';
import {SkillList} from './model/SkillList';
import {SkillPlatformCompactDto} from './model/SkillPlatformCompactDto';
import {SkillPlatformCompactDtoApiResponse} from './model/SkillPlatformCompactDtoApiResponse';
import {SkillPlatformCompactDtoIEnumerableApiResponse} from './model/SkillPlatformCompactDtoIEnumerableApiResponse';
import {SkillPlatformCompactDtoPagedListApiResponse} from './model/SkillPlatformCompactDtoPagedListApiResponse';
import {SkillPlatformCreateDto} from './model/SkillPlatformCreateDto';
import {SkillPlatformDto} from './model/SkillPlatformDto';
import {SkillPlatformDtoApiResponse} from './model/SkillPlatformDtoApiResponse';
import {SkillPlatformEditDto} from './model/SkillPlatformEditDto';
import {SkillPlatformFilterDto} from './model/SkillPlatformFilterDto';
import {SkillType} from './model/SkillType';
import {SocialMediaType} from './model/SocialMediaType';
import {SoftSkill} from './model/SoftSkill';
import {SourcingSequenceCompactDto} from './model/SourcingSequenceCompactDto';
import {SourcingSequenceCompactDtoApiResponse} from './model/SourcingSequenceCompactDtoApiResponse';
import {SourcingSequenceCompactDtoIEnumerableApiResponse} from './model/SourcingSequenceCompactDtoIEnumerableApiResponse';
import {SourcingSequenceCompactDtoPagedListApiResponse} from './model/SourcingSequenceCompactDtoPagedListApiResponse';
import {SourcingSequenceCreateDto} from './model/SourcingSequenceCreateDto';
import {SourcingSequenceDto} from './model/SourcingSequenceDto';
import {SourcingSequenceDtoApiResponse} from './model/SourcingSequenceDtoApiResponse';
import {SourcingSequenceEditDto} from './model/SourcingSequenceEditDto';
import {SourcingSequenceFilterDto} from './model/SourcingSequenceFilterDto';
import {SourcingSequenceFilterDtoIEnumerableApiResponse} from './model/SourcingSequenceFilterDtoIEnumerableApiResponse';
import {SpecializationDto} from './model/SpecializationDto';
import {StateCompactDto} from './model/StateCompactDto';
import {StateCompactDtoApiResponse} from './model/StateCompactDtoApiResponse';
import {StateCompactDtoIEnumerableApiResponse} from './model/StateCompactDtoIEnumerableApiResponse';
import {StateCompactDtoPagedListApiResponse} from './model/StateCompactDtoPagedListApiResponse';
import {StateCreateDto} from './model/StateCreateDto';
import {StateDto} from './model/StateDto';
import {StateDtoApiResponse} from './model/StateDtoApiResponse';
import {StateEditDto} from './model/StateEditDto';
import {StatusType} from './model/StatusType';
import {StringIEnumerableApiResponse} from './model/StringIEnumerableApiResponse';
import {SubRoleDto} from './model/SubRoleDto';
import {TaggedCandidates} from './model/TaggedCandidates';
import {Trait} from './model/Trait';
import {UpdateBasicDocumentDetailsCandidateIdBody} from './model/UpdateBasicDocumentDetailsCandidateIdBody';
import {UploadBackGroundImageCandidateIdBody} from './model/UploadBackGroundImageCandidateIdBody';
import {UploadCollegeImageCandidateIdBody} from './model/UploadCollegeImageCandidateIdBody';
import {UploadProfileImageCandidateIdBody} from './model/UploadProfileImageCandidateIdBody';
import {UploadResumeCandidateIdBody} from './model/UploadResumeCandidateIdBody';
import {UserDto} from './model/UserDto';
import {UserDtoApiResponse} from './model/UserDtoApiResponse';
import {ValidateCandidateDto} from './model/ValidateCandidateDto';
import {Website} from './model/Website';
import {WebsiteType} from './model/WebsiteType';
import {WorkplaceType} from './model/WorkplaceType';
import {AccountApi} from './api/AccountApi';
import {AssessmentApi} from './api/AssessmentApi';
import {AssessmentSkillApi} from './api/AssessmentSkillApi';
import {BucketsApi} from './api/BucketsApi';
import {BusinessVerticalApi} from './api/BusinessVerticalApi';
import {CandidateApi} from './api/CandidateApi';
import {CandidateCertificateApi} from './api/CandidateCertificateApi';
import {CandidateConfigurationApi} from './api/CandidateConfigurationApi';
import {CandidateDocumentApi} from './api/CandidateDocumentApi';
import {CandidateEmployerApi} from './api/CandidateEmployerApi';
import {CandidatePersonalInfoApi} from './api/CandidatePersonalInfoApi';
import {CandidateProfileApi} from './api/CandidateProfileApi';
import {CandidateProjectsApi} from './api/CandidateProjectsApi';
import {CandidateQualificationApi} from './api/CandidateQualificationApi';
import {CandidateSkillApi} from './api/CandidateSkillApi';
import {CandidateSkillPlatformApi} from './api/CandidateSkillPlatformApi';
import {CandidateWebsiteApi} from './api/CandidateWebsiteApi';
import {CertificateApi} from './api/CertificateApi';
import {CertificateAliasApi} from './api/CertificateAliasApi';
import {CityApi} from './api/CityApi';
import {ClientApi} from './api/ClientApi';
import {ClientContractApi} from './api/ClientContractApi';
import {ClientDashboardApi} from './api/ClientDashboardApi';
import {ClientInterviewRoundApi} from './api/ClientInterviewRoundApi';
import {CompanyApi} from './api/CompanyApi';
import {CompanyAliasApi} from './api/CompanyAliasApi';
import {ContactApi} from './api/ContactApi';
import {ContentApi} from './api/ContentApi';
import {CountryApi} from './api/CountryApi';
import {CourseApi} from './api/CourseApi';
import {DegreeApi} from './api/DegreeApi';
import {DegreeAliasApi} from './api/DegreeAliasApi';
import {DocumentApi} from './api/DocumentApi';
import {DummyApi} from './api/DummyApi';
import {EducationInstituteApi} from './api/EducationInstituteApi';
import {FieldOfStudyApi} from './api/FieldOfStudyApi';
import {FilesApi} from './api/FilesApi';
import {FunctionalAreaApi} from './api/FunctionalAreaApi';
import {GlobalConfigrationApi} from './api/GlobalConfigrationApi';
import {HotSkillApi} from './api/HotSkillApi';
import {IntegrationApi} from './api/IntegrationApi';
import {JdAndAssessmentApi} from './api/JdAndAssessmentApi';
import {JdAndCandidateStatusApi} from './api/JdAndCandidateStatusApi';
import {JdAndTaggedCandidateApi} from './api/JdAndTaggedCandidateApi';
import {JdClientInterviewApi} from './api/JdClientInterviewApi';
import {JdClientInterviewRoundsApi} from './api/JdClientInterviewRoundsApi';
import {JdInterviewRoundApi} from './api/JdInterviewRoundApi';
import {JdInterviewRoundsPanelApi} from './api/JdInterviewRoundsPanelApi';
import {JdSkillApi} from './api/JdSkillApi';
import {JdSkillPlatformApi} from './api/JdSkillPlatformApi';
import {JdSoftSkillApi} from './api/JdSoftSkillApi';
import {JdTraitApi} from './api/JdTraitApi';
import {JobDescriptionApi} from './api/JobDescriptionApi';
import {JobTitleApi} from './api/JobTitleApi';
import {JobTitleAliasApi} from './api/JobTitleAliasApi';
import {JobTitleAndSkillApi} from './api/JobTitleAndSkillApi';
import {LanguageApi} from './api/LanguageApi';
import {MPathwayAttributeApi} from './api/MPathwayAttributeApi';
import {MPathwayOutcomeApi} from './api/MPathwayOutcomeApi';
import {MPathwayOutcomeAttributeApi} from './api/MPathwayOutcomeAttributeApi';
import {MPathwayTypeApi} from './api/MPathwayTypeApi';
import {MStepApi} from './api/MStepApi';
import {MStepAttributeApi} from './api/MStepAttributeApi';
import {MStepOutcomeApi} from './api/MStepOutcomeApi';
import {MStepOutcomeCommunicationApi} from './api/MStepOutcomeCommunicationApi';
import {MStepTextApi} from './api/MStepTextApi';
import {OntologyApi} from './api/OntologyApi';
import {ParserApi} from './api/ParserApi';
import {ParserDocumentApi} from './api/ParserDocumentApi';
import {PathwayApi} from './api/PathwayApi';
import {RSAApi} from './api/RSAApi';
import {SkillApi} from './api/SkillApi';
import {SkillPlatformApi} from './api/SkillPlatformApi';
import {SourcingSequenceApi} from './api/SourcingSequenceApi';
import {StateApi} from './api/StateApi';

/**
* Object.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var ExtractorEngineApi = require('index'); // See note below*.
* var xxxSvc = new ExtractorEngineApi.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new ExtractorEngineApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new ExtractorEngineApi.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new ExtractorEngineApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version v3.76(UAT)
*/
export {
    /**
     * The ApiClient constructor.
     * @property {module:ApiClient}
     */
    ApiClient,

    /**
     * The AcceptanceType model constructor.
     * @property {module:model/AcceptanceType}
     */
    AcceptanceType,

    /**
     * The AcceptedStatus model constructor.
     * @property {module:model/AcceptedStatus}
     */
    AcceptedStatus,

    /**
     * The AddressCreateDto model constructor.
     * @property {module:model/AddressCreateDto}
     */
    AddressCreateDto,

    /**
     * The AddressDto model constructor.
     * @property {module:model/AddressDto}
     */
    AddressDto,

    /**
     * The AddressEditDto model constructor.
     * @property {module:model/AddressEditDto}
     */
    AddressEditDto,

    /**
     * The AddressType model constructor.
     * @property {module:model/AddressType}
     */
    AddressType,

    /**
     * The ApiClientContractBody model constructor.
     * @property {module:model/ApiClientContractBody}
     */
    ApiClientContractBody,

    /**
     * The ApiClientContractBody1 model constructor.
     * @property {module:model/ApiClientContractBody1}
     */
    ApiClientContractBody1,

    /**
     * The ApiParserDocumentBody model constructor.
     * @property {module:model/ApiParserDocumentBody}
     */
    ApiParserDocumentBody,

    /**
     * The AssessmentCompactDto model constructor.
     * @property {module:model/AssessmentCompactDto}
     */
    AssessmentCompactDto,

    /**
     * The AssessmentCompactDtoApiResponse model constructor.
     * @property {module:model/AssessmentCompactDtoApiResponse}
     */
    AssessmentCompactDtoApiResponse,

    /**
     * The AssessmentCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/AssessmentCompactDtoIEnumerableApiResponse}
     */
    AssessmentCompactDtoIEnumerableApiResponse,

    /**
     * The AssessmentCreateDto model constructor.
     * @property {module:model/AssessmentCreateDto}
     */
    AssessmentCreateDto,

    /**
     * The AssessmentDto model constructor.
     * @property {module:model/AssessmentDto}
     */
    AssessmentDto,

    /**
     * The AssessmentDtoApiResponse model constructor.
     * @property {module:model/AssessmentDtoApiResponse}
     */
    AssessmentDtoApiResponse,

    /**
     * The AssessmentDtoIEnumerableApiResponse model constructor.
     * @property {module:model/AssessmentDtoIEnumerableApiResponse}
     */
    AssessmentDtoIEnumerableApiResponse,

    /**
     * The AssessmentEditDto model constructor.
     * @property {module:model/AssessmentEditDto}
     */
    AssessmentEditDto,

    /**
     * The AssessmentSearchDto model constructor.
     * @property {module:model/AssessmentSearchDto}
     */
    AssessmentSearchDto,

    /**
     * The AssessmentSearchDtoIEnumerableApiResponse model constructor.
     * @property {module:model/AssessmentSearchDtoIEnumerableApiResponse}
     */
    AssessmentSearchDtoIEnumerableApiResponse,

    /**
     * The AssessmentSkillCompactDto model constructor.
     * @property {module:model/AssessmentSkillCompactDto}
     */
    AssessmentSkillCompactDto,

    /**
     * The AssessmentSkillCompactDtoApiResponse model constructor.
     * @property {module:model/AssessmentSkillCompactDtoApiResponse}
     */
    AssessmentSkillCompactDtoApiResponse,

    /**
     * The AssessmentSkillCreateDto model constructor.
     * @property {module:model/AssessmentSkillCreateDto}
     */
    AssessmentSkillCreateDto,

    /**
     * The AssessmentSkillDto model constructor.
     * @property {module:model/AssessmentSkillDto}
     */
    AssessmentSkillDto,

    /**
     * The AssessmentSkillFilterDto model constructor.
     * @property {module:model/AssessmentSkillFilterDto}
     */
    AssessmentSkillFilterDto,

    /**
     * The AssociatedEmployerCompactDto model constructor.
     * @property {module:model/AssociatedEmployerCompactDto}
     */
    AssociatedEmployerCompactDto,

    /**
     * The AssociatedEmployerCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/AssociatedEmployerCompactDtoIEnumerableApiResponse}
     */
    AssociatedEmployerCompactDtoIEnumerableApiResponse,

    /**
     * The AttributeType model constructor.
     * @property {module:model/AttributeType}
     */
    AttributeType,

    /**
     * The BooleanApiResponse model constructor.
     * @property {module:model/BooleanApiResponse}
     */
    BooleanApiResponse,

    /**
     * The BusinessVerticalCompactDto model constructor.
     * @property {module:model/BusinessVerticalCompactDto}
     */
    BusinessVerticalCompactDto,

    /**
     * The BusinessVerticalCompactDtoApiResponse model constructor.
     * @property {module:model/BusinessVerticalCompactDtoApiResponse}
     */
    BusinessVerticalCompactDtoApiResponse,

    /**
     * The BusinessVerticalCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/BusinessVerticalCompactDtoIEnumerableApiResponse}
     */
    BusinessVerticalCompactDtoIEnumerableApiResponse,

    /**
     * The BusinessVerticalCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/BusinessVerticalCompactDtoPagedListApiResponse}
     */
    BusinessVerticalCompactDtoPagedListApiResponse,

    /**
     * The BusinessVerticalCreateDto model constructor.
     * @property {module:model/BusinessVerticalCreateDto}
     */
    BusinessVerticalCreateDto,

    /**
     * The BusinessVerticalDto model constructor.
     * @property {module:model/BusinessVerticalDto}
     */
    BusinessVerticalDto,

    /**
     * The BusinessVerticalDtoApiResponse model constructor.
     * @property {module:model/BusinessVerticalDtoApiResponse}
     */
    BusinessVerticalDtoApiResponse,

    /**
     * The BusinessVerticalEditDto model constructor.
     * @property {module:model/BusinessVerticalEditDto}
     */
    BusinessVerticalEditDto,

    /**
     * The CandidateAboutDto model constructor.
     * @property {module:model/CandidateAboutDto}
     */
    CandidateAboutDto,

    /**
     * The CandidateAboutDtoApiResponse model constructor.
     * @property {module:model/CandidateAboutDtoApiResponse}
     */
    CandidateAboutDtoApiResponse,

    /**
     * The CandidateActivityAndHobbieDto model constructor.
     * @property {module:model/CandidateActivityAndHobbieDto}
     */
    CandidateActivityAndHobbieDto,

    /**
     * The CandidateActivityAndHobbieDtoApiResponse model constructor.
     * @property {module:model/CandidateActivityAndHobbieDtoApiResponse}
     */
    CandidateActivityAndHobbieDtoApiResponse,

    /**
     * The CandidateAndAddressCompactDto model constructor.
     * @property {module:model/CandidateAndAddressCompactDto}
     */
    CandidateAndAddressCompactDto,

    /**
     * The CandidateAndAddressDto model constructor.
     * @property {module:model/CandidateAndAddressDto}
     */
    CandidateAndAddressDto,

    /**
     * The CandidateAndLanguageDto model constructor.
     * @property {module:model/CandidateAndLanguageDto}
     */
    CandidateAndLanguageDto,

    /**
     * The CandidateAssessmentDto model constructor.
     * @property {module:model/CandidateAssessmentDto}
     */
    CandidateAssessmentDto,

    /**
     * The CandidateAssessmentReportDto model constructor.
     * @property {module:model/CandidateAssessmentReportDto}
     */
    CandidateAssessmentReportDto,

    /**
     * The CandidateAutoSearchDto model constructor.
     * @property {module:model/CandidateAutoSearchDto}
     */
    CandidateAutoSearchDto,

    /**
     * The CandidateBackGroundImageDto model constructor.
     * @property {module:model/CandidateBackGroundImageDto}
     */
    CandidateBackGroundImageDto,

    /**
     * The CandidateBackGroundImageDtoApiResponse model constructor.
     * @property {module:model/CandidateBackGroundImageDtoApiResponse}
     */
    CandidateBackGroundImageDtoApiResponse,

    /**
     * The CandidateBaseDto model constructor.
     * @property {module:model/CandidateBaseDto}
     */
    CandidateBaseDto,

    /**
     * The CandidateCertificateCompactDto model constructor.
     * @property {module:model/CandidateCertificateCompactDto}
     */
    CandidateCertificateCompactDto,

    /**
     * The CandidateCertificateCompactDtoApiResponse model constructor.
     * @property {module:model/CandidateCertificateCompactDtoApiResponse}
     */
    CandidateCertificateCompactDtoApiResponse,

    /**
     * The CandidateCertificateCreateDto model constructor.
     * @property {module:model/CandidateCertificateCreateDto}
     */
    CandidateCertificateCreateDto,

    /**
     * The CandidateCertificateDto model constructor.
     * @property {module:model/CandidateCertificateDto}
     */
    CandidateCertificateDto,

    /**
     * The CandidateCertificateDtoApiResponse model constructor.
     * @property {module:model/CandidateCertificateDtoApiResponse}
     */
    CandidateCertificateDtoApiResponse,

    /**
     * The CandidateCertificateDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CandidateCertificateDtoIEnumerableApiResponse}
     */
    CandidateCertificateDtoIEnumerableApiResponse,

    /**
     * The CandidateCertificateEditDto model constructor.
     * @property {module:model/CandidateCertificateEditDto}
     */
    CandidateCertificateEditDto,

    /**
     * The CandidateCertificateFilterDto model constructor.
     * @property {module:model/CandidateCertificateFilterDto}
     */
    CandidateCertificateFilterDto,

    /**
     * The CandidateCertificateFilterDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CandidateCertificateFilterDtoIEnumerableApiResponse}
     */
    CandidateCertificateFilterDtoIEnumerableApiResponse,

    /**
     * The CandidateCertificateInfoDto model constructor.
     * @property {module:model/CandidateCertificateInfoDto}
     */
    CandidateCertificateInfoDto,

    /**
     * The CandidateCertificateInfoDtoApiResponse model constructor.
     * @property {module:model/CandidateCertificateInfoDtoApiResponse}
     */
    CandidateCertificateInfoDtoApiResponse,

    /**
     * The CandidateCollegeImageDto model constructor.
     * @property {module:model/CandidateCollegeImageDto}
     */
    CandidateCollegeImageDto,

    /**
     * The CandidateCollegeImageDtoApiResponse model constructor.
     * @property {module:model/CandidateCollegeImageDtoApiResponse}
     */
    CandidateCollegeImageDtoApiResponse,

    /**
     * The CandidateCompactDto model constructor.
     * @property {module:model/CandidateCompactDto}
     */
    CandidateCompactDto,

    /**
     * The CandidateCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CandidateCompactDtoIEnumerableApiResponse}
     */
    CandidateCompactDtoIEnumerableApiResponse,

    /**
     * The CandidateCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/CandidateCompactDtoPagedListApiResponse}
     */
    CandidateCompactDtoPagedListApiResponse,

    /**
     * The CandidateCompactSkillPlatformDto model constructor.
     * @property {module:model/CandidateCompactSkillPlatformDto}
     */
    CandidateCompactSkillPlatformDto,

    /**
     * The CandidateCompactSkillPlatformDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CandidateCompactSkillPlatformDtoIEnumerableApiResponse}
     */
    CandidateCompactSkillPlatformDtoIEnumerableApiResponse,

    /**
     * The CandidateConfigurationCompactDto model constructor.
     * @property {module:model/CandidateConfigurationCompactDto}
     */
    CandidateConfigurationCompactDto,

    /**
     * The CandidateConfigurationCompactDtoApiResponse model constructor.
     * @property {module:model/CandidateConfigurationCompactDtoApiResponse}
     */
    CandidateConfigurationCompactDtoApiResponse,

    /**
     * The CandidateConfigurationCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CandidateConfigurationCompactDtoIEnumerableApiResponse}
     */
    CandidateConfigurationCompactDtoIEnumerableApiResponse,

    /**
     * The CandidateConfigurationCreateDto model constructor.
     * @property {module:model/CandidateConfigurationCreateDto}
     */
    CandidateConfigurationCreateDto,

    /**
     * The CandidateConfigurationDto model constructor.
     * @property {module:model/CandidateConfigurationDto}
     */
    CandidateConfigurationDto,

    /**
     * The CandidateConfigurationDtoApiResponse model constructor.
     * @property {module:model/CandidateConfigurationDtoApiResponse}
     */
    CandidateConfigurationDtoApiResponse,

    /**
     * The CandidateConfigurationEditDto model constructor.
     * @property {module:model/CandidateConfigurationEditDto}
     */
    CandidateConfigurationEditDto,

    /**
     * The CandidateContactDto model constructor.
     * @property {module:model/CandidateContactDto}
     */
    CandidateContactDto,

    /**
     * The CandidateContactDtoApiResponse model constructor.
     * @property {module:model/CandidateContactDtoApiResponse}
     */
    CandidateContactDtoApiResponse,

    /**
     * The CandidateDetailsDto model constructor.
     * @property {module:model/CandidateDetailsDto}
     */
    CandidateDetailsDto,

    /**
     * The CandidateDetailsDtoApiResponse model constructor.
     * @property {module:model/CandidateDetailsDtoApiResponse}
     */
    CandidateDetailsDtoApiResponse,

    /**
     * The CandidateDetailsEditDto model constructor.
     * @property {module:model/CandidateDetailsEditDto}
     */
    CandidateDetailsEditDto,

    /**
     * The CandidateDto model constructor.
     * @property {module:model/CandidateDto}
     */
    CandidateDto,

    /**
     * The CandidateDtoApiResponse model constructor.
     * @property {module:model/CandidateDtoApiResponse}
     */
    CandidateDtoApiResponse,

    /**
     * The CandidateDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CandidateDtoIEnumerableApiResponse}
     */
    CandidateDtoIEnumerableApiResponse,

    /**
     * The CandidateDtoPagedListApiResponse model constructor.
     * @property {module:model/CandidateDtoPagedListApiResponse}
     */
    CandidateDtoPagedListApiResponse,

    /**
     * The CandidateEmailDto model constructor.
     * @property {module:model/CandidateEmailDto}
     */
    CandidateEmailDto,

    /**
     * The CandidateEmployerCompactDto model constructor.
     * @property {module:model/CandidateEmployerCompactDto}
     */
    CandidateEmployerCompactDto,

    /**
     * The CandidateEmployerCompactDtoApiResponse model constructor.
     * @property {module:model/CandidateEmployerCompactDtoApiResponse}
     */
    CandidateEmployerCompactDtoApiResponse,

    /**
     * The CandidateEmployerCreateDto model constructor.
     * @property {module:model/CandidateEmployerCreateDto}
     */
    CandidateEmployerCreateDto,

    /**
     * The CandidateEmployerDto model constructor.
     * @property {module:model/CandidateEmployerDto}
     */
    CandidateEmployerDto,

    /**
     * The CandidateEmployerDtoApiResponse model constructor.
     * @property {module:model/CandidateEmployerDtoApiResponse}
     */
    CandidateEmployerDtoApiResponse,

    /**
     * The CandidateEmployerDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CandidateEmployerDtoIEnumerableApiResponse}
     */
    CandidateEmployerDtoIEnumerableApiResponse,

    /**
     * The CandidateEmployerEditDto model constructor.
     * @property {module:model/CandidateEmployerEditDto}
     */
    CandidateEmployerEditDto,

    /**
     * The CandidateEmployerProjectDto model constructor.
     * @property {module:model/CandidateEmployerProjectDto}
     */
    CandidateEmployerProjectDto,

    /**
     * The CandidateEmployerProjectDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CandidateEmployerProjectDtoIEnumerableApiResponse}
     */
    CandidateEmployerProjectDtoIEnumerableApiResponse,

    /**
     * The CandidateHeadLineDto model constructor.
     * @property {module:model/CandidateHeadLineDto}
     */
    CandidateHeadLineDto,

    /**
     * The CandidateHeadLineDtoApiResponse model constructor.
     * @property {module:model/CandidateHeadLineDtoApiResponse}
     */
    CandidateHeadLineDtoApiResponse,

    /**
     * The CandidateManualSearchDto model constructor.
     * @property {module:model/CandidateManualSearchDto}
     */
    CandidateManualSearchDto,

    /**
     * The CandidateNonTechnicalSkillCreateDto model constructor.
     * @property {module:model/CandidateNonTechnicalSkillCreateDto}
     */
    CandidateNonTechnicalSkillCreateDto,

    /**
     * The CandidateOtherPersonalInfoDto model constructor.
     * @property {module:model/CandidateOtherPersonalInfoDto}
     */
    CandidateOtherPersonalInfoDto,

    /**
     * The CandidateOtherPersonalInfoDtoApiResponse model constructor.
     * @property {module:model/CandidateOtherPersonalInfoDtoApiResponse}
     */
    CandidateOtherPersonalInfoDtoApiResponse,

    /**
     * The CandidateOtherPersonalInfoEditDto model constructor.
     * @property {module:model/CandidateOtherPersonalInfoEditDto}
     */
    CandidateOtherPersonalInfoEditDto,

    /**
     * The CandidatePersonalInfoDto model constructor.
     * @property {module:model/CandidatePersonalInfoDto}
     */
    CandidatePersonalInfoDto,

    /**
     * The CandidatePersonalInfoDtoApiResponse model constructor.
     * @property {module:model/CandidatePersonalInfoDtoApiResponse}
     */
    CandidatePersonalInfoDtoApiResponse,

    /**
     * The CandidatePersonalInfoEditDto model constructor.
     * @property {module:model/CandidatePersonalInfoEditDto}
     */
    CandidatePersonalInfoEditDto,

    /**
     * The CandidatePhoneDto model constructor.
     * @property {module:model/CandidatePhoneDto}
     */
    CandidatePhoneDto,

    /**
     * The CandidateProfileDto model constructor.
     * @property {module:model/CandidateProfileDto}
     */
    CandidateProfileDto,

    /**
     * The CandidateProfileDtoApiResponse model constructor.
     * @property {module:model/CandidateProfileDtoApiResponse}
     */
    CandidateProfileDtoApiResponse,

    /**
     * The CandidateProfileImageDto model constructor.
     * @property {module:model/CandidateProfileImageDto}
     */
    CandidateProfileImageDto,

    /**
     * The CandidateProfileImageDtoApiResponse model constructor.
     * @property {module:model/CandidateProfileImageDtoApiResponse}
     */
    CandidateProfileImageDtoApiResponse,

    /**
     * The CandidateProfileViewDto model constructor.
     * @property {module:model/CandidateProfileViewDto}
     */
    CandidateProfileViewDto,

    /**
     * The CandidateProfileViewDtoApiResponse model constructor.
     * @property {module:model/CandidateProfileViewDtoApiResponse}
     */
    CandidateProfileViewDtoApiResponse,

    /**
     * The CandidateProjectsCompactDto model constructor.
     * @property {module:model/CandidateProjectsCompactDto}
     */
    CandidateProjectsCompactDto,

    /**
     * The CandidateProjectsCompactDtoApiResponse model constructor.
     * @property {module:model/CandidateProjectsCompactDtoApiResponse}
     */
    CandidateProjectsCompactDtoApiResponse,

    /**
     * The CandidateProjectsCreateDto model constructor.
     * @property {module:model/CandidateProjectsCreateDto}
     */
    CandidateProjectsCreateDto,

    /**
     * The CandidateProjectsDto model constructor.
     * @property {module:model/CandidateProjectsDto}
     */
    CandidateProjectsDto,

    /**
     * The CandidateProjectsDtoApiResponse model constructor.
     * @property {module:model/CandidateProjectsDtoApiResponse}
     */
    CandidateProjectsDtoApiResponse,

    /**
     * The CandidateProjectsDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CandidateProjectsDtoIEnumerableApiResponse}
     */
    CandidateProjectsDtoIEnumerableApiResponse,

    /**
     * The CandidateProjectsEditDto model constructor.
     * @property {module:model/CandidateProjectsEditDto}
     */
    CandidateProjectsEditDto,

    /**
     * The CandidateQualificationCompactDto model constructor.
     * @property {module:model/CandidateQualificationCompactDto}
     */
    CandidateQualificationCompactDto,

    /**
     * The CandidateQualificationCompactDtoApiResponse model constructor.
     * @property {module:model/CandidateQualificationCompactDtoApiResponse}
     */
    CandidateQualificationCompactDtoApiResponse,

    /**
     * The CandidateQualificationCreateDto model constructor.
     * @property {module:model/CandidateQualificationCreateDto}
     */
    CandidateQualificationCreateDto,

    /**
     * The CandidateQualificationDto model constructor.
     * @property {module:model/CandidateQualificationDto}
     */
    CandidateQualificationDto,

    /**
     * The CandidateQualificationDtoApiResponse model constructor.
     * @property {module:model/CandidateQualificationDtoApiResponse}
     */
    CandidateQualificationDtoApiResponse,

    /**
     * The CandidateQualificationDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CandidateQualificationDtoIEnumerableApiResponse}
     */
    CandidateQualificationDtoIEnumerableApiResponse,

    /**
     * The CandidateQualificationEditDto model constructor.
     * @property {module:model/CandidateQualificationEditDto}
     */
    CandidateQualificationEditDto,

    /**
     * The CandidateRegisterDto model constructor.
     * @property {module:model/CandidateRegisterDto}
     */
    CandidateRegisterDto,

    /**
     * The CandidateResumeDto model constructor.
     * @property {module:model/CandidateResumeDto}
     */
    CandidateResumeDto,

    /**
     * The CandidateResumeDtoApiResponse model constructor.
     * @property {module:model/CandidateResumeDtoApiResponse}
     */
    CandidateResumeDtoApiResponse,

    /**
     * The CandidateSkillCompactDto model constructor.
     * @property {module:model/CandidateSkillCompactDto}
     */
    CandidateSkillCompactDto,

    /**
     * The CandidateSkillCompactDtoApiResponse model constructor.
     * @property {module:model/CandidateSkillCompactDtoApiResponse}
     */
    CandidateSkillCompactDtoApiResponse,

    /**
     * The CandidateSkillCreateDto model constructor.
     * @property {module:model/CandidateSkillCreateDto}
     */
    CandidateSkillCreateDto,

    /**
     * The CandidateSkillDto model constructor.
     * @property {module:model/CandidateSkillDto}
     */
    CandidateSkillDto,

    /**
     * The CandidateSkillDtoApiResponse model constructor.
     * @property {module:model/CandidateSkillDtoApiResponse}
     */
    CandidateSkillDtoApiResponse,

    /**
     * The CandidateSkillDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CandidateSkillDtoIEnumerableApiResponse}
     */
    CandidateSkillDtoIEnumerableApiResponse,

    /**
     * The CandidateSkillEditDto model constructor.
     * @property {module:model/CandidateSkillEditDto}
     */
    CandidateSkillEditDto,

    /**
     * The CandidateSkillPlatformCompactDto model constructor.
     * @property {module:model/CandidateSkillPlatformCompactDto}
     */
    CandidateSkillPlatformCompactDto,

    /**
     * The CandidateSkillPlatformCompactDtoApiResponse model constructor.
     * @property {module:model/CandidateSkillPlatformCompactDtoApiResponse}
     */
    CandidateSkillPlatformCompactDtoApiResponse,

    /**
     * The CandidateSkillPlatformCreateDto model constructor.
     * @property {module:model/CandidateSkillPlatformCreateDto}
     */
    CandidateSkillPlatformCreateDto,

    /**
     * The CandidateSkillPlatformDto model constructor.
     * @property {module:model/CandidateSkillPlatformDto}
     */
    CandidateSkillPlatformDto,

    /**
     * The CandidateSkillPlatformDtoApiResponse model constructor.
     * @property {module:model/CandidateSkillPlatformDtoApiResponse}
     */
    CandidateSkillPlatformDtoApiResponse,

    /**
     * The CandidateSkillPlatformEditDto model constructor.
     * @property {module:model/CandidateSkillPlatformEditDto}
     */
    CandidateSkillPlatformEditDto,

    /**
     * The CandidateSkillsDto model constructor.
     * @property {module:model/CandidateSkillsDto}
     */
    CandidateSkillsDto,

    /**
     * The CandidateSkillsDtoApiResponse model constructor.
     * @property {module:model/CandidateSkillsDtoApiResponse}
     */
    CandidateSkillsDtoApiResponse,

    /**
     * The CandidateSkillsEditDto model constructor.
     * @property {module:model/CandidateSkillsEditDto}
     */
    CandidateSkillsEditDto,

    /**
     * The CandidateSourcingSequenceDto model constructor.
     * @property {module:model/CandidateSourcingSequenceDto}
     */
    CandidateSourcingSequenceDto,

    /**
     * The CandidateTraitDto model constructor.
     * @property {module:model/CandidateTraitDto}
     */
    CandidateTraitDto,

    /**
     * The CandidateViewDto model constructor.
     * @property {module:model/CandidateViewDto}
     */
    CandidateViewDto,

    /**
     * The CandidateViewDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CandidateViewDtoIEnumerableApiResponse}
     */
    CandidateViewDtoIEnumerableApiResponse,

    /**
     * The CandidateWebsiteCompactDto model constructor.
     * @property {module:model/CandidateWebsiteCompactDto}
     */
    CandidateWebsiteCompactDto,

    /**
     * The CandidateWebsiteCompactDtoApiResponse model constructor.
     * @property {module:model/CandidateWebsiteCompactDtoApiResponse}
     */
    CandidateWebsiteCompactDtoApiResponse,

    /**
     * The CandidateWebsiteCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CandidateWebsiteCompactDtoIEnumerableApiResponse}
     */
    CandidateWebsiteCompactDtoIEnumerableApiResponse,

    /**
     * The CandidateWebsiteCreateDto model constructor.
     * @property {module:model/CandidateWebsiteCreateDto}
     */
    CandidateWebsiteCreateDto,

    /**
     * The CandidateWebsiteDto model constructor.
     * @property {module:model/CandidateWebsiteDto}
     */
    CandidateWebsiteDto,

    /**
     * The CandidateWebsiteDtoApiResponse model constructor.
     * @property {module:model/CandidateWebsiteDtoApiResponse}
     */
    CandidateWebsiteDtoApiResponse,

    /**
     * The CandidateWebsiteEditDto model constructor.
     * @property {module:model/CandidateWebsiteEditDto}
     */
    CandidateWebsiteEditDto,

    /**
     * The CertificateAliasCompactDto model constructor.
     * @property {module:model/CertificateAliasCompactDto}
     */
    CertificateAliasCompactDto,

    /**
     * The CertificateAliasCompactDtoApiResponse model constructor.
     * @property {module:model/CertificateAliasCompactDtoApiResponse}
     */
    CertificateAliasCompactDtoApiResponse,

    /**
     * The CertificateAliasCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CertificateAliasCompactDtoIEnumerableApiResponse}
     */
    CertificateAliasCompactDtoIEnumerableApiResponse,

    /**
     * The CertificateAliasCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/CertificateAliasCompactDtoPagedListApiResponse}
     */
    CertificateAliasCompactDtoPagedListApiResponse,

    /**
     * The CertificateAliasCreateDto model constructor.
     * @property {module:model/CertificateAliasCreateDto}
     */
    CertificateAliasCreateDto,

    /**
     * The CertificateAliasDto model constructor.
     * @property {module:model/CertificateAliasDto}
     */
    CertificateAliasDto,

    /**
     * The CertificateAliasDtoApiResponse model constructor.
     * @property {module:model/CertificateAliasDtoApiResponse}
     */
    CertificateAliasDtoApiResponse,

    /**
     * The CertificateAliasEditDto model constructor.
     * @property {module:model/CertificateAliasEditDto}
     */
    CertificateAliasEditDto,

    /**
     * The CertificateCompactDto model constructor.
     * @property {module:model/CertificateCompactDto}
     */
    CertificateCompactDto,

    /**
     * The CertificateCompactDtoApiResponse model constructor.
     * @property {module:model/CertificateCompactDtoApiResponse}
     */
    CertificateCompactDtoApiResponse,

    /**
     * The CertificateCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CertificateCompactDtoIEnumerableApiResponse}
     */
    CertificateCompactDtoIEnumerableApiResponse,

    /**
     * The CertificateCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/CertificateCompactDtoPagedListApiResponse}
     */
    CertificateCompactDtoPagedListApiResponse,

    /**
     * The CertificateCreateDto model constructor.
     * @property {module:model/CertificateCreateDto}
     */
    CertificateCreateDto,

    /**
     * The CertificateDto model constructor.
     * @property {module:model/CertificateDto}
     */
    CertificateDto,

    /**
     * The CertificateDtoApiResponse model constructor.
     * @property {module:model/CertificateDtoApiResponse}
     */
    CertificateDtoApiResponse,

    /**
     * The CertificateEditDto model constructor.
     * @property {module:model/CertificateEditDto}
     */
    CertificateEditDto,

    /**
     * The CertificateSearchDto model constructor.
     * @property {module:model/CertificateSearchDto}
     */
    CertificateSearchDto,

    /**
     * The CertificateSearchDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CertificateSearchDtoIEnumerableApiResponse}
     */
    CertificateSearchDtoIEnumerableApiResponse,

    /**
     * The Certification model constructor.
     * @property {module:model/Certification}
     */
    Certification,

    /**
     * The ChatGPTMapDto model constructor.
     * @property {module:model/ChatGPTMapDto}
     */
    ChatGPTMapDto,

    /**
     * The ChatGPTMapDtoApiResponse model constructor.
     * @property {module:model/ChatGPTMapDtoApiResponse}
     */
    ChatGPTMapDtoApiResponse,

    /**
     * The CityCompactDto model constructor.
     * @property {module:model/CityCompactDto}
     */
    CityCompactDto,

    /**
     * The CityCompactDtoApiResponse model constructor.
     * @property {module:model/CityCompactDtoApiResponse}
     */
    CityCompactDtoApiResponse,

    /**
     * The CityCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CityCompactDtoIEnumerableApiResponse}
     */
    CityCompactDtoIEnumerableApiResponse,

    /**
     * The CityCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/CityCompactDtoPagedListApiResponse}
     */
    CityCompactDtoPagedListApiResponse,

    /**
     * The CityCreateDto model constructor.
     * @property {module:model/CityCreateDto}
     */
    CityCreateDto,

    /**
     * The CityDto model constructor.
     * @property {module:model/CityDto}
     */
    CityDto,

    /**
     * The CityDtoApiResponse model constructor.
     * @property {module:model/CityDtoApiResponse}
     */
    CityDtoApiResponse,

    /**
     * The CityEditDto model constructor.
     * @property {module:model/CityEditDto}
     */
    CityEditDto,

    /**
     * The CityLocationDto model constructor.
     * @property {module:model/CityLocationDto}
     */
    CityLocationDto,

    /**
     * The CityLocationDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CityLocationDtoIEnumerableApiResponse}
     */
    CityLocationDtoIEnumerableApiResponse,

    /**
     * The ClientAndAddressCompactDto model constructor.
     * @property {module:model/ClientAndAddressCompactDto}
     */
    ClientAndAddressCompactDto,

    /**
     * The ClientAndAddressCreateDto model constructor.
     * @property {module:model/ClientAndAddressCreateDto}
     */
    ClientAndAddressCreateDto,

    /**
     * The ClientAndAddressDto model constructor.
     * @property {module:model/ClientAndAddressDto}
     */
    ClientAndAddressDto,

    /**
     * The ClientAndAddressEditDto model constructor.
     * @property {module:model/ClientAndAddressEditDto}
     */
    ClientAndAddressEditDto,

    /**
     * The ClientCompactDto model constructor.
     * @property {module:model/ClientCompactDto}
     */
    ClientCompactDto,

    /**
     * The ClientCompactDtoApiResponse model constructor.
     * @property {module:model/ClientCompactDtoApiResponse}
     */
    ClientCompactDtoApiResponse,

    /**
     * The ClientCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/ClientCompactDtoIEnumerableApiResponse}
     */
    ClientCompactDtoIEnumerableApiResponse,

    /**
     * The ClientCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/ClientCompactDtoPagedListApiResponse}
     */
    ClientCompactDtoPagedListApiResponse,

    /**
     * The ClientContractCompactDto model constructor.
     * @property {module:model/ClientContractCompactDto}
     */
    ClientContractCompactDto,

    /**
     * The ClientContractCompactDtoApiResponse model constructor.
     * @property {module:model/ClientContractCompactDtoApiResponse}
     */
    ClientContractCompactDtoApiResponse,

    /**
     * The ClientContractCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/ClientContractCompactDtoIEnumerableApiResponse}
     */
    ClientContractCompactDtoIEnumerableApiResponse,

    /**
     * The ClientContractDto model constructor.
     * @property {module:model/ClientContractDto}
     */
    ClientContractDto,

    /**
     * The ClientContractDtoApiResponse model constructor.
     * @property {module:model/ClientContractDtoApiResponse}
     */
    ClientContractDtoApiResponse,

    /**
     * The ClientContractDtoIEnumerableApiResponse model constructor.
     * @property {module:model/ClientContractDtoIEnumerableApiResponse}
     */
    ClientContractDtoIEnumerableApiResponse,

    /**
     * The ClientCreateDto model constructor.
     * @property {module:model/ClientCreateDto}
     */
    ClientCreateDto,

    /**
     * The ClientDashboardDto model constructor.
     * @property {module:model/ClientDashboardDto}
     */
    ClientDashboardDto,

    /**
     * The ClientDashboardDtoApiResponse model constructor.
     * @property {module:model/ClientDashboardDtoApiResponse}
     */
    ClientDashboardDtoApiResponse,

    /**
     * The ClientDashboardDtoIEnumerableApiResponse model constructor.
     * @property {module:model/ClientDashboardDtoIEnumerableApiResponse}
     */
    ClientDashboardDtoIEnumerableApiResponse,

    /**
     * The ClientDto model constructor.
     * @property {module:model/ClientDto}
     */
    ClientDto,

    /**
     * The ClientDtoApiResponse model constructor.
     * @property {module:model/ClientDtoApiResponse}
     */
    ClientDtoApiResponse,

    /**
     * The ClientEditDto model constructor.
     * @property {module:model/ClientEditDto}
     */
    ClientEditDto,

    /**
     * The ClientFilterDto model constructor.
     * @property {module:model/ClientFilterDto}
     */
    ClientFilterDto,

    /**
     * The ClientFilterDtoIEnumerableApiResponse model constructor.
     * @property {module:model/ClientFilterDtoIEnumerableApiResponse}
     */
    ClientFilterDtoIEnumerableApiResponse,

    /**
     * The ClientInterviewRoundCompactDto model constructor.
     * @property {module:model/ClientInterviewRoundCompactDto}
     */
    ClientInterviewRoundCompactDto,

    /**
     * The ClientInterviewRoundCompactDtoApiResponse model constructor.
     * @property {module:model/ClientInterviewRoundCompactDtoApiResponse}
     */
    ClientInterviewRoundCompactDtoApiResponse,

    /**
     * The ClientInterviewRoundCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/ClientInterviewRoundCompactDtoIEnumerableApiResponse}
     */
    ClientInterviewRoundCompactDtoIEnumerableApiResponse,

    /**
     * The ClientInterviewRoundCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/ClientInterviewRoundCompactDtoPagedListApiResponse}
     */
    ClientInterviewRoundCompactDtoPagedListApiResponse,

    /**
     * The ClientInterviewRoundCreateDto model constructor.
     * @property {module:model/ClientInterviewRoundCreateDto}
     */
    ClientInterviewRoundCreateDto,

    /**
     * The ClientInterviewRoundDto model constructor.
     * @property {module:model/ClientInterviewRoundDto}
     */
    ClientInterviewRoundDto,

    /**
     * The ClientInterviewRoundDtoApiResponse model constructor.
     * @property {module:model/ClientInterviewRoundDtoApiResponse}
     */
    ClientInterviewRoundDtoApiResponse,

    /**
     * The ClientInterviewRoundDtoIEnumerableApiResponse model constructor.
     * @property {module:model/ClientInterviewRoundDtoIEnumerableApiResponse}
     */
    ClientInterviewRoundDtoIEnumerableApiResponse,

    /**
     * The ClientInterviewRoundEditDto model constructor.
     * @property {module:model/ClientInterviewRoundEditDto}
     */
    ClientInterviewRoundEditDto,

    /**
     * The ClientLogInDto model constructor.
     * @property {module:model/ClientLogInDto}
     */
    ClientLogInDto,

    /**
     * The ClientStatus model constructor.
     * @property {module:model/ClientStatus}
     */
    ClientStatus,

    /**
     * The CompanyAliasCompactDto model constructor.
     * @property {module:model/CompanyAliasCompactDto}
     */
    CompanyAliasCompactDto,

    /**
     * The CompanyAliasCompactDtoApiResponse model constructor.
     * @property {module:model/CompanyAliasCompactDtoApiResponse}
     */
    CompanyAliasCompactDtoApiResponse,

    /**
     * The CompanyAliasCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CompanyAliasCompactDtoIEnumerableApiResponse}
     */
    CompanyAliasCompactDtoIEnumerableApiResponse,

    /**
     * The CompanyAliasCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/CompanyAliasCompactDtoPagedListApiResponse}
     */
    CompanyAliasCompactDtoPagedListApiResponse,

    /**
     * The CompanyAliasCreateDto model constructor.
     * @property {module:model/CompanyAliasCreateDto}
     */
    CompanyAliasCreateDto,

    /**
     * The CompanyAliasDto model constructor.
     * @property {module:model/CompanyAliasDto}
     */
    CompanyAliasDto,

    /**
     * The CompanyAliasDtoApiResponse model constructor.
     * @property {module:model/CompanyAliasDtoApiResponse}
     */
    CompanyAliasDtoApiResponse,

    /**
     * The CompanyAliasEditDto model constructor.
     * @property {module:model/CompanyAliasEditDto}
     */
    CompanyAliasEditDto,

    /**
     * The CompanyCompactDto model constructor.
     * @property {module:model/CompanyCompactDto}
     */
    CompanyCompactDto,

    /**
     * The CompanyCompactDtoApiResponse model constructor.
     * @property {module:model/CompanyCompactDtoApiResponse}
     */
    CompanyCompactDtoApiResponse,

    /**
     * The CompanyCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CompanyCompactDtoIEnumerableApiResponse}
     */
    CompanyCompactDtoIEnumerableApiResponse,

    /**
     * The CompanyCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/CompanyCompactDtoPagedListApiResponse}
     */
    CompanyCompactDtoPagedListApiResponse,

    /**
     * The CompanyCreateDto model constructor.
     * @property {module:model/CompanyCreateDto}
     */
    CompanyCreateDto,

    /**
     * The CompanyDto model constructor.
     * @property {module:model/CompanyDto}
     */
    CompanyDto,

    /**
     * The CompanyDtoApiResponse model constructor.
     * @property {module:model/CompanyDtoApiResponse}
     */
    CompanyDtoApiResponse,

    /**
     * The CompanyDuration model constructor.
     * @property {module:model/CompanyDuration}
     */
    CompanyDuration,

    /**
     * The CompanyEditDto model constructor.
     * @property {module:model/CompanyEditDto}
     */
    CompanyEditDto,

    /**
     * The CompanyType model constructor.
     * @property {module:model/CompanyType}
     */
    CompanyType,

    /**
     * The ContactCompactDto model constructor.
     * @property {module:model/ContactCompactDto}
     */
    ContactCompactDto,

    /**
     * The ContactCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/ContactCompactDtoIEnumerableApiResponse}
     */
    ContactCompactDtoIEnumerableApiResponse,

    /**
     * The ContactCreateDto model constructor.
     * @property {module:model/ContactCreateDto}
     */
    ContactCreateDto,

    /**
     * The ContactDto model constructor.
     * @property {module:model/ContactDto}
     */
    ContactDto,

    /**
     * The ContactDtoApiResponse model constructor.
     * @property {module:model/ContactDtoApiResponse}
     */
    ContactDtoApiResponse,

    /**
     * The ContactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/ContactDtoIEnumerableApiResponse}
     */
    ContactDtoIEnumerableApiResponse,

    /**
     * The ContactEditDto model constructor.
     * @property {module:model/ContactEditDto}
     */
    ContactEditDto,

    /**
     * The ContactInfo model constructor.
     * @property {module:model/ContactInfo}
     */
    ContactInfo,

    /**
     * The ContactType model constructor.
     * @property {module:model/ContactType}
     */
    ContactType,

    /**
     * The ContentCompactDto model constructor.
     * @property {module:model/ContentCompactDto}
     */
    ContentCompactDto,

    /**
     * The ContentCompactDtoApiResponse model constructor.
     * @property {module:model/ContentCompactDtoApiResponse}
     */
    ContentCompactDtoApiResponse,

    /**
     * The ContentCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/ContentCompactDtoIEnumerableApiResponse}
     */
    ContentCompactDtoIEnumerableApiResponse,

    /**
     * The ContentCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/ContentCompactDtoPagedListApiResponse}
     */
    ContentCompactDtoPagedListApiResponse,

    /**
     * The ContentCreateDto model constructor.
     * @property {module:model/ContentCreateDto}
     */
    ContentCreateDto,

    /**
     * The ContentDto model constructor.
     * @property {module:model/ContentDto}
     */
    ContentDto,

    /**
     * The ContentDtoApiResponse model constructor.
     * @property {module:model/ContentDtoApiResponse}
     */
    ContentDtoApiResponse,

    /**
     * The ContentEditDto model constructor.
     * @property {module:model/ContentEditDto}
     */
    ContentEditDto,

    /**
     * The CountryCompactDto model constructor.
     * @property {module:model/CountryCompactDto}
     */
    CountryCompactDto,

    /**
     * The CountryCompactDtoApiResponse model constructor.
     * @property {module:model/CountryCompactDtoApiResponse}
     */
    CountryCompactDtoApiResponse,

    /**
     * The CountryCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CountryCompactDtoIEnumerableApiResponse}
     */
    CountryCompactDtoIEnumerableApiResponse,

    /**
     * The CountryCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/CountryCompactDtoPagedListApiResponse}
     */
    CountryCompactDtoPagedListApiResponse,

    /**
     * The CountryCreateDto model constructor.
     * @property {module:model/CountryCreateDto}
     */
    CountryCreateDto,

    /**
     * The CountryDto model constructor.
     * @property {module:model/CountryDto}
     */
    CountryDto,

    /**
     * The CountryDtoApiResponse model constructor.
     * @property {module:model/CountryDtoApiResponse}
     */
    CountryDtoApiResponse,

    /**
     * The CountryEditDto model constructor.
     * @property {module:model/CountryEditDto}
     */
    CountryEditDto,

    /**
     * The CountryFilterDto model constructor.
     * @property {module:model/CountryFilterDto}
     */
    CountryFilterDto,

    /**
     * The CountryFilterDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CountryFilterDtoIEnumerableApiResponse}
     */
    CountryFilterDtoIEnumerableApiResponse,

    /**
     * The CourseCompactDto model constructor.
     * @property {module:model/CourseCompactDto}
     */
    CourseCompactDto,

    /**
     * The CourseCompactDtoApiResponse model constructor.
     * @property {module:model/CourseCompactDtoApiResponse}
     */
    CourseCompactDtoApiResponse,

    /**
     * The CourseCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/CourseCompactDtoIEnumerableApiResponse}
     */
    CourseCompactDtoIEnumerableApiResponse,

    /**
     * The CourseCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/CourseCompactDtoPagedListApiResponse}
     */
    CourseCompactDtoPagedListApiResponse,

    /**
     * The CourseCreateDto model constructor.
     * @property {module:model/CourseCreateDto}
     */
    CourseCreateDto,

    /**
     * The CourseDto model constructor.
     * @property {module:model/CourseDto}
     */
    CourseDto,

    /**
     * The CourseDtoApiResponse model constructor.
     * @property {module:model/CourseDtoApiResponse}
     */
    CourseDtoApiResponse,

    /**
     * The CourseEditDto model constructor.
     * @property {module:model/CourseEditDto}
     */
    CourseEditDto,

    /**
     * The CustomCandidateStatusDto model constructor.
     * @property {module:model/CustomCandidateStatusDto}
     */
    CustomCandidateStatusDto,

    /**
     * The CustomCandidateStatusDtoApiResponse model constructor.
     * @property {module:model/CustomCandidateStatusDtoApiResponse}
     */
    CustomCandidateStatusDtoApiResponse,

    /**
     * The DegreeAliasCompactDto model constructor.
     * @property {module:model/DegreeAliasCompactDto}
     */
    DegreeAliasCompactDto,

    /**
     * The DegreeAliasCompactDtoApiResponse model constructor.
     * @property {module:model/DegreeAliasCompactDtoApiResponse}
     */
    DegreeAliasCompactDtoApiResponse,

    /**
     * The DegreeAliasCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/DegreeAliasCompactDtoIEnumerableApiResponse}
     */
    DegreeAliasCompactDtoIEnumerableApiResponse,

    /**
     * The DegreeAliasCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/DegreeAliasCompactDtoPagedListApiResponse}
     */
    DegreeAliasCompactDtoPagedListApiResponse,

    /**
     * The DegreeAliasCreateDto model constructor.
     * @property {module:model/DegreeAliasCreateDto}
     */
    DegreeAliasCreateDto,

    /**
     * The DegreeAliasDto model constructor.
     * @property {module:model/DegreeAliasDto}
     */
    DegreeAliasDto,

    /**
     * The DegreeAliasDtoApiResponse model constructor.
     * @property {module:model/DegreeAliasDtoApiResponse}
     */
    DegreeAliasDtoApiResponse,

    /**
     * The DegreeAliasEditDto model constructor.
     * @property {module:model/DegreeAliasEditDto}
     */
    DegreeAliasEditDto,

    /**
     * The DegreeCompactDto model constructor.
     * @property {module:model/DegreeCompactDto}
     */
    DegreeCompactDto,

    /**
     * The DegreeCompactDtoApiResponse model constructor.
     * @property {module:model/DegreeCompactDtoApiResponse}
     */
    DegreeCompactDtoApiResponse,

    /**
     * The DegreeCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/DegreeCompactDtoIEnumerableApiResponse}
     */
    DegreeCompactDtoIEnumerableApiResponse,

    /**
     * The DegreeCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/DegreeCompactDtoPagedListApiResponse}
     */
    DegreeCompactDtoPagedListApiResponse,

    /**
     * The DegreeCreateDto model constructor.
     * @property {module:model/DegreeCreateDto}
     */
    DegreeCreateDto,

    /**
     * The DegreeDto model constructor.
     * @property {module:model/DegreeDto}
     */
    DegreeDto,

    /**
     * The DegreeDtoApiResponse model constructor.
     * @property {module:model/DegreeDtoApiResponse}
     */
    DegreeDtoApiResponse,

    /**
     * The DegreeEditDto model constructor.
     * @property {module:model/DegreeEditDto}
     */
    DegreeEditDto,

    /**
     * The DepartmentDto model constructor.
     * @property {module:model/DepartmentDto}
     */
    DepartmentDto,

    /**
     * The Document model constructor.
     * @property {module:model/Document}
     */
    Document,

    /**
     * The DocumentCompactDto model constructor.
     * @property {module:model/DocumentCompactDto}
     */
    DocumentCompactDto,

    /**
     * The DocumentCompactDtoApiResponse model constructor.
     * @property {module:model/DocumentCompactDtoApiResponse}
     */
    DocumentCompactDtoApiResponse,

    /**
     * The DocumentCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/DocumentCompactDtoIEnumerableApiResponse}
     */
    DocumentCompactDtoIEnumerableApiResponse,

    /**
     * The DocumentCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/DocumentCompactDtoPagedListApiResponse}
     */
    DocumentCompactDtoPagedListApiResponse,

    /**
     * The DocumentDto model constructor.
     * @property {module:model/DocumentDto}
     */
    DocumentDto,

    /**
     * The DocumentDtoApiResponse model constructor.
     * @property {module:model/DocumentDtoApiResponse}
     */
    DocumentDtoApiResponse,

    /**
     * The DocumentUploadBody model constructor.
     * @property {module:model/DocumentUploadBody}
     */
    DocumentUploadBody,

    /**
     * The DummyCompactDto model constructor.
     * @property {module:model/DummyCompactDto}
     */
    DummyCompactDto,

    /**
     * The DummyCompactDtoApiResponse model constructor.
     * @property {module:model/DummyCompactDtoApiResponse}
     */
    DummyCompactDtoApiResponse,

    /**
     * The DummyCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/DummyCompactDtoIEnumerableApiResponse}
     */
    DummyCompactDtoIEnumerableApiResponse,

    /**
     * The DummyCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/DummyCompactDtoPagedListApiResponse}
     */
    DummyCompactDtoPagedListApiResponse,

    /**
     * The DummyCreateDto model constructor.
     * @property {module:model/DummyCreateDto}
     */
    DummyCreateDto,

    /**
     * The DummyDto model constructor.
     * @property {module:model/DummyDto}
     */
    DummyDto,

    /**
     * The DummyDtoApiResponse model constructor.
     * @property {module:model/DummyDtoApiResponse}
     */
    DummyDtoApiResponse,

    /**
     * The DummyEditDto model constructor.
     * @property {module:model/DummyEditDto}
     */
    DummyEditDto,

    /**
     * The Education model constructor.
     * @property {module:model/Education}
     */
    Education,

    /**
     * The EducationInstituteCompactDto model constructor.
     * @property {module:model/EducationInstituteCompactDto}
     */
    EducationInstituteCompactDto,

    /**
     * The EducationInstituteCompactDtoApiResponse model constructor.
     * @property {module:model/EducationInstituteCompactDtoApiResponse}
     */
    EducationInstituteCompactDtoApiResponse,

    /**
     * The EducationInstituteCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/EducationInstituteCompactDtoIEnumerableApiResponse}
     */
    EducationInstituteCompactDtoIEnumerableApiResponse,

    /**
     * The EducationInstituteCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/EducationInstituteCompactDtoPagedListApiResponse}
     */
    EducationInstituteCompactDtoPagedListApiResponse,

    /**
     * The EducationInstituteCreateDto model constructor.
     * @property {module:model/EducationInstituteCreateDto}
     */
    EducationInstituteCreateDto,

    /**
     * The EducationInstituteDto model constructor.
     * @property {module:model/EducationInstituteDto}
     */
    EducationInstituteDto,

    /**
     * The EducationInstituteDtoApiResponse model constructor.
     * @property {module:model/EducationInstituteDtoApiResponse}
     */
    EducationInstituteDtoApiResponse,

    /**
     * The EducationInstituteEditDto model constructor.
     * @property {module:model/EducationInstituteEditDto}
     */
    EducationInstituteEditDto,

    /**
     * The EmployerDto model constructor.
     * @property {module:model/EmployerDto}
     */
    EmployerDto,

    /**
     * The EmploymentStatusType model constructor.
     * @property {module:model/EmploymentStatusType}
     */
    EmploymentStatusType,

    /**
     * The EmploymentType model constructor.
     * @property {module:model/EmploymentType}
     */
    EmploymentType,

    /**
     * The FieldOfStudyCompactDto model constructor.
     * @property {module:model/FieldOfStudyCompactDto}
     */
    FieldOfStudyCompactDto,

    /**
     * The FieldOfStudyCompactDtoApiResponse model constructor.
     * @property {module:model/FieldOfStudyCompactDtoApiResponse}
     */
    FieldOfStudyCompactDtoApiResponse,

    /**
     * The FieldOfStudyCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/FieldOfStudyCompactDtoIEnumerableApiResponse}
     */
    FieldOfStudyCompactDtoIEnumerableApiResponse,

    /**
     * The FieldOfStudyCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/FieldOfStudyCompactDtoPagedListApiResponse}
     */
    FieldOfStudyCompactDtoPagedListApiResponse,

    /**
     * The FieldOfStudyCreateDto model constructor.
     * @property {module:model/FieldOfStudyCreateDto}
     */
    FieldOfStudyCreateDto,

    /**
     * The FieldOfStudyDto model constructor.
     * @property {module:model/FieldOfStudyDto}
     */
    FieldOfStudyDto,

    /**
     * The FieldOfStudyDtoApiResponse model constructor.
     * @property {module:model/FieldOfStudyDtoApiResponse}
     */
    FieldOfStudyDtoApiResponse,

    /**
     * The FieldOfStudyEditDto model constructor.
     * @property {module:model/FieldOfStudyEditDto}
     */
    FieldOfStudyEditDto,

    /**
     * The FileType model constructor.
     * @property {module:model/FileType}
     */
    FileType,

    /**
     * The FilesUploadBody model constructor.
     * @property {module:model/FilesUploadBody}
     */
    FilesUploadBody,

    /**
     * The FunctionalAreaCompactDto model constructor.
     * @property {module:model/FunctionalAreaCompactDto}
     */
    FunctionalAreaCompactDto,

    /**
     * The FunctionalAreaCompactDtoApiResponse model constructor.
     * @property {module:model/FunctionalAreaCompactDtoApiResponse}
     */
    FunctionalAreaCompactDtoApiResponse,

    /**
     * The FunctionalAreaCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/FunctionalAreaCompactDtoIEnumerableApiResponse}
     */
    FunctionalAreaCompactDtoIEnumerableApiResponse,

    /**
     * The FunctionalAreaCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/FunctionalAreaCompactDtoPagedListApiResponse}
     */
    FunctionalAreaCompactDtoPagedListApiResponse,

    /**
     * The FunctionalAreaCreateDto model constructor.
     * @property {module:model/FunctionalAreaCreateDto}
     */
    FunctionalAreaCreateDto,

    /**
     * The FunctionalAreaDto model constructor.
     * @property {module:model/FunctionalAreaDto}
     */
    FunctionalAreaDto,

    /**
     * The FunctionalAreaDtoApiResponse model constructor.
     * @property {module:model/FunctionalAreaDtoApiResponse}
     */
    FunctionalAreaDtoApiResponse,

    /**
     * The FunctionalAreaEditDto model constructor.
     * @property {module:model/FunctionalAreaEditDto}
     */
    FunctionalAreaEditDto,

    /**
     * The GenderType model constructor.
     * @property {module:model/GenderType}
     */
    GenderType,

    /**
     * The GlobalConfigrationCompactDto model constructor.
     * @property {module:model/GlobalConfigrationCompactDto}
     */
    GlobalConfigrationCompactDto,

    /**
     * The GlobalConfigrationCompactDtoApiResponse model constructor.
     * @property {module:model/GlobalConfigrationCompactDtoApiResponse}
     */
    GlobalConfigrationCompactDtoApiResponse,

    /**
     * The GlobalConfigrationCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/GlobalConfigrationCompactDtoIEnumerableApiResponse}
     */
    GlobalConfigrationCompactDtoIEnumerableApiResponse,

    /**
     * The GlobalConfigrationCreateDto model constructor.
     * @property {module:model/GlobalConfigrationCreateDto}
     */
    GlobalConfigrationCreateDto,

    /**
     * The GlobalConfigrationEditDto model constructor.
     * @property {module:model/GlobalConfigrationEditDto}
     */
    GlobalConfigrationEditDto,

    /**
     * The GlobalConfigurationDto model constructor.
     * @property {module:model/GlobalConfigurationDto}
     */
    GlobalConfigurationDto,

    /**
     * The GlobalConfigurationDtoApiResponse model constructor.
     * @property {module:model/GlobalConfigurationDtoApiResponse}
     */
    GlobalConfigurationDtoApiResponse,

    /**
     * The Hobby model constructor.
     * @property {module:model/Hobby}
     */
    Hobby,

    /**
     * The HotSkillCompactDto model constructor.
     * @property {module:model/HotSkillCompactDto}
     */
    HotSkillCompactDto,

    /**
     * The HotSkillCompactDtoApiResponse model constructor.
     * @property {module:model/HotSkillCompactDtoApiResponse}
     */
    HotSkillCompactDtoApiResponse,

    /**
     * The HotSkillCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/HotSkillCompactDtoIEnumerableApiResponse}
     */
    HotSkillCompactDtoIEnumerableApiResponse,

    /**
     * The HotSkillCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/HotSkillCompactDtoPagedListApiResponse}
     */
    HotSkillCompactDtoPagedListApiResponse,

    /**
     * The HotSkillCreateDto model constructor.
     * @property {module:model/HotSkillCreateDto}
     */
    HotSkillCreateDto,

    /**
     * The HotSkillDto model constructor.
     * @property {module:model/HotSkillDto}
     */
    HotSkillDto,

    /**
     * The HotSkillDtoApiResponse model constructor.
     * @property {module:model/HotSkillDtoApiResponse}
     */
    HotSkillDtoApiResponse,

    /**
     * The HotSkillEditDto model constructor.
     * @property {module:model/HotSkillEditDto}
     */
    HotSkillEditDto,

    /**
     * The IntegrationCompactDto model constructor.
     * @property {module:model/IntegrationCompactDto}
     */
    IntegrationCompactDto,

    /**
     * The IntegrationCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/IntegrationCompactDtoIEnumerableApiResponse}
     */
    IntegrationCompactDtoIEnumerableApiResponse,

    /**
     * The IntegrationDto model constructor.
     * @property {module:model/IntegrationDto}
     */
    IntegrationDto,

    /**
     * The InterviewStatus model constructor.
     * @property {module:model/InterviewStatus}
     */
    InterviewStatus,

    /**
     * The JDClientInterviewRoundPanelCreateDto model constructor.
     * @property {module:model/JDClientInterviewRoundPanelCreateDto}
     */
    JDClientInterviewRoundPanelCreateDto,

    /**
     * The JDClientInterviewRoundPanelDto model constructor.
     * @property {module:model/JDClientInterviewRoundPanelDto}
     */
    JDClientInterviewRoundPanelDto,

    /**
     * The JdAndAddressCompactDto model constructor.
     * @property {module:model/JdAndAddressCompactDto}
     */
    JdAndAddressCompactDto,

    /**
     * The JdAndAssessmentCompactDto model constructor.
     * @property {module:model/JdAndAssessmentCompactDto}
     */
    JdAndAssessmentCompactDto,

    /**
     * The JdAndAssessmentCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JdAndAssessmentCompactDtoIEnumerableApiResponse}
     */
    JdAndAssessmentCompactDtoIEnumerableApiResponse,

    /**
     * The JdAndAssessmentCreateDto model constructor.
     * @property {module:model/JdAndAssessmentCreateDto}
     */
    JdAndAssessmentCreateDto,

    /**
     * The JdAndAssessmentDto model constructor.
     * @property {module:model/JdAndAssessmentDto}
     */
    JdAndAssessmentDto,

    /**
     * The JdAndAssessmentDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JdAndAssessmentDtoIEnumerableApiResponse}
     */
    JdAndAssessmentDtoIEnumerableApiResponse,

    /**
     * The JdAndCandidateStatusDto model constructor.
     * @property {module:model/JdAndCandidateStatusDto}
     */
    JdAndCandidateStatusDto,

    /**
     * The JdAndCandidateStatusDtoApiResponse model constructor.
     * @property {module:model/JdAndCandidateStatusDtoApiResponse}
     */
    JdAndCandidateStatusDtoApiResponse,

    /**
     * The JdAndCandidateStatusDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JdAndCandidateStatusDtoIEnumerableApiResponse}
     */
    JdAndCandidateStatusDtoIEnumerableApiResponse,

    /**
     * The JdAndTaggedCandidateDto model constructor.
     * @property {module:model/JdAndTaggedCandidateDto}
     */
    JdAndTaggedCandidateDto,

    /**
     * The JdAndTaggedCandidateDtoApiResponse model constructor.
     * @property {module:model/JdAndTaggedCandidateDtoApiResponse}
     */
    JdAndTaggedCandidateDtoApiResponse,

    /**
     * The JdAndTaggedCandidateDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JdAndTaggedCandidateDtoIEnumerableApiResponse}
     */
    JdAndTaggedCandidateDtoIEnumerableApiResponse,

    /**
     * The JdAndTaggedCandidateStatusType model constructor.
     * @property {module:model/JdAndTaggedCandidateStatusType}
     */
    JdAndTaggedCandidateStatusType,

    /**
     * The JdClientInterviewCompactDto model constructor.
     * @property {module:model/JdClientInterviewCompactDto}
     */
    JdClientInterviewCompactDto,

    /**
     * The JdClientInterviewCompactDtoApiResponse model constructor.
     * @property {module:model/JdClientInterviewCompactDtoApiResponse}
     */
    JdClientInterviewCompactDtoApiResponse,

    /**
     * The JdClientInterviewCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JdClientInterviewCompactDtoIEnumerableApiResponse}
     */
    JdClientInterviewCompactDtoIEnumerableApiResponse,

    /**
     * The JdClientInterviewCreateDto model constructor.
     * @property {module:model/JdClientInterviewCreateDto}
     */
    JdClientInterviewCreateDto,

    /**
     * The JdClientInterviewDto model constructor.
     * @property {module:model/JdClientInterviewDto}
     */
    JdClientInterviewDto,

    /**
     * The JdClientInterviewDtoApiResponse model constructor.
     * @property {module:model/JdClientInterviewDtoApiResponse}
     */
    JdClientInterviewDtoApiResponse,

    /**
     * The JdClientInterviewRoundsCreateDto model constructor.
     * @property {module:model/JdClientInterviewRoundsCreateDto}
     */
    JdClientInterviewRoundsCreateDto,

    /**
     * The JdClientInterviewRoundsDto model constructor.
     * @property {module:model/JdClientInterviewRoundsDto}
     */
    JdClientInterviewRoundsDto,

    /**
     * The JdClientInterviewRoundsDtoApiResponse model constructor.
     * @property {module:model/JdClientInterviewRoundsDtoApiResponse}
     */
    JdClientInterviewRoundsDtoApiResponse,

    /**
     * The JdClientInterviewRoundsEditDto model constructor.
     * @property {module:model/JdClientInterviewRoundsEditDto}
     */
    JdClientInterviewRoundsEditDto,

    /**
     * The JdInterviewRoundsCompactDto model constructor.
     * @property {module:model/JdInterviewRoundsCompactDto}
     */
    JdInterviewRoundsCompactDto,

    /**
     * The JdInterviewRoundsCompactDtoApiResponse model constructor.
     * @property {module:model/JdInterviewRoundsCompactDtoApiResponse}
     */
    JdInterviewRoundsCompactDtoApiResponse,

    /**
     * The JdInterviewRoundsCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JdInterviewRoundsCompactDtoIEnumerableApiResponse}
     */
    JdInterviewRoundsCompactDtoIEnumerableApiResponse,

    /**
     * The JdInterviewRoundsCreateDto model constructor.
     * @property {module:model/JdInterviewRoundsCreateDto}
     */
    JdInterviewRoundsCreateDto,

    /**
     * The JdInterviewRoundsDto model constructor.
     * @property {module:model/JdInterviewRoundsDto}
     */
    JdInterviewRoundsDto,

    /**
     * The JdInterviewRoundsDtoApiResponse model constructor.
     * @property {module:model/JdInterviewRoundsDtoApiResponse}
     */
    JdInterviewRoundsDtoApiResponse,

    /**
     * The JdInterviewRoundsEditDto model constructor.
     * @property {module:model/JdInterviewRoundsEditDto}
     */
    JdInterviewRoundsEditDto,

    /**
     * The JdInterviewRoundsPanelCompactDto model constructor.
     * @property {module:model/JdInterviewRoundsPanelCompactDto}
     */
    JdInterviewRoundsPanelCompactDto,

    /**
     * The JdInterviewRoundsPanelCompactDtoApiResponse model constructor.
     * @property {module:model/JdInterviewRoundsPanelCompactDtoApiResponse}
     */
    JdInterviewRoundsPanelCompactDtoApiResponse,

    /**
     * The JdInterviewRoundsPanelCreateDto model constructor.
     * @property {module:model/JdInterviewRoundsPanelCreateDto}
     */
    JdInterviewRoundsPanelCreateDto,

    /**
     * The JdInterviewRoundsPanelDto model constructor.
     * @property {module:model/JdInterviewRoundsPanelDto}
     */
    JdInterviewRoundsPanelDto,

    /**
     * The JdInterviewRoundsPanelDtoApiResponse model constructor.
     * @property {module:model/JdInterviewRoundsPanelDtoApiResponse}
     */
    JdInterviewRoundsPanelDtoApiResponse,

    /**
     * The JdInterviewRoundsPanelDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JdInterviewRoundsPanelDtoIEnumerableApiResponse}
     */
    JdInterviewRoundsPanelDtoIEnumerableApiResponse,

    /**
     * The JdInterviewRoundsPanelEditDto model constructor.
     * @property {module:model/JdInterviewRoundsPanelEditDto}
     */
    JdInterviewRoundsPanelEditDto,

    /**
     * The JdSkillCompactDto model constructor.
     * @property {module:model/JdSkillCompactDto}
     */
    JdSkillCompactDto,

    /**
     * The JdSkillCompactDtoApiResponse model constructor.
     * @property {module:model/JdSkillCompactDtoApiResponse}
     */
    JdSkillCompactDtoApiResponse,

    /**
     * The JdSkillDto model constructor.
     * @property {module:model/JdSkillDto}
     */
    JdSkillDto,

    /**
     * The JdSkillDtoApiResponse model constructor.
     * @property {module:model/JdSkillDtoApiResponse}
     */
    JdSkillDtoApiResponse,

    /**
     * The JdSkillEditDto model constructor.
     * @property {module:model/JdSkillEditDto}
     */
    JdSkillEditDto,

    /**
     * The JdSkillPlatformCompactDto model constructor.
     * @property {module:model/JdSkillPlatformCompactDto}
     */
    JdSkillPlatformCompactDto,

    /**
     * The JdSkillPlatformCompactDtoApiResponse model constructor.
     * @property {module:model/JdSkillPlatformCompactDtoApiResponse}
     */
    JdSkillPlatformCompactDtoApiResponse,

    /**
     * The JdSkillPlatformCreateDto model constructor.
     * @property {module:model/JdSkillPlatformCreateDto}
     */
    JdSkillPlatformCreateDto,

    /**
     * The JdSkillPlatformDto model constructor.
     * @property {module:model/JdSkillPlatformDto}
     */
    JdSkillPlatformDto,

    /**
     * The JdSkillPlatformDtoApiResponse model constructor.
     * @property {module:model/JdSkillPlatformDtoApiResponse}
     */
    JdSkillPlatformDtoApiResponse,

    /**
     * The JdSkillPlatformEditDto model constructor.
     * @property {module:model/JdSkillPlatformEditDto}
     */
    JdSkillPlatformEditDto,

    /**
     * The JdSkillType model constructor.
     * @property {module:model/JdSkillType}
     */
    JdSkillType,

    /**
     * The JdSoftSkillDto model constructor.
     * @property {module:model/JdSoftSkillDto}
     */
    JdSoftSkillDto,

    /**
     * The JdSoftSkillDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JdSoftSkillDtoIEnumerableApiResponse}
     */
    JdSoftSkillDtoIEnumerableApiResponse,

    /**
     * The JdSoftSkillEditDto model constructor.
     * @property {module:model/JdSoftSkillEditDto}
     */
    JdSoftSkillEditDto,

    /**
     * The JdTraitCompactDto model constructor.
     * @property {module:model/JdTraitCompactDto}
     */
    JdTraitCompactDto,

    /**
     * The JdTraitCompactDtoApiResponse model constructor.
     * @property {module:model/JdTraitCompactDtoApiResponse}
     */
    JdTraitCompactDtoApiResponse,

    /**
     * The JdTraitCreateDto model constructor.
     * @property {module:model/JdTraitCreateDto}
     */
    JdTraitCreateDto,

    /**
     * The JdTraitDto model constructor.
     * @property {module:model/JdTraitDto}
     */
    JdTraitDto,

    /**
     * The JdTraitDtoApiResponse model constructor.
     * @property {module:model/JdTraitDtoApiResponse}
     */
    JdTraitDtoApiResponse,

    /**
     * The JdTraitEditDto model constructor.
     * @property {module:model/JdTraitEditDto}
     */
    JdTraitEditDto,

    /**
     * The JobDescriptionCompactDto model constructor.
     * @property {module:model/JobDescriptionCompactDto}
     */
    JobDescriptionCompactDto,

    /**
     * The JobDescriptionCompactDtoApiResponse model constructor.
     * @property {module:model/JobDescriptionCompactDtoApiResponse}
     */
    JobDescriptionCompactDtoApiResponse,

    /**
     * The JobDescriptionCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JobDescriptionCompactDtoIEnumerableApiResponse}
     */
    JobDescriptionCompactDtoIEnumerableApiResponse,

    /**
     * The JobDescriptionCreateDto model constructor.
     * @property {module:model/JobDescriptionCreateDto}
     */
    JobDescriptionCreateDto,

    /**
     * The JobDescriptionDto model constructor.
     * @property {module:model/JobDescriptionDto}
     */
    JobDescriptionDto,

    /**
     * The JobDescriptionDtoApiResponse model constructor.
     * @property {module:model/JobDescriptionDtoApiResponse}
     */
    JobDescriptionDtoApiResponse,

    /**
     * The JobDescriptionDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JobDescriptionDtoIEnumerableApiResponse}
     */
    JobDescriptionDtoIEnumerableApiResponse,

    /**
     * The JobDescriptionEditDto model constructor.
     * @property {module:model/JobDescriptionEditDto}
     */
    JobDescriptionEditDto,

    /**
     * The JobDescriptionFilterDto model constructor.
     * @property {module:model/JobDescriptionFilterDto}
     */
    JobDescriptionFilterDto,

    /**
     * The JobDescriptionFilterDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JobDescriptionFilterDtoIEnumerableApiResponse}
     */
    JobDescriptionFilterDtoIEnumerableApiResponse,

    /**
     * The JobDescriptionSearchDto model constructor.
     * @property {module:model/JobDescriptionSearchDto}
     */
    JobDescriptionSearchDto,

    /**
     * The JobDescriptionSearchDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JobDescriptionSearchDtoIEnumerableApiResponse}
     */
    JobDescriptionSearchDtoIEnumerableApiResponse,

    /**
     * The JobDescriptionSkillDto model constructor.
     * @property {module:model/JobDescriptionSkillDto}
     */
    JobDescriptionSkillDto,

    /**
     * The JobDescriptionSkillDtoApiResponse model constructor.
     * @property {module:model/JobDescriptionSkillDtoApiResponse}
     */
    JobDescriptionSkillDtoApiResponse,

    /**
     * The JobDescriptionStatusDto model constructor.
     * @property {module:model/JobDescriptionStatusDto}
     */
    JobDescriptionStatusDto,

    /**
     * The JobProfileType model constructor.
     * @property {module:model/JobProfileType}
     */
    JobProfileType,

    /**
     * The JobTitleAliasCompactDto model constructor.
     * @property {module:model/JobTitleAliasCompactDto}
     */
    JobTitleAliasCompactDto,

    /**
     * The JobTitleAliasCompactDtoApiResponse model constructor.
     * @property {module:model/JobTitleAliasCompactDtoApiResponse}
     */
    JobTitleAliasCompactDtoApiResponse,

    /**
     * The JobTitleAliasCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JobTitleAliasCompactDtoIEnumerableApiResponse}
     */
    JobTitleAliasCompactDtoIEnumerableApiResponse,

    /**
     * The JobTitleAliasCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/JobTitleAliasCompactDtoPagedListApiResponse}
     */
    JobTitleAliasCompactDtoPagedListApiResponse,

    /**
     * The JobTitleAliasCreateDto model constructor.
     * @property {module:model/JobTitleAliasCreateDto}
     */
    JobTitleAliasCreateDto,

    /**
     * The JobTitleAliasDto model constructor.
     * @property {module:model/JobTitleAliasDto}
     */
    JobTitleAliasDto,

    /**
     * The JobTitleAliasDtoApiResponse model constructor.
     * @property {module:model/JobTitleAliasDtoApiResponse}
     */
    JobTitleAliasDtoApiResponse,

    /**
     * The JobTitleAliasEditDto model constructor.
     * @property {module:model/JobTitleAliasEditDto}
     */
    JobTitleAliasEditDto,

    /**
     * The JobTitleAndSkillCompactDto model constructor.
     * @property {module:model/JobTitleAndSkillCompactDto}
     */
    JobTitleAndSkillCompactDto,

    /**
     * The JobTitleAndSkillCompactDtoApiResponse model constructor.
     * @property {module:model/JobTitleAndSkillCompactDtoApiResponse}
     */
    JobTitleAndSkillCompactDtoApiResponse,

    /**
     * The JobTitleAndSkillCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JobTitleAndSkillCompactDtoIEnumerableApiResponse}
     */
    JobTitleAndSkillCompactDtoIEnumerableApiResponse,

    /**
     * The JobTitleAndSkillCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/JobTitleAndSkillCompactDtoPagedListApiResponse}
     */
    JobTitleAndSkillCompactDtoPagedListApiResponse,

    /**
     * The JobTitleAndSkillCreateDto model constructor.
     * @property {module:model/JobTitleAndSkillCreateDto}
     */
    JobTitleAndSkillCreateDto,

    /**
     * The JobTitleAndSkillDto model constructor.
     * @property {module:model/JobTitleAndSkillDto}
     */
    JobTitleAndSkillDto,

    /**
     * The JobTitleAndSkillDtoApiResponse model constructor.
     * @property {module:model/JobTitleAndSkillDtoApiResponse}
     */
    JobTitleAndSkillDtoApiResponse,

    /**
     * The JobTitleAndSkillEditDto model constructor.
     * @property {module:model/JobTitleAndSkillEditDto}
     */
    JobTitleAndSkillEditDto,

    /**
     * The JobTitleCompactDto model constructor.
     * @property {module:model/JobTitleCompactDto}
     */
    JobTitleCompactDto,

    /**
     * The JobTitleCompactDtoApiResponse model constructor.
     * @property {module:model/JobTitleCompactDtoApiResponse}
     */
    JobTitleCompactDtoApiResponse,

    /**
     * The JobTitleCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/JobTitleCompactDtoIEnumerableApiResponse}
     */
    JobTitleCompactDtoIEnumerableApiResponse,

    /**
     * The JobTitleCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/JobTitleCompactDtoPagedListApiResponse}
     */
    JobTitleCompactDtoPagedListApiResponse,

    /**
     * The JobTitleCreateDto model constructor.
     * @property {module:model/JobTitleCreateDto}
     */
    JobTitleCreateDto,

    /**
     * The JobTitleDto model constructor.
     * @property {module:model/JobTitleDto}
     */
    JobTitleDto,

    /**
     * The JobTitleDtoApiResponse model constructor.
     * @property {module:model/JobTitleDtoApiResponse}
     */
    JobTitleDtoApiResponse,

    /**
     * The JobTitleEditDto model constructor.
     * @property {module:model/JobTitleEditDto}
     */
    JobTitleEditDto,

    /**
     * The Language model constructor.
     * @property {module:model/Language}
     */
    Language,

    /**
     * The LanguageCompactDto model constructor.
     * @property {module:model/LanguageCompactDto}
     */
    LanguageCompactDto,

    /**
     * The LanguageCompactDtoApiResponse model constructor.
     * @property {module:model/LanguageCompactDtoApiResponse}
     */
    LanguageCompactDtoApiResponse,

    /**
     * The LanguageCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/LanguageCompactDtoIEnumerableApiResponse}
     */
    LanguageCompactDtoIEnumerableApiResponse,

    /**
     * The LanguageCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/LanguageCompactDtoPagedListApiResponse}
     */
    LanguageCompactDtoPagedListApiResponse,

    /**
     * The LanguageCreateDto model constructor.
     * @property {module:model/LanguageCreateDto}
     */
    LanguageCreateDto,

    /**
     * The LanguageDto model constructor.
     * @property {module:model/LanguageDto}
     */
    LanguageDto,

    /**
     * The LanguageDtoApiResponse model constructor.
     * @property {module:model/LanguageDtoApiResponse}
     */
    LanguageDtoApiResponse,

    /**
     * The LanguageEditDto model constructor.
     * @property {module:model/LanguageEditDto}
     */
    LanguageEditDto,

    /**
     * The LanguageFilterDto model constructor.
     * @property {module:model/LanguageFilterDto}
     */
    LanguageFilterDto,

    /**
     * The LanguageFilterDtoIEnumerableApiResponse model constructor.
     * @property {module:model/LanguageFilterDtoIEnumerableApiResponse}
     */
    LanguageFilterDtoIEnumerableApiResponse,

    /**
     * The LogInDto model constructor.
     * @property {module:model/LogInDto}
     */
    LogInDto,

    /**
     * The MPathwayAttributeCompactDto model constructor.
     * @property {module:model/MPathwayAttributeCompactDto}
     */
    MPathwayAttributeCompactDto,

    /**
     * The MPathwayAttributeCompactDtoApiResponse model constructor.
     * @property {module:model/MPathwayAttributeCompactDtoApiResponse}
     */
    MPathwayAttributeCompactDtoApiResponse,

    /**
     * The MPathwayAttributeCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MPathwayAttributeCompactDtoIEnumerableApiResponse}
     */
    MPathwayAttributeCompactDtoIEnumerableApiResponse,

    /**
     * The MPathwayAttributeCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/MPathwayAttributeCompactDtoPagedListApiResponse}
     */
    MPathwayAttributeCompactDtoPagedListApiResponse,

    /**
     * The MPathwayAttributeCreateDto model constructor.
     * @property {module:model/MPathwayAttributeCreateDto}
     */
    MPathwayAttributeCreateDto,

    /**
     * The MPathwayAttributeDto model constructor.
     * @property {module:model/MPathwayAttributeDto}
     */
    MPathwayAttributeDto,

    /**
     * The MPathwayAttributeDtoApiResponse model constructor.
     * @property {module:model/MPathwayAttributeDtoApiResponse}
     */
    MPathwayAttributeDtoApiResponse,

    /**
     * The MPathwayAttributeEditDto model constructor.
     * @property {module:model/MPathwayAttributeEditDto}
     */
    MPathwayAttributeEditDto,

    /**
     * The MPathwayOutcomeAttributeCompactDto model constructor.
     * @property {module:model/MPathwayOutcomeAttributeCompactDto}
     */
    MPathwayOutcomeAttributeCompactDto,

    /**
     * The MPathwayOutcomeAttributeCompactDtoApiResponse model constructor.
     * @property {module:model/MPathwayOutcomeAttributeCompactDtoApiResponse}
     */
    MPathwayOutcomeAttributeCompactDtoApiResponse,

    /**
     * The MPathwayOutcomeAttributeCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MPathwayOutcomeAttributeCompactDtoIEnumerableApiResponse}
     */
    MPathwayOutcomeAttributeCompactDtoIEnumerableApiResponse,

    /**
     * The MPathwayOutcomeAttributeCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/MPathwayOutcomeAttributeCompactDtoPagedListApiResponse}
     */
    MPathwayOutcomeAttributeCompactDtoPagedListApiResponse,

    /**
     * The MPathwayOutcomeAttributeCreateDto model constructor.
     * @property {module:model/MPathwayOutcomeAttributeCreateDto}
     */
    MPathwayOutcomeAttributeCreateDto,

    /**
     * The MPathwayOutcomeAttributeDto model constructor.
     * @property {module:model/MPathwayOutcomeAttributeDto}
     */
    MPathwayOutcomeAttributeDto,

    /**
     * The MPathwayOutcomeAttributeDtoApiResponse model constructor.
     * @property {module:model/MPathwayOutcomeAttributeDtoApiResponse}
     */
    MPathwayOutcomeAttributeDtoApiResponse,

    /**
     * The MPathwayOutcomeAttributeEditDto model constructor.
     * @property {module:model/MPathwayOutcomeAttributeEditDto}
     */
    MPathwayOutcomeAttributeEditDto,

    /**
     * The MPathwayOutcomeCompactDto model constructor.
     * @property {module:model/MPathwayOutcomeCompactDto}
     */
    MPathwayOutcomeCompactDto,

    /**
     * The MPathwayOutcomeCompactDtoApiResponse model constructor.
     * @property {module:model/MPathwayOutcomeCompactDtoApiResponse}
     */
    MPathwayOutcomeCompactDtoApiResponse,

    /**
     * The MPathwayOutcomeCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MPathwayOutcomeCompactDtoIEnumerableApiResponse}
     */
    MPathwayOutcomeCompactDtoIEnumerableApiResponse,

    /**
     * The MPathwayOutcomeCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/MPathwayOutcomeCompactDtoPagedListApiResponse}
     */
    MPathwayOutcomeCompactDtoPagedListApiResponse,

    /**
     * The MPathwayOutcomeCreateDto model constructor.
     * @property {module:model/MPathwayOutcomeCreateDto}
     */
    MPathwayOutcomeCreateDto,

    /**
     * The MPathwayOutcomeDto model constructor.
     * @property {module:model/MPathwayOutcomeDto}
     */
    MPathwayOutcomeDto,

    /**
     * The MPathwayOutcomeDtoApiResponse model constructor.
     * @property {module:model/MPathwayOutcomeDtoApiResponse}
     */
    MPathwayOutcomeDtoApiResponse,

    /**
     * The MPathwayOutcomeEditDto model constructor.
     * @property {module:model/MPathwayOutcomeEditDto}
     */
    MPathwayOutcomeEditDto,

    /**
     * The MPathwayOutcomeFilterDto model constructor.
     * @property {module:model/MPathwayOutcomeFilterDto}
     */
    MPathwayOutcomeFilterDto,

    /**
     * The MPathwayOutcomeFilterDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MPathwayOutcomeFilterDtoIEnumerableApiResponse}
     */
    MPathwayOutcomeFilterDtoIEnumerableApiResponse,

    /**
     * The MPathwayType model constructor.
     * @property {module:model/MPathwayType}
     */
    MPathwayType,

    /**
     * The MPathwayTypeCompactDto model constructor.
     * @property {module:model/MPathwayTypeCompactDto}
     */
    MPathwayTypeCompactDto,

    /**
     * The MPathwayTypeCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MPathwayTypeCompactDtoIEnumerableApiResponse}
     */
    MPathwayTypeCompactDtoIEnumerableApiResponse,

    /**
     * The MPathwayTypeCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/MPathwayTypeCompactDtoPagedListApiResponse}
     */
    MPathwayTypeCompactDtoPagedListApiResponse,

    /**
     * The MPathwayTypeCreateDto model constructor.
     * @property {module:model/MPathwayTypeCreateDto}
     */
    MPathwayTypeCreateDto,

    /**
     * The MPathwayTypeDto model constructor.
     * @property {module:model/MPathwayTypeDto}
     */
    MPathwayTypeDto,

    /**
     * The MPathwayTypeDtoApiResponse model constructor.
     * @property {module:model/MPathwayTypeDtoApiResponse}
     */
    MPathwayTypeDtoApiResponse,

    /**
     * The MPathwayTypeDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MPathwayTypeDtoIEnumerableApiResponse}
     */
    MPathwayTypeDtoIEnumerableApiResponse,

    /**
     * The MPathwayTypeEditDto model constructor.
     * @property {module:model/MPathwayTypeEditDto}
     */
    MPathwayTypeEditDto,

    /**
     * The MPathwayTypeFilterDto model constructor.
     * @property {module:model/MPathwayTypeFilterDto}
     */
    MPathwayTypeFilterDto,

    /**
     * The MPathwayTypeFilterDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MPathwayTypeFilterDtoIEnumerableApiResponse}
     */
    MPathwayTypeFilterDtoIEnumerableApiResponse,

    /**
     * The MStepAttributeCompactDto model constructor.
     * @property {module:model/MStepAttributeCompactDto}
     */
    MStepAttributeCompactDto,

    /**
     * The MStepAttributeCompactDtoApiResponse model constructor.
     * @property {module:model/MStepAttributeCompactDtoApiResponse}
     */
    MStepAttributeCompactDtoApiResponse,

    /**
     * The MStepAttributeCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MStepAttributeCompactDtoIEnumerableApiResponse}
     */
    MStepAttributeCompactDtoIEnumerableApiResponse,

    /**
     * The MStepAttributeCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/MStepAttributeCompactDtoPagedListApiResponse}
     */
    MStepAttributeCompactDtoPagedListApiResponse,

    /**
     * The MStepAttributeCreateDto model constructor.
     * @property {module:model/MStepAttributeCreateDto}
     */
    MStepAttributeCreateDto,

    /**
     * The MStepAttributeDto model constructor.
     * @property {module:model/MStepAttributeDto}
     */
    MStepAttributeDto,

    /**
     * The MStepAttributeDtoApiResponse model constructor.
     * @property {module:model/MStepAttributeDtoApiResponse}
     */
    MStepAttributeDtoApiResponse,

    /**
     * The MStepAttributeEditDto model constructor.
     * @property {module:model/MStepAttributeEditDto}
     */
    MStepAttributeEditDto,

    /**
     * The MStepCompactDto model constructor.
     * @property {module:model/MStepCompactDto}
     */
    MStepCompactDto,

    /**
     * The MStepCompactDtoApiResponse model constructor.
     * @property {module:model/MStepCompactDtoApiResponse}
     */
    MStepCompactDtoApiResponse,

    /**
     * The MStepCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MStepCompactDtoIEnumerableApiResponse}
     */
    MStepCompactDtoIEnumerableApiResponse,

    /**
     * The MStepCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/MStepCompactDtoPagedListApiResponse}
     */
    MStepCompactDtoPagedListApiResponse,

    /**
     * The MStepCreateDto model constructor.
     * @property {module:model/MStepCreateDto}
     */
    MStepCreateDto,

    /**
     * The MStepDto model constructor.
     * @property {module:model/MStepDto}
     */
    MStepDto,

    /**
     * The MStepDtoApiResponse model constructor.
     * @property {module:model/MStepDtoApiResponse}
     */
    MStepDtoApiResponse,

    /**
     * The MStepEditDto model constructor.
     * @property {module:model/MStepEditDto}
     */
    MStepEditDto,

    /**
     * The MStepFilterDto model constructor.
     * @property {module:model/MStepFilterDto}
     */
    MStepFilterDto,

    /**
     * The MStepFilterDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MStepFilterDtoIEnumerableApiResponse}
     */
    MStepFilterDtoIEnumerableApiResponse,

    /**
     * The MStepOutcomeCommunicationCompactDto model constructor.
     * @property {module:model/MStepOutcomeCommunicationCompactDto}
     */
    MStepOutcomeCommunicationCompactDto,

    /**
     * The MStepOutcomeCommunicationCompactDtoApiResponse model constructor.
     * @property {module:model/MStepOutcomeCommunicationCompactDtoApiResponse}
     */
    MStepOutcomeCommunicationCompactDtoApiResponse,

    /**
     * The MStepOutcomeCommunicationCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MStepOutcomeCommunicationCompactDtoIEnumerableApiResponse}
     */
    MStepOutcomeCommunicationCompactDtoIEnumerableApiResponse,

    /**
     * The MStepOutcomeCommunicationCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/MStepOutcomeCommunicationCompactDtoPagedListApiResponse}
     */
    MStepOutcomeCommunicationCompactDtoPagedListApiResponse,

    /**
     * The MStepOutcomeCommunicationCreateDto model constructor.
     * @property {module:model/MStepOutcomeCommunicationCreateDto}
     */
    MStepOutcomeCommunicationCreateDto,

    /**
     * The MStepOutcomeCommunicationDto model constructor.
     * @property {module:model/MStepOutcomeCommunicationDto}
     */
    MStepOutcomeCommunicationDto,

    /**
     * The MStepOutcomeCommunicationDtoApiResponse model constructor.
     * @property {module:model/MStepOutcomeCommunicationDtoApiResponse}
     */
    MStepOutcomeCommunicationDtoApiResponse,

    /**
     * The MStepOutcomeCommunicationEditDto model constructor.
     * @property {module:model/MStepOutcomeCommunicationEditDto}
     */
    MStepOutcomeCommunicationEditDto,

    /**
     * The MStepOutcomeCompactDto model constructor.
     * @property {module:model/MStepOutcomeCompactDto}
     */
    MStepOutcomeCompactDto,

    /**
     * The MStepOutcomeCompactDtoApiResponse model constructor.
     * @property {module:model/MStepOutcomeCompactDtoApiResponse}
     */
    MStepOutcomeCompactDtoApiResponse,

    /**
     * The MStepOutcomeCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MStepOutcomeCompactDtoIEnumerableApiResponse}
     */
    MStepOutcomeCompactDtoIEnumerableApiResponse,

    /**
     * The MStepOutcomeCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/MStepOutcomeCompactDtoPagedListApiResponse}
     */
    MStepOutcomeCompactDtoPagedListApiResponse,

    /**
     * The MStepOutcomeCreateDto model constructor.
     * @property {module:model/MStepOutcomeCreateDto}
     */
    MStepOutcomeCreateDto,

    /**
     * The MStepOutcomeDto model constructor.
     * @property {module:model/MStepOutcomeDto}
     */
    MStepOutcomeDto,

    /**
     * The MStepOutcomeDtoApiResponse model constructor.
     * @property {module:model/MStepOutcomeDtoApiResponse}
     */
    MStepOutcomeDtoApiResponse,

    /**
     * The MStepOutcomeEditDto model constructor.
     * @property {module:model/MStepOutcomeEditDto}
     */
    MStepOutcomeEditDto,

    /**
     * The MStepOutcomeFilterDto model constructor.
     * @property {module:model/MStepOutcomeFilterDto}
     */
    MStepOutcomeFilterDto,

    /**
     * The MStepOutcomeFilterDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MStepOutcomeFilterDtoIEnumerableApiResponse}
     */
    MStepOutcomeFilterDtoIEnumerableApiResponse,

    /**
     * The MStepTextCompactDto model constructor.
     * @property {module:model/MStepTextCompactDto}
     */
    MStepTextCompactDto,

    /**
     * The MStepTextCompactDtoApiResponse model constructor.
     * @property {module:model/MStepTextCompactDtoApiResponse}
     */
    MStepTextCompactDtoApiResponse,

    /**
     * The MStepTextCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/MStepTextCompactDtoIEnumerableApiResponse}
     */
    MStepTextCompactDtoIEnumerableApiResponse,

    /**
     * The MStepTextCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/MStepTextCompactDtoPagedListApiResponse}
     */
    MStepTextCompactDtoPagedListApiResponse,

    /**
     * The MStepTextCreateDto model constructor.
     * @property {module:model/MStepTextCreateDto}
     */
    MStepTextCreateDto,

    /**
     * The MStepTextDto model constructor.
     * @property {module:model/MStepTextDto}
     */
    MStepTextDto,

    /**
     * The MStepTextDtoApiResponse model constructor.
     * @property {module:model/MStepTextDtoApiResponse}
     */
    MStepTextDtoApiResponse,

    /**
     * The MStepTextEditDto model constructor.
     * @property {module:model/MStepTextEditDto}
     */
    MStepTextEditDto,

    /**
     * The NormalizationStatus model constructor.
     * @property {module:model/NormalizationStatus}
     */
    NormalizationStatus,

    /**
     * The OntologyCompactDto model constructor.
     * @property {module:model/OntologyCompactDto}
     */
    OntologyCompactDto,

    /**
     * The OntologyCompactDtoApiResponse model constructor.
     * @property {module:model/OntologyCompactDtoApiResponse}
     */
    OntologyCompactDtoApiResponse,

    /**
     * The OntologyCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/OntologyCompactDtoIEnumerableApiResponse}
     */
    OntologyCompactDtoIEnumerableApiResponse,

    /**
     * The OntologyCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/OntologyCompactDtoPagedListApiResponse}
     */
    OntologyCompactDtoPagedListApiResponse,

    /**
     * The OntologyCreateDto model constructor.
     * @property {module:model/OntologyCreateDto}
     */
    OntologyCreateDto,

    /**
     * The OntologyDto model constructor.
     * @property {module:model/OntologyDto}
     */
    OntologyDto,

    /**
     * The OntologyDtoApiResponse model constructor.
     * @property {module:model/OntologyDtoApiResponse}
     */
    OntologyDtoApiResponse,

    /**
     * The OntologyEditDto model constructor.
     * @property {module:model/OntologyEditDto}
     */
    OntologyEditDto,

    /**
     * The Overview model constructor.
     * @property {module:model/Overview}
     */
    Overview,

    /**
     * The PageResult model constructor.
     * @property {module:model/PageResult}
     */
    PageResult,

    /**
     * The ParsedData model constructor.
     * @property {module:model/ParsedData}
     */
    ParsedData,

    /**
     * The ParserCompactDto model constructor.
     * @property {module:model/ParserCompactDto}
     */
    ParserCompactDto,

    /**
     * The ParserCompactDtoApiResponse model constructor.
     * @property {module:model/ParserCompactDtoApiResponse}
     */
    ParserCompactDtoApiResponse,

    /**
     * The ParserCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/ParserCompactDtoIEnumerableApiResponse}
     */
    ParserCompactDtoIEnumerableApiResponse,

    /**
     * The ParserCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/ParserCompactDtoPagedListApiResponse}
     */
    ParserCompactDtoPagedListApiResponse,

    /**
     * The ParserCreateDto model constructor.
     * @property {module:model/ParserCreateDto}
     */
    ParserCreateDto,

    /**
     * The ParserDocumentCompactDto model constructor.
     * @property {module:model/ParserDocumentCompactDto}
     */
    ParserDocumentCompactDto,

    /**
     * The ParserDocumentCompactDtoApiResponse model constructor.
     * @property {module:model/ParserDocumentCompactDtoApiResponse}
     */
    ParserDocumentCompactDtoApiResponse,

    /**
     * The ParserDocumentCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/ParserDocumentCompactDtoIEnumerableApiResponse}
     */
    ParserDocumentCompactDtoIEnumerableApiResponse,

    /**
     * The ParserDocumentCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/ParserDocumentCompactDtoPagedListApiResponse}
     */
    ParserDocumentCompactDtoPagedListApiResponse,

    /**
     * The ParserDocumentDto model constructor.
     * @property {module:model/ParserDocumentDto}
     */
    ParserDocumentDto,

    /**
     * The ParserDocumentDtoApiResponse model constructor.
     * @property {module:model/ParserDocumentDtoApiResponse}
     */
    ParserDocumentDtoApiResponse,

    /**
     * The ParserDocumentEditDto model constructor.
     * @property {module:model/ParserDocumentEditDto}
     */
    ParserDocumentEditDto,

    /**
     * The ParserDocumentParseRChilliJSONBody model constructor.
     * @property {module:model/ParserDocumentParseRChilliJSONBody}
     */
    ParserDocumentParseRChilliJSONBody,

    /**
     * The ParserDto model constructor.
     * @property {module:model/ParserDto}
     */
    ParserDto,

    /**
     * The ParserDtoApiResponse model constructor.
     * @property {module:model/ParserDtoApiResponse}
     */
    ParserDtoApiResponse,

    /**
     * The ParserEditDto model constructor.
     * @property {module:model/ParserEditDto}
     */
    ParserEditDto,

    /**
     * The ParserType model constructor.
     * @property {module:model/ParserType}
     */
    ParserType,

    /**
     * The PathwayAttributeCreateDto model constructor.
     * @property {module:model/PathwayAttributeCreateDto}
     */
    PathwayAttributeCreateDto,

    /**
     * The PathwayAttributeDto model constructor.
     * @property {module:model/PathwayAttributeDto}
     */
    PathwayAttributeDto,

    /**
     * The PathwayCompactDto model constructor.
     * @property {module:model/PathwayCompactDto}
     */
    PathwayCompactDto,

    /**
     * The PathwayCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/PathwayCompactDtoIEnumerableApiResponse}
     */
    PathwayCompactDtoIEnumerableApiResponse,

    /**
     * The PathwayCreateDto model constructor.
     * @property {module:model/PathwayCreateDto}
     */
    PathwayCreateDto,

    /**
     * The PathwayDto model constructor.
     * @property {module:model/PathwayDto}
     */
    PathwayDto,

    /**
     * The PathwayDtoApiResponse model constructor.
     * @property {module:model/PathwayDtoApiResponse}
     */
    PathwayDtoApiResponse,

    /**
     * The PathwayOutcomeAttributeCreateDto model constructor.
     * @property {module:model/PathwayOutcomeAttributeCreateDto}
     */
    PathwayOutcomeAttributeCreateDto,

    /**
     * The PathwayOutcomeAttributeDto model constructor.
     * @property {module:model/PathwayOutcomeAttributeDto}
     */
    PathwayOutcomeAttributeDto,

    /**
     * The PathwayOutcomeCreateDto model constructor.
     * @property {module:model/PathwayOutcomeCreateDto}
     */
    PathwayOutcomeCreateDto,

    /**
     * The PathwayOutcomeDto model constructor.
     * @property {module:model/PathwayOutcomeDto}
     */
    PathwayOutcomeDto,

    /**
     * The PathwayStepAttributeCreateDto model constructor.
     * @property {module:model/PathwayStepAttributeCreateDto}
     */
    PathwayStepAttributeCreateDto,

    /**
     * The PathwayStepAttributeDto model constructor.
     * @property {module:model/PathwayStepAttributeDto}
     */
    PathwayStepAttributeDto,

    /**
     * The PathwayStepCreateDto model constructor.
     * @property {module:model/PathwayStepCreateDto}
     */
    PathwayStepCreateDto,

    /**
     * The PathwayStepDto model constructor.
     * @property {module:model/PathwayStepDto}
     */
    PathwayStepDto,

    /**
     * The PathwayStepOutcomeCommunicationCreateDto model constructor.
     * @property {module:model/PathwayStepOutcomeCommunicationCreateDto}
     */
    PathwayStepOutcomeCommunicationCreateDto,

    /**
     * The PathwayStepOutcomeCommunicationDto model constructor.
     * @property {module:model/PathwayStepOutcomeCommunicationDto}
     */
    PathwayStepOutcomeCommunicationDto,

    /**
     * The PathwayStepOutcomeCreateDto model constructor.
     * @property {module:model/PathwayStepOutcomeCreateDto}
     */
    PathwayStepOutcomeCreateDto,

    /**
     * The PathwayStepOutcomeDto model constructor.
     * @property {module:model/PathwayStepOutcomeDto}
     */
    PathwayStepOutcomeDto,

    /**
     * The PathwayStepTextCreateDto model constructor.
     * @property {module:model/PathwayStepTextCreateDto}
     */
    PathwayStepTextCreateDto,

    /**
     * The PathwayStepTextDto model constructor.
     * @property {module:model/PathwayStepTextDto}
     */
    PathwayStepTextDto,

    /**
     * The ProgressiveStatus model constructor.
     * @property {module:model/ProgressiveStatus}
     */
    ProgressiveStatus,

    /**
     * The ProjSkill model constructor.
     * @property {module:model/ProjSkill}
     */
    ProjSkill,

    /**
     * The ProjectDetail model constructor.
     * @property {module:model/ProjectDetail}
     */
    ProjectDetail,

    /**
     * The ProjectDto model constructor.
     * @property {module:model/ProjectDto}
     */
    ProjectDto,

    /**
     * The ProjectSkillCreateDto model constructor.
     * @property {module:model/ProjectSkillCreateDto}
     */
    ProjectSkillCreateDto,

    /**
     * The ProjectSkillDto model constructor.
     * @property {module:model/ProjectSkillDto}
     */
    ProjectSkillDto,

    /**
     * The ProjectSkillEditDto model constructor.
     * @property {module:model/ProjectSkillEditDto}
     */
    ProjectSkillEditDto,

    /**
     * The QualificationDto model constructor.
     * @property {module:model/QualificationDto}
     */
    QualificationDto,

    /**
     * The RSAdditionalDetails model constructor.
     * @property {module:model/RSAdditionalDetails}
     */
    RSAdditionalDetails,

    /**
     * The RSCandidateAddress model constructor.
     * @property {module:model/RSCandidateAddress}
     */
    RSCandidateAddress,

    /**
     * The RSCandidateCertificate model constructor.
     * @property {module:model/RSCandidateCertificate}
     */
    RSCandidateCertificate,

    /**
     * The RSCandidatePersonalInfoDto model constructor.
     * @property {module:model/RSCandidatePersonalInfoDto}
     */
    RSCandidatePersonalInfoDto,

    /**
     * The RSCandidateTrait model constructor.
     * @property {module:model/RSCandidateTrait}
     */
    RSCandidateTrait,

    /**
     * The RSExperienceTimeLine model constructor.
     * @property {module:model/RSExperienceTimeLine}
     */
    RSExperienceTimeLine,

    /**
     * The RSExpertise model constructor.
     * @property {module:model/RSExpertise}
     */
    RSExpertise,

    /**
     * The RSNormalizationStatus model constructor.
     * @property {module:model/RSNormalizationStatus}
     */
    RSNormalizationStatus,

    /**
     * The RSProfileCompletion model constructor.
     * @property {module:model/RSProfileCompletion}
     */
    RSProfileCompletion,

    /**
     * The RSProfileHeadLine model constructor.
     * @property {module:model/RSProfileHeadLine}
     */
    RSProfileHeadLine,

    /**
     * The RSProfileImage model constructor.
     * @property {module:model/RSProfileImage}
     */
    RSProfileImage,

    /**
     * The RSProject model constructor.
     * @property {module:model/RSProject}
     */
    RSProject,

    /**
     * The RSQualificationTimeLine model constructor.
     * @property {module:model/RSQualificationTimeLine}
     */
    RSQualificationTimeLine,

    /**
     * The RSSocialMediaWebsite model constructor.
     * @property {module:model/RSSocialMediaWebsite}
     */
    RSSocialMediaWebsite,

    /**
     * The RSSoftSkill model constructor.
     * @property {module:model/RSSoftSkill}
     */
    RSSoftSkill,

    /**
     * The RSTechnicalSkill model constructor.
     * @property {module:model/RSTechnicalSkill}
     */
    RSTechnicalSkill,

    /**
     * The RSTechnicalSkillPlatform model constructor.
     * @property {module:model/RSTechnicalSkillPlatform}
     */
    RSTechnicalSkillPlatform,

    /**
     * The RSTimeLine model constructor.
     * @property {module:model/RSTimeLine}
     */
    RSTimeLine,

    /**
     * The RSWebsite model constructor.
     * @property {module:model/RSWebsite}
     */
    RSWebsite,

    /**
     * The RoleDto model constructor.
     * @property {module:model/RoleDto}
     */
    RoleDto,

    /**
     * The SalaryDto model constructor.
     * @property {module:model/SalaryDto}
     */
    SalaryDto,

    /**
     * The Skill model constructor.
     * @property {module:model/Skill}
     */
    Skill,

    /**
     * The SkillCompactDto model constructor.
     * @property {module:model/SkillCompactDto}
     */
    SkillCompactDto,

    /**
     * The SkillCompactDtoApiResponse model constructor.
     * @property {module:model/SkillCompactDtoApiResponse}
     */
    SkillCompactDtoApiResponse,

    /**
     * The SkillCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/SkillCompactDtoIEnumerableApiResponse}
     */
    SkillCompactDtoIEnumerableApiResponse,

    /**
     * The SkillCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/SkillCompactDtoPagedListApiResponse}
     */
    SkillCompactDtoPagedListApiResponse,

    /**
     * The SkillCreateDto model constructor.
     * @property {module:model/SkillCreateDto}
     */
    SkillCreateDto,

    /**
     * The SkillData model constructor.
     * @property {module:model/SkillData}
     */
    SkillData,

    /**
     * The SkillDto model constructor.
     * @property {module:model/SkillDto}
     */
    SkillDto,

    /**
     * The SkillDtoApiResponse model constructor.
     * @property {module:model/SkillDtoApiResponse}
     */
    SkillDtoApiResponse,

    /**
     * The SkillEditDto model constructor.
     * @property {module:model/SkillEditDto}
     */
    SkillEditDto,

    /**
     * The SkillFilterDto model constructor.
     * @property {module:model/SkillFilterDto}
     */
    SkillFilterDto,

    /**
     * The SkillFilterDtoIEnumerableApiResponse model constructor.
     * @property {module:model/SkillFilterDtoIEnumerableApiResponse}
     */
    SkillFilterDtoIEnumerableApiResponse,

    /**
     * The SkillLevel model constructor.
     * @property {module:model/SkillLevel}
     */
    SkillLevel,

    /**
     * The SkillList model constructor.
     * @property {module:model/SkillList}
     */
    SkillList,

    /**
     * The SkillPlatformCompactDto model constructor.
     * @property {module:model/SkillPlatformCompactDto}
     */
    SkillPlatformCompactDto,

    /**
     * The SkillPlatformCompactDtoApiResponse model constructor.
     * @property {module:model/SkillPlatformCompactDtoApiResponse}
     */
    SkillPlatformCompactDtoApiResponse,

    /**
     * The SkillPlatformCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/SkillPlatformCompactDtoIEnumerableApiResponse}
     */
    SkillPlatformCompactDtoIEnumerableApiResponse,

    /**
     * The SkillPlatformCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/SkillPlatformCompactDtoPagedListApiResponse}
     */
    SkillPlatformCompactDtoPagedListApiResponse,

    /**
     * The SkillPlatformCreateDto model constructor.
     * @property {module:model/SkillPlatformCreateDto}
     */
    SkillPlatformCreateDto,

    /**
     * The SkillPlatformDto model constructor.
     * @property {module:model/SkillPlatformDto}
     */
    SkillPlatformDto,

    /**
     * The SkillPlatformDtoApiResponse model constructor.
     * @property {module:model/SkillPlatformDtoApiResponse}
     */
    SkillPlatformDtoApiResponse,

    /**
     * The SkillPlatformEditDto model constructor.
     * @property {module:model/SkillPlatformEditDto}
     */
    SkillPlatformEditDto,

    /**
     * The SkillPlatformFilterDto model constructor.
     * @property {module:model/SkillPlatformFilterDto}
     */
    SkillPlatformFilterDto,

    /**
     * The SkillType model constructor.
     * @property {module:model/SkillType}
     */
    SkillType,

    /**
     * The SocialMediaType model constructor.
     * @property {module:model/SocialMediaType}
     */
    SocialMediaType,

    /**
     * The SoftSkill model constructor.
     * @property {module:model/SoftSkill}
     */
    SoftSkill,

    /**
     * The SourcingSequenceCompactDto model constructor.
     * @property {module:model/SourcingSequenceCompactDto}
     */
    SourcingSequenceCompactDto,

    /**
     * The SourcingSequenceCompactDtoApiResponse model constructor.
     * @property {module:model/SourcingSequenceCompactDtoApiResponse}
     */
    SourcingSequenceCompactDtoApiResponse,

    /**
     * The SourcingSequenceCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/SourcingSequenceCompactDtoIEnumerableApiResponse}
     */
    SourcingSequenceCompactDtoIEnumerableApiResponse,

    /**
     * The SourcingSequenceCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/SourcingSequenceCompactDtoPagedListApiResponse}
     */
    SourcingSequenceCompactDtoPagedListApiResponse,

    /**
     * The SourcingSequenceCreateDto model constructor.
     * @property {module:model/SourcingSequenceCreateDto}
     */
    SourcingSequenceCreateDto,

    /**
     * The SourcingSequenceDto model constructor.
     * @property {module:model/SourcingSequenceDto}
     */
    SourcingSequenceDto,

    /**
     * The SourcingSequenceDtoApiResponse model constructor.
     * @property {module:model/SourcingSequenceDtoApiResponse}
     */
    SourcingSequenceDtoApiResponse,

    /**
     * The SourcingSequenceEditDto model constructor.
     * @property {module:model/SourcingSequenceEditDto}
     */
    SourcingSequenceEditDto,

    /**
     * The SourcingSequenceFilterDto model constructor.
     * @property {module:model/SourcingSequenceFilterDto}
     */
    SourcingSequenceFilterDto,

    /**
     * The SourcingSequenceFilterDtoIEnumerableApiResponse model constructor.
     * @property {module:model/SourcingSequenceFilterDtoIEnumerableApiResponse}
     */
    SourcingSequenceFilterDtoIEnumerableApiResponse,

    /**
     * The SpecializationDto model constructor.
     * @property {module:model/SpecializationDto}
     */
    SpecializationDto,

    /**
     * The StateCompactDto model constructor.
     * @property {module:model/StateCompactDto}
     */
    StateCompactDto,

    /**
     * The StateCompactDtoApiResponse model constructor.
     * @property {module:model/StateCompactDtoApiResponse}
     */
    StateCompactDtoApiResponse,

    /**
     * The StateCompactDtoIEnumerableApiResponse model constructor.
     * @property {module:model/StateCompactDtoIEnumerableApiResponse}
     */
    StateCompactDtoIEnumerableApiResponse,

    /**
     * The StateCompactDtoPagedListApiResponse model constructor.
     * @property {module:model/StateCompactDtoPagedListApiResponse}
     */
    StateCompactDtoPagedListApiResponse,

    /**
     * The StateCreateDto model constructor.
     * @property {module:model/StateCreateDto}
     */
    StateCreateDto,

    /**
     * The StateDto model constructor.
     * @property {module:model/StateDto}
     */
    StateDto,

    /**
     * The StateDtoApiResponse model constructor.
     * @property {module:model/StateDtoApiResponse}
     */
    StateDtoApiResponse,

    /**
     * The StateEditDto model constructor.
     * @property {module:model/StateEditDto}
     */
    StateEditDto,

    /**
     * The StatusType model constructor.
     * @property {module:model/StatusType}
     */
    StatusType,

    /**
     * The StringIEnumerableApiResponse model constructor.
     * @property {module:model/StringIEnumerableApiResponse}
     */
    StringIEnumerableApiResponse,

    /**
     * The SubRoleDto model constructor.
     * @property {module:model/SubRoleDto}
     */
    SubRoleDto,

    /**
     * The TaggedCandidates model constructor.
     * @property {module:model/TaggedCandidates}
     */
    TaggedCandidates,

    /**
     * The Trait model constructor.
     * @property {module:model/Trait}
     */
    Trait,

    /**
     * The UpdateBasicDocumentDetailsCandidateIdBody model constructor.
     * @property {module:model/UpdateBasicDocumentDetailsCandidateIdBody}
     */
    UpdateBasicDocumentDetailsCandidateIdBody,

    /**
     * The UploadBackGroundImageCandidateIdBody model constructor.
     * @property {module:model/UploadBackGroundImageCandidateIdBody}
     */
    UploadBackGroundImageCandidateIdBody,

    /**
     * The UploadCollegeImageCandidateIdBody model constructor.
     * @property {module:model/UploadCollegeImageCandidateIdBody}
     */
    UploadCollegeImageCandidateIdBody,

    /**
     * The UploadProfileImageCandidateIdBody model constructor.
     * @property {module:model/UploadProfileImageCandidateIdBody}
     */
    UploadProfileImageCandidateIdBody,

    /**
     * The UploadResumeCandidateIdBody model constructor.
     * @property {module:model/UploadResumeCandidateIdBody}
     */
    UploadResumeCandidateIdBody,

    /**
     * The UserDto model constructor.
     * @property {module:model/UserDto}
     */
    UserDto,

    /**
     * The UserDtoApiResponse model constructor.
     * @property {module:model/UserDtoApiResponse}
     */
    UserDtoApiResponse,

    /**
     * The ValidateCandidateDto model constructor.
     * @property {module:model/ValidateCandidateDto}
     */
    ValidateCandidateDto,

    /**
     * The Website model constructor.
     * @property {module:model/Website}
     */
    Website,

    /**
     * The WebsiteType model constructor.
     * @property {module:model/WebsiteType}
     */
    WebsiteType,

    /**
     * The WorkplaceType model constructor.
     * @property {module:model/WorkplaceType}
     */
    WorkplaceType,

    /**
    * The AccountApi service constructor.
    * @property {module:api/AccountApi}
    */
    AccountApi,

    /**
    * The AssessmentApi service constructor.
    * @property {module:api/AssessmentApi}
    */
    AssessmentApi,

    /**
    * The AssessmentSkillApi service constructor.
    * @property {module:api/AssessmentSkillApi}
    */
    AssessmentSkillApi,

    /**
    * The BucketsApi service constructor.
    * @property {module:api/BucketsApi}
    */
    BucketsApi,

    /**
    * The BusinessVerticalApi service constructor.
    * @property {module:api/BusinessVerticalApi}
    */
    BusinessVerticalApi,

    /**
    * The CandidateApi service constructor.
    * @property {module:api/CandidateApi}
    */
    CandidateApi,

    /**
    * The CandidateCertificateApi service constructor.
    * @property {module:api/CandidateCertificateApi}
    */
    CandidateCertificateApi,

    /**
    * The CandidateConfigurationApi service constructor.
    * @property {module:api/CandidateConfigurationApi}
    */
    CandidateConfigurationApi,

    /**
    * The CandidateDocumentApi service constructor.
    * @property {module:api/CandidateDocumentApi}
    */
    CandidateDocumentApi,

    /**
    * The CandidateEmployerApi service constructor.
    * @property {module:api/CandidateEmployerApi}
    */
    CandidateEmployerApi,

    /**
    * The CandidatePersonalInfoApi service constructor.
    * @property {module:api/CandidatePersonalInfoApi}
    */
    CandidatePersonalInfoApi,

    /**
    * The CandidateProfileApi service constructor.
    * @property {module:api/CandidateProfileApi}
    */
    CandidateProfileApi,

    /**
    * The CandidateProjectsApi service constructor.
    * @property {module:api/CandidateProjectsApi}
    */
    CandidateProjectsApi,

    /**
    * The CandidateQualificationApi service constructor.
    * @property {module:api/CandidateQualificationApi}
    */
    CandidateQualificationApi,

    /**
    * The CandidateSkillApi service constructor.
    * @property {module:api/CandidateSkillApi}
    */
    CandidateSkillApi,

    /**
    * The CandidateSkillPlatformApi service constructor.
    * @property {module:api/CandidateSkillPlatformApi}
    */
    CandidateSkillPlatformApi,

    /**
    * The CandidateWebsiteApi service constructor.
    * @property {module:api/CandidateWebsiteApi}
    */
    CandidateWebsiteApi,

    /**
    * The CertificateApi service constructor.
    * @property {module:api/CertificateApi}
    */
    CertificateApi,

    /**
    * The CertificateAliasApi service constructor.
    * @property {module:api/CertificateAliasApi}
    */
    CertificateAliasApi,

    /**
    * The CityApi service constructor.
    * @property {module:api/CityApi}
    */
    CityApi,

    /**
    * The ClientApi service constructor.
    * @property {module:api/ClientApi}
    */
    ClientApi,

    /**
    * The ClientContractApi service constructor.
    * @property {module:api/ClientContractApi}
    */
    ClientContractApi,

    /**
    * The ClientDashboardApi service constructor.
    * @property {module:api/ClientDashboardApi}
    */
    ClientDashboardApi,

    /**
    * The ClientInterviewRoundApi service constructor.
    * @property {module:api/ClientInterviewRoundApi}
    */
    ClientInterviewRoundApi,

    /**
    * The CompanyApi service constructor.
    * @property {module:api/CompanyApi}
    */
    CompanyApi,

    /**
    * The CompanyAliasApi service constructor.
    * @property {module:api/CompanyAliasApi}
    */
    CompanyAliasApi,

    /**
    * The ContactApi service constructor.
    * @property {module:api/ContactApi}
    */
    ContactApi,

    /**
    * The ContentApi service constructor.
    * @property {module:api/ContentApi}
    */
    ContentApi,

    /**
    * The CountryApi service constructor.
    * @property {module:api/CountryApi}
    */
    CountryApi,

    /**
    * The CourseApi service constructor.
    * @property {module:api/CourseApi}
    */
    CourseApi,

    /**
    * The DegreeApi service constructor.
    * @property {module:api/DegreeApi}
    */
    DegreeApi,

    /**
    * The DegreeAliasApi service constructor.
    * @property {module:api/DegreeAliasApi}
    */
    DegreeAliasApi,

    /**
    * The DocumentApi service constructor.
    * @property {module:api/DocumentApi}
    */
    DocumentApi,

    /**
    * The DummyApi service constructor.
    * @property {module:api/DummyApi}
    */
    DummyApi,

    /**
    * The EducationInstituteApi service constructor.
    * @property {module:api/EducationInstituteApi}
    */
    EducationInstituteApi,

    /**
    * The FieldOfStudyApi service constructor.
    * @property {module:api/FieldOfStudyApi}
    */
    FieldOfStudyApi,

    /**
    * The FilesApi service constructor.
    * @property {module:api/FilesApi}
    */
    FilesApi,

    /**
    * The FunctionalAreaApi service constructor.
    * @property {module:api/FunctionalAreaApi}
    */
    FunctionalAreaApi,

    /**
    * The GlobalConfigrationApi service constructor.
    * @property {module:api/GlobalConfigrationApi}
    */
    GlobalConfigrationApi,

    /**
    * The HotSkillApi service constructor.
    * @property {module:api/HotSkillApi}
    */
    HotSkillApi,

    /**
    * The IntegrationApi service constructor.
    * @property {module:api/IntegrationApi}
    */
    IntegrationApi,

    /**
    * The JdAndAssessmentApi service constructor.
    * @property {module:api/JdAndAssessmentApi}
    */
    JdAndAssessmentApi,

    /**
    * The JdAndCandidateStatusApi service constructor.
    * @property {module:api/JdAndCandidateStatusApi}
    */
    JdAndCandidateStatusApi,

    /**
    * The JdAndTaggedCandidateApi service constructor.
    * @property {module:api/JdAndTaggedCandidateApi}
    */
    JdAndTaggedCandidateApi,

    /**
    * The JdClientInterviewApi service constructor.
    * @property {module:api/JdClientInterviewApi}
    */
    JdClientInterviewApi,

    /**
    * The JdClientInterviewRoundsApi service constructor.
    * @property {module:api/JdClientInterviewRoundsApi}
    */
    JdClientInterviewRoundsApi,

    /**
    * The JdInterviewRoundApi service constructor.
    * @property {module:api/JdInterviewRoundApi}
    */
    JdInterviewRoundApi,

    /**
    * The JdInterviewRoundsPanelApi service constructor.
    * @property {module:api/JdInterviewRoundsPanelApi}
    */
    JdInterviewRoundsPanelApi,

    /**
    * The JdSkillApi service constructor.
    * @property {module:api/JdSkillApi}
    */
    JdSkillApi,

    /**
    * The JdSkillPlatformApi service constructor.
    * @property {module:api/JdSkillPlatformApi}
    */
    JdSkillPlatformApi,

    /**
    * The JdSoftSkillApi service constructor.
    * @property {module:api/JdSoftSkillApi}
    */
    JdSoftSkillApi,

    /**
    * The JdTraitApi service constructor.
    * @property {module:api/JdTraitApi}
    */
    JdTraitApi,

    /**
    * The JobDescriptionApi service constructor.
    * @property {module:api/JobDescriptionApi}
    */
    JobDescriptionApi,

    /**
    * The JobTitleApi service constructor.
    * @property {module:api/JobTitleApi}
    */
    JobTitleApi,

    /**
    * The JobTitleAliasApi service constructor.
    * @property {module:api/JobTitleAliasApi}
    */
    JobTitleAliasApi,

    /**
    * The JobTitleAndSkillApi service constructor.
    * @property {module:api/JobTitleAndSkillApi}
    */
    JobTitleAndSkillApi,

    /**
    * The LanguageApi service constructor.
    * @property {module:api/LanguageApi}
    */
    LanguageApi,

    /**
    * The MPathwayAttributeApi service constructor.
    * @property {module:api/MPathwayAttributeApi}
    */
    MPathwayAttributeApi,

    /**
    * The MPathwayOutcomeApi service constructor.
    * @property {module:api/MPathwayOutcomeApi}
    */
    MPathwayOutcomeApi,

    /**
    * The MPathwayOutcomeAttributeApi service constructor.
    * @property {module:api/MPathwayOutcomeAttributeApi}
    */
    MPathwayOutcomeAttributeApi,

    /**
    * The MPathwayTypeApi service constructor.
    * @property {module:api/MPathwayTypeApi}
    */
    MPathwayTypeApi,

    /**
    * The MStepApi service constructor.
    * @property {module:api/MStepApi}
    */
    MStepApi,

    /**
    * The MStepAttributeApi service constructor.
    * @property {module:api/MStepAttributeApi}
    */
    MStepAttributeApi,

    /**
    * The MStepOutcomeApi service constructor.
    * @property {module:api/MStepOutcomeApi}
    */
    MStepOutcomeApi,

    /**
    * The MStepOutcomeCommunicationApi service constructor.
    * @property {module:api/MStepOutcomeCommunicationApi}
    */
    MStepOutcomeCommunicationApi,

    /**
    * The MStepTextApi service constructor.
    * @property {module:api/MStepTextApi}
    */
    MStepTextApi,

    /**
    * The OntologyApi service constructor.
    * @property {module:api/OntologyApi}
    */
    OntologyApi,

    /**
    * The ParserApi service constructor.
    * @property {module:api/ParserApi}
    */
    ParserApi,

    /**
    * The ParserDocumentApi service constructor.
    * @property {module:api/ParserDocumentApi}
    */
    ParserDocumentApi,

    /**
    * The PathwayApi service constructor.
    * @property {module:api/PathwayApi}
    */
    PathwayApi,

    /**
    * The RSAApi service constructor.
    * @property {module:api/RSAApi}
    */
    RSAApi,

    /**
    * The SkillApi service constructor.
    * @property {module:api/SkillApi}
    */
    SkillApi,

    /**
    * The SkillPlatformApi service constructor.
    * @property {module:api/SkillPlatformApi}
    */
    SkillPlatformApi,

    /**
    * The SourcingSequenceApi service constructor.
    * @property {module:api/SourcingSequenceApi}
    */
    SourcingSequenceApi,

    /**
    * The StateApi service constructor.
    * @property {module:api/StateApi}
    */
    StateApi
};
