/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {AcceptedStatus} from '../model/AcceptedStatus';
import {CandidateAutoSearchDto} from '../model/CandidateAutoSearchDto';
import {CandidateManualSearchDto} from '../model/CandidateManualSearchDto';
import {JobDescriptionCompactDtoApiResponse} from '../model/JobDescriptionCompactDtoApiResponse';
import {JobDescriptionCompactDtoIEnumerableApiResponse} from '../model/JobDescriptionCompactDtoIEnumerableApiResponse';
import {JobDescriptionCreateDto} from '../model/JobDescriptionCreateDto';
import {JobDescriptionDtoApiResponse} from '../model/JobDescriptionDtoApiResponse';
import {JobDescriptionDtoIEnumerableApiResponse} from '../model/JobDescriptionDtoIEnumerableApiResponse';
import {JobDescriptionEditDto} from '../model/JobDescriptionEditDto';
import {JobDescriptionFilterDtoIEnumerableApiResponse} from '../model/JobDescriptionFilterDtoIEnumerableApiResponse';
import {JobDescriptionSearchDtoIEnumerableApiResponse} from '../model/JobDescriptionSearchDtoIEnumerableApiResponse';
import {JobDescriptionSkillDto} from '../model/JobDescriptionSkillDto';
import {JobDescriptionSkillDtoApiResponse} from '../model/JobDescriptionSkillDtoApiResponse';
import {JobDescriptionStatusDto} from '../model/JobDescriptionStatusDto';

/**
* JobDescription service.
* @module api/JobDescriptionApi
* @version v3.76(UAT)
*/
export class JobDescriptionApi {

    /**
    * Constructs a new JobDescriptionApi. 
    * @alias module:api/JobDescriptionApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiJobDescriptionGet operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/JobDescriptionApi~apiJobDescriptionGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionGetAllByClientIdClientIdGet operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionGetAllByClientIdClientIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionFilterDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} clientId 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionGetAllByClientIdClientIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionGetAllByClientIdClientIdGet(clientId, callback) {
      
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling apiJobDescriptionGetAllByClientIdClientIdGet");
      }

      let pathParams = {
        'clientId': clientId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionFilterDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/GetAllByClientId/{clientId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionGetAllByNameAndClientIdGet operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionGetAllByNameAndClientIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionFilterDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {Number} opts.clientId 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionGetAllByNameAndClientIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionGetAllByNameAndClientIdGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'name': opts['name'],'clientId': opts['clientId']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionFilterDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/GetAllByNameAndClientId', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionGetAllJdByClientIdClientIdGet operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionGetAllJdByClientIdClientIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} clientId 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionGetAllJdByClientIdClientIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionGetAllJdByClientIdClientIdGet(clientId, callback) {
      
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling apiJobDescriptionGetAllJdByClientIdClientIdGet");
      }

      let pathParams = {
        'clientId': clientId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/GetAllJdByClientId/{clientId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionGetAllJdByStatusStatusGet operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionGetAllJdByStatusStatusGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/AcceptedStatus} status   1 &#x3D; Open  2 &#x3D; Accepted  3 &#x3D; Rejected  4 &#x3D; InProgress  5 &#x3D; Closed  6 &#x3D; Deactivate
     * @param {Object} opts Optional parameters
     * @param {Number} opts.clientId 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionGetAllJdByStatusStatusGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionGetAllJdByStatusStatusGet(status, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'status' is set
      if (status === undefined || status === null) {
        throw new Error("Missing the required parameter 'status' when calling apiJobDescriptionGetAllJdByStatusStatusGet");
      }

      let pathParams = {
        'status': status
      };
      let queryParams = {
        'clientId': opts['clientId']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/GetAllJdByStatus/{status}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionGetAllJdHoldStatusHoldGet operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionGetAllJdHoldStatusHoldGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Boolean} hold 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.clientId 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionGetAllJdHoldStatusHoldGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionGetAllJdHoldStatusHoldGet(hold, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'hold' is set
      if (hold === undefined || hold === null) {
        throw new Error("Missing the required parameter 'hold' when calling apiJobDescriptionGetAllJdHoldStatusHoldGet");
      }

      let pathParams = {
        'hold': hold
      };
      let queryParams = {
        'clientId': opts['clientId']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/GetAllJdHoldStatus/{hold}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionGetByGuidGuidGet operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiJobDescriptionGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionGetJdBySlugPost operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionGetJdBySlugPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.slug 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionGetJdBySlugPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionGetJdBySlugPost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'slug': opts['slug']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/GetJdBySlug', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionGetJdSkillsByJdIdJobDescriptionIdGet operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionGetJdSkillsByJdIdJobDescriptionIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionSkillDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} jobDescriptionId 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionGetJdSkillsByJdIdJobDescriptionIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionGetJdSkillsByJdIdJobDescriptionIdGet(jobDescriptionId, callback) {
      
      let postBody = null;
      // verify the required parameter 'jobDescriptionId' is set
      if (jobDescriptionId === undefined || jobDescriptionId === null) {
        throw new Error("Missing the required parameter 'jobDescriptionId' when calling apiJobDescriptionGetJdSkillsByJdIdJobDescriptionIdGet");
      }

      let pathParams = {
        'JobDescriptionId': jobDescriptionId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionSkillDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/GetJdSkillsByJdId/{JobDescriptionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionIdDelete operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiJobDescriptionIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionIdGet operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiJobDescriptionIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionJdCandidatesAutoSearchPost operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionJdCandidatesAutoSearchPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionSearchDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateAutoSearchDto} opts.body 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionJdCandidatesAutoSearchPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionJdCandidatesAutoSearchPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionSearchDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/JdCandidatesAutoSearch', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionJdCandidatesManualSearchPost operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionJdCandidatesManualSearchPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionSearchDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateManualSearchDto} opts.body 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionJdCandidatesManualSearchPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionJdCandidatesManualSearchPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionSearchDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/JdCandidatesManualSearch', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionPost operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/JobDescriptionCreateDto} opts.body 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionPut operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/JobDescriptionEditDto} opts.body 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionUpdateJdAndSkillsJobDescriptionIdPost operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionUpdateJdAndSkillsJobDescriptionIdPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionSkillDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} jobDescriptionId 
     * @param {Object} opts Optional parameters
     * @param {module:model/JobDescriptionSkillDto} opts.body 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionUpdateJdAndSkillsJobDescriptionIdPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionUpdateJdAndSkillsJobDescriptionIdPost(jobDescriptionId, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'jobDescriptionId' is set
      if (jobDescriptionId === undefined || jobDescriptionId === null) {
        throw new Error("Missing the required parameter 'jobDescriptionId' when calling apiJobDescriptionUpdateJdAndSkillsJobDescriptionIdPost");
      }

      let pathParams = {
        'JobDescriptionId': jobDescriptionId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionSkillDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/UpdateJdAndSkills/{JobDescriptionId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionUpdateJdHoldStatusJobDescriptionIdStatusPost operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionUpdateJdHoldStatusJobDescriptionIdStatusPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} jobDescriptionId 
     * @param {Boolean} status 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionUpdateJdHoldStatusJobDescriptionIdStatusPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionUpdateJdHoldStatusJobDescriptionIdStatusPost(jobDescriptionId, status, callback) {
      
      let postBody = null;
      // verify the required parameter 'jobDescriptionId' is set
      if (jobDescriptionId === undefined || jobDescriptionId === null) {
        throw new Error("Missing the required parameter 'jobDescriptionId' when calling apiJobDescriptionUpdateJdHoldStatusJobDescriptionIdStatusPost");
      }
      // verify the required parameter 'status' is set
      if (status === undefined || status === null) {
        throw new Error("Missing the required parameter 'status' when calling apiJobDescriptionUpdateJdHoldStatusJobDescriptionIdStatusPost");
      }

      let pathParams = {
        'jobDescriptionId': jobDescriptionId,'status': status
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/UpdateJdHoldStatus/{jobDescriptionId}/{status}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJobDescriptionUpdateJdStatusPost operation.
     * @callback moduleapi/JobDescriptionApi~apiJobDescriptionUpdateJdStatusPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JobDescriptionDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/JobDescriptionStatusDto} opts.body 
     * @param {module:api/JobDescriptionApi~apiJobDescriptionUpdateJdStatusPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJobDescriptionUpdateJdStatusPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JobDescriptionDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JobDescription/UpdateJdStatus', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}