/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The RSProfileCompletion model module.
 * @module model/RSProfileCompletion
 * @version v3.76(UAT)
 */
export class RSProfileCompletion {
  /**
   * Constructs a new <code>RSProfileCompletion</code>.
   * @alias module:model/RSProfileCompletion
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RSProfileCompletion</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RSProfileCompletion} obj Optional instance to populate.
   * @return {module:model/RSProfileCompletion} The populated <code>RSProfileCompletion</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RSProfileCompletion();
      if (data.hasOwnProperty('profileCompletionTotal'))
        obj.profileCompletionTotal = ApiClient.convertToType(data['profileCompletionTotal'], 'Number');
      if (data.hasOwnProperty('profileCompletionFilled'))
        obj.profileCompletionFilled = ApiClient.convertToType(data['profileCompletionFilled'], 'Number');
      if (data.hasOwnProperty('profileCompletionPercentage'))
        obj.profileCompletionPercentage = ApiClient.convertToType(data['profileCompletionPercentage'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} profileCompletionTotal
 */
RSProfileCompletion.prototype.profileCompletionTotal = undefined;

/**
 * @member {Number} profileCompletionFilled
 */
RSProfileCompletion.prototype.profileCompletionFilled = undefined;

/**
 * @member {Number} profileCompletionPercentage
 */
RSProfileCompletion.prototype.profileCompletionPercentage = undefined;

