/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {MStepOutcomeCompactDtoApiResponse} from '../model/MStepOutcomeCompactDtoApiResponse';
import {MStepOutcomeCompactDtoIEnumerableApiResponse} from '../model/MStepOutcomeCompactDtoIEnumerableApiResponse';
import {MStepOutcomeCompactDtoPagedListApiResponse} from '../model/MStepOutcomeCompactDtoPagedListApiResponse';
import {MStepOutcomeCreateDto} from '../model/MStepOutcomeCreateDto';
import {MStepOutcomeDtoApiResponse} from '../model/MStepOutcomeDtoApiResponse';
import {MStepOutcomeEditDto} from '../model/MStepOutcomeEditDto';
import {MStepOutcomeFilterDtoIEnumerableApiResponse} from '../model/MStepOutcomeFilterDtoIEnumerableApiResponse';

/**
* MStepOutcome service.
* @module api/MStepOutcomeApi
* @version v3.76(UAT)
*/
export class MStepOutcomeApi {

    /**
    * Constructs a new MStepOutcomeApi. 
    * @alias module:api/MStepOutcomeApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiMStepOutcomeGet operation.
     * @callback moduleapi/MStepOutcomeApi~apiMStepOutcomeGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepOutcomeCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/MStepOutcomeApi~apiMStepOutcomeGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepOutcomeGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepOutcomeCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/MStepOutcome', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepOutcomeGetAllByNameEntityIdEntityIdGet operation.
     * @callback moduleapi/MStepOutcomeApi~apiMStepOutcomeGetAllByNameEntityIdEntityIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepOutcomeCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} entityId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {module:api/MStepOutcomeApi~apiMStepOutcomeGetAllByNameEntityIdEntityIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepOutcomeGetAllByNameEntityIdEntityIdGet(entityId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'entityId' is set
      if (entityId === undefined || entityId === null) {
        throw new Error("Missing the required parameter 'entityId' when calling apiMStepOutcomeGetAllByNameEntityIdEntityIdGet");
      }

      let pathParams = {
        'entityId': entityId
      };
      let queryParams = {
        'name': opts['name']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepOutcomeCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/MStepOutcome/GetAllByNameEntityId/{entityId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepOutcomeGetAllByNameGet operation.
     * @callback moduleapi/MStepOutcomeApi~apiMStepOutcomeGetAllByNameGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepOutcomeFilterDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {module:api/MStepOutcomeApi~apiMStepOutcomeGetAllByNameGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepOutcomeGetAllByNameGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'name': opts['name']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepOutcomeFilterDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/MStepOutcome/GetAllByName', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepOutcomeGetAllByPageGet operation.
     * @callback moduleapi/MStepOutcomeApi~apiMStepOutcomeGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepOutcomeCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/MStepOutcomeApi~apiMStepOutcomeGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepOutcomeGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepOutcomeCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/MStepOutcome/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepOutcomeGetAllPageByEntityIdEntityIdGet operation.
     * @callback moduleapi/MStepOutcomeApi~apiMStepOutcomeGetAllPageByEntityIdEntityIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepOutcomeCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} entityId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/MStepOutcomeApi~apiMStepOutcomeGetAllPageByEntityIdEntityIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepOutcomeGetAllPageByEntityIdEntityIdGet(entityId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'entityId' is set
      if (entityId === undefined || entityId === null) {
        throw new Error("Missing the required parameter 'entityId' when calling apiMStepOutcomeGetAllPageByEntityIdEntityIdGet");
      }

      let pathParams = {
        'entityId': entityId
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepOutcomeCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/MStepOutcome/GetAllPageByEntityId/{entityId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepOutcomeGetAllStepOutcomeByMStepIdMStepIdGet operation.
     * @callback moduleapi/MStepOutcomeApi~apiMStepOutcomeGetAllStepOutcomeByMStepIdMStepIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepOutcomeCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} mStepId 
     * @param {module:api/MStepOutcomeApi~apiMStepOutcomeGetAllStepOutcomeByMStepIdMStepIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepOutcomeGetAllStepOutcomeByMStepIdMStepIdGet(mStepId, callback) {
      
      let postBody = null;
      // verify the required parameter 'mStepId' is set
      if (mStepId === undefined || mStepId === null) {
        throw new Error("Missing the required parameter 'mStepId' when calling apiMStepOutcomeGetAllStepOutcomeByMStepIdMStepIdGet");
      }

      let pathParams = {
        'mStepId': mStepId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepOutcomeCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/MStepOutcome/GetAllStepOutcomeByMStepId/{mStepId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepOutcomeGetByGuidGuidGet operation.
     * @callback moduleapi/MStepOutcomeApi~apiMStepOutcomeGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepOutcomeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/MStepOutcomeApi~apiMStepOutcomeGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepOutcomeGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiMStepOutcomeGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepOutcomeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MStepOutcome/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepOutcomeIdDelete operation.
     * @callback moduleapi/MStepOutcomeApi~apiMStepOutcomeIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepOutcomeCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/MStepOutcomeApi~apiMStepOutcomeIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepOutcomeIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiMStepOutcomeIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepOutcomeCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MStepOutcome/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepOutcomeIdGet operation.
     * @callback moduleapi/MStepOutcomeApi~apiMStepOutcomeIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepOutcomeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/MStepOutcomeApi~apiMStepOutcomeIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepOutcomeIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiMStepOutcomeIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepOutcomeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MStepOutcome/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepOutcomePost operation.
     * @callback moduleapi/MStepOutcomeApi~apiMStepOutcomePostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepOutcomeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/MStepOutcomeCreateDto} opts.body 
     * @param {module:api/MStepOutcomeApi~apiMStepOutcomePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepOutcomePost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepOutcomeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MStepOutcome', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMStepOutcomePut operation.
     * @callback moduleapi/MStepOutcomeApi~apiMStepOutcomePutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MStepOutcomeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/MStepOutcomeEditDto} opts.body 
     * @param {module:api/MStepOutcomeApi~apiMStepOutcomePutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMStepOutcomePut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MStepOutcomeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MStepOutcome', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}