/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {JdSkillPlatformCompactDtoApiResponse} from '../model/JdSkillPlatformCompactDtoApiResponse';
import {JdSkillPlatformCreateDto} from '../model/JdSkillPlatformCreateDto';
import {JdSkillPlatformDtoApiResponse} from '../model/JdSkillPlatformDtoApiResponse';
import {JdSkillPlatformEditDto} from '../model/JdSkillPlatformEditDto';

/**
* JdSkillPlatform service.
* @module api/JdSkillPlatformApi
* @version v3.76(UAT)
*/
export class JdSkillPlatformApi {

    /**
    * Constructs a new JdSkillPlatformApi. 
    * @alias module:api/JdSkillPlatformApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiJdSkillPlatformDelete operation.
     * @callback moduleapi/JdSkillPlatformApi~apiJdSkillPlatformDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JdSkillPlatformCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.id 
     * @param {module:api/JdSkillPlatformApi~apiJdSkillPlatformDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJdSkillPlatformDelete(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'id': opts['id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JdSkillPlatformCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JdSkillPlatform', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJdSkillPlatformPost operation.
     * @callback moduleapi/JdSkillPlatformApi~apiJdSkillPlatformPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JdSkillPlatformDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/JdSkillPlatformCreateDto} opts.body 
     * @param {module:api/JdSkillPlatformApi~apiJdSkillPlatformPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJdSkillPlatformPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JdSkillPlatformDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JdSkillPlatform', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiJdSkillPlatformPut operation.
     * @callback moduleapi/JdSkillPlatformApi~apiJdSkillPlatformPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/JdSkillPlatformDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/JdSkillPlatformEditDto} opts.body 
     * @param {module:api/JdSkillPlatformApi~apiJdSkillPlatformPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiJdSkillPlatformPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = JdSkillPlatformDtoApiResponse;

      return this.apiClient.callApi(
        '/api/JdSkillPlatform', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}