/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {MPathwayTypeCompactDtoIEnumerableApiResponse} from '../model/MPathwayTypeCompactDtoIEnumerableApiResponse';
import {MPathwayTypeCompactDtoPagedListApiResponse} from '../model/MPathwayTypeCompactDtoPagedListApiResponse';
import {MPathwayTypeCreateDto} from '../model/MPathwayTypeCreateDto';
import {MPathwayTypeDtoApiResponse} from '../model/MPathwayTypeDtoApiResponse';
import {MPathwayTypeDtoIEnumerableApiResponse} from '../model/MPathwayTypeDtoIEnumerableApiResponse';
import {MPathwayTypeEditDto} from '../model/MPathwayTypeEditDto';
import {MPathwayTypeFilterDtoIEnumerableApiResponse} from '../model/MPathwayTypeFilterDtoIEnumerableApiResponse';

/**
* MPathwayType service.
* @module api/MPathwayTypeApi
* @version v3.76(UAT)
*/
export class MPathwayTypeApi {

    /**
    * Constructs a new MPathwayTypeApi. 
    * @alias module:api/MPathwayTypeApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiMPathwayTypeGet operation.
     * @callback moduleapi/MPathwayTypeApi~apiMPathwayTypeGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayTypeCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/MPathwayTypeApi~apiMPathwayTypeGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayTypeGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayTypeCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayType', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayTypeGetAllByNameGet operation.
     * @callback moduleapi/MPathwayTypeApi~apiMPathwayTypeGetAllByNameGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayTypeFilterDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {module:api/MPathwayTypeApi~apiMPathwayTypeGetAllByNameGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayTypeGetAllByNameGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'name': opts['name']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayTypeFilterDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayType/GetAllByName', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayTypeGetAllByPageGet operation.
     * @callback moduleapi/MPathwayTypeApi~apiMPathwayTypeGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayTypeCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/MPathwayTypeApi~apiMPathwayTypeGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayTypeGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayTypeCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayType/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayTypeGetAllPathwayTypeGet operation.
     * @callback moduleapi/MPathwayTypeApi~apiMPathwayTypeGetAllPathwayTypeGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayTypeDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {Boolean} opts.isAdmin 
     * @param {module:api/MPathwayTypeApi~apiMPathwayTypeGetAllPathwayTypeGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayTypeGetAllPathwayTypeGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'name': opts['name'],'isAdmin': opts['isAdmin']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayTypeDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayType/GetAllPathwayType', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayTypeGetByGuidGuidGet operation.
     * @callback moduleapi/MPathwayTypeApi~apiMPathwayTypeGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayTypeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/MPathwayTypeApi~apiMPathwayTypeGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayTypeGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiMPathwayTypeGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayTypeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayType/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayTypeIdGet operation.
     * @callback moduleapi/MPathwayTypeApi~apiMPathwayTypeIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayTypeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/MPathwayTypeApi~apiMPathwayTypeIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayTypeIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiMPathwayTypeIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayTypeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayType/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayTypePost operation.
     * @callback moduleapi/MPathwayTypeApi~apiMPathwayTypePostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayTypeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/MPathwayTypeCreateDto} opts.body 
     * @param {module:api/MPathwayTypeApi~apiMPathwayTypePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayTypePost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayTypeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayType', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayTypePut operation.
     * @callback moduleapi/MPathwayTypeApi~apiMPathwayTypePutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayTypeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/MPathwayTypeEditDto} opts.body 
     * @param {module:api/MPathwayTypeApi~apiMPathwayTypePutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayTypePut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayTypeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayType', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}