/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {CandidateWebsiteCompactDtoApiResponse} from '../model/CandidateWebsiteCompactDtoApiResponse';
import {CandidateWebsiteCompactDtoIEnumerableApiResponse} from '../model/CandidateWebsiteCompactDtoIEnumerableApiResponse';
import {CandidateWebsiteCreateDto} from '../model/CandidateWebsiteCreateDto';
import {CandidateWebsiteDtoApiResponse} from '../model/CandidateWebsiteDtoApiResponse';
import {CandidateWebsiteEditDto} from '../model/CandidateWebsiteEditDto';
import {WebsiteType} from '../model/WebsiteType';

/**
* CandidateWebsite service.
* @module api/CandidateWebsiteApi
* @version v3.76(UAT)
*/
export class CandidateWebsiteApi {

    /**
    * Constructs a new CandidateWebsiteApi. 
    * @alias module:api/CandidateWebsiteApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiCandidateWebsiteGetAllByWebsiteTypeCandidateidGet operation.
     * @callback moduleapi/CandidateWebsiteApi~apiCandidateWebsiteGetAllByWebsiteTypeCandidateidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateWebsiteCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} candidateid 
     * @param {Object} opts Optional parameters
     * @param {module:model/WebsiteType} opts.websitetype   1 &#x3D; Link  2 &#x3D; SocialMedia
     * @param {module:api/CandidateWebsiteApi~apiCandidateWebsiteGetAllByWebsiteTypeCandidateidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateWebsiteGetAllByWebsiteTypeCandidateidGet(candidateid, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'candidateid' is set
      if (candidateid === undefined || candidateid === null) {
        throw new Error("Missing the required parameter 'candidateid' when calling apiCandidateWebsiteGetAllByWebsiteTypeCandidateidGet");
      }

      let pathParams = {
        'candidateid': candidateid
      };
      let queryParams = {
        'websitetype': opts['websitetype']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateWebsiteCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateWebsite/GetAllByWebsiteType/{candidateid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateWebsiteIdDelete operation.
     * @callback moduleapi/CandidateWebsiteApi~apiCandidateWebsiteIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateWebsiteCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/CandidateWebsiteApi~apiCandidateWebsiteIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateWebsiteIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiCandidateWebsiteIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateWebsiteCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateWebsite/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateWebsitePost operation.
     * @callback moduleapi/CandidateWebsiteApi~apiCandidateWebsitePostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateWebsiteDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateWebsiteCreateDto} opts.body 
     * @param {module:api/CandidateWebsiteApi~apiCandidateWebsitePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateWebsitePost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateWebsiteDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateWebsite', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCandidateWebsitePut operation.
     * @callback moduleapi/CandidateWebsiteApi~apiCandidateWebsitePutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CandidateWebsiteDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateWebsiteEditDto} opts.body 
     * @param {module:api/CandidateWebsiteApi~apiCandidateWebsitePutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiCandidateWebsitePut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CandidateWebsiteDtoApiResponse;

      return this.apiClient.callApi(
        '/api/CandidateWebsite', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}