/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {FunctionalAreaCompactDtoApiResponse} from '../model/FunctionalAreaCompactDtoApiResponse';
import {FunctionalAreaCompactDtoIEnumerableApiResponse} from '../model/FunctionalAreaCompactDtoIEnumerableApiResponse';
import {FunctionalAreaCompactDtoPagedListApiResponse} from '../model/FunctionalAreaCompactDtoPagedListApiResponse';
import {FunctionalAreaCreateDto} from '../model/FunctionalAreaCreateDto';
import {FunctionalAreaDtoApiResponse} from '../model/FunctionalAreaDtoApiResponse';
import {FunctionalAreaEditDto} from '../model/FunctionalAreaEditDto';

/**
* FunctionalArea service.
* @module api/FunctionalAreaApi
* @version v3.76(UAT)
*/
export class FunctionalAreaApi {

    /**
    * Constructs a new FunctionalAreaApi. 
    * @alias module:api/FunctionalAreaApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiFunctionalAreaGet operation.
     * @callback moduleapi/FunctionalAreaApi~apiFunctionalAreaGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FunctionalAreaCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/FunctionalAreaApi~apiFunctionalAreaGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFunctionalAreaGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FunctionalAreaCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/FunctionalArea', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFunctionalAreaGetAllByPageGet operation.
     * @callback moduleapi/FunctionalAreaApi~apiFunctionalAreaGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FunctionalAreaCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/FunctionalAreaApi~apiFunctionalAreaGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFunctionalAreaGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FunctionalAreaCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/FunctionalArea/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFunctionalAreaGetByGuidGuidGet operation.
     * @callback moduleapi/FunctionalAreaApi~apiFunctionalAreaGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FunctionalAreaDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/FunctionalAreaApi~apiFunctionalAreaGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFunctionalAreaGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiFunctionalAreaGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FunctionalAreaDtoApiResponse;

      return this.apiClient.callApi(
        '/api/FunctionalArea/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFunctionalAreaIdDelete operation.
     * @callback moduleapi/FunctionalAreaApi~apiFunctionalAreaIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FunctionalAreaCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/FunctionalAreaApi~apiFunctionalAreaIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFunctionalAreaIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiFunctionalAreaIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FunctionalAreaCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/FunctionalArea/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFunctionalAreaIdGet operation.
     * @callback moduleapi/FunctionalAreaApi~apiFunctionalAreaIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FunctionalAreaDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/FunctionalAreaApi~apiFunctionalAreaIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFunctionalAreaIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiFunctionalAreaIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FunctionalAreaDtoApiResponse;

      return this.apiClient.callApi(
        '/api/FunctionalArea/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFunctionalAreaPost operation.
     * @callback moduleapi/FunctionalAreaApi~apiFunctionalAreaPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FunctionalAreaDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/FunctionalAreaCreateDto} opts.body 
     * @param {module:api/FunctionalAreaApi~apiFunctionalAreaPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFunctionalAreaPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FunctionalAreaDtoApiResponse;

      return this.apiClient.callApi(
        '/api/FunctionalArea', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFunctionalAreaPut operation.
     * @callback moduleapi/FunctionalAreaApi~apiFunctionalAreaPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FunctionalAreaDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/FunctionalAreaEditDto} opts.body 
     * @param {module:api/FunctionalAreaApi~apiFunctionalAreaPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFunctionalAreaPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FunctionalAreaDtoApiResponse;

      return this.apiClient.callApi(
        '/api/FunctionalArea', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}