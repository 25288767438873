/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Certification} from './Certification';
import {CompanyDuration} from './CompanyDuration';
import {ContactInfo} from './ContactInfo';
import {Education} from './Education';
import {Hobby} from './Hobby';
import {Overview} from './Overview';
import {ProjectDetail} from './ProjectDetail';
import {Skill} from './Skill';
import {SoftSkill} from './SoftSkill';
import {Trait} from './Trait';
import {Website} from './Website';

/**
 * The ParsedData model module.
 * @module model/ParsedData
 * @version v3.76(UAT)
 */
export class ParsedData {
  /**
   * Constructs a new <code>ParsedData</code>.
   * @alias module:model/ParsedData
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ParsedData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ParsedData} obj Optional instance to populate.
   * @return {module:model/ParsedData} The populated <code>ParsedData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ParsedData();
      if (data.hasOwnProperty('overview'))
        obj.overview = Overview.constructFromObject(data['overview']);
      if (data.hasOwnProperty('traits'))
        obj.traits = ApiClient.convertToType(data['traits'], [Trait]);
      if (data.hasOwnProperty('education'))
        obj.education = ApiClient.convertToType(data['education'], [Education]);
      if (data.hasOwnProperty('contactInfo'))
        obj.contactInfo = ContactInfo.constructFromObject(data['contactInfo']);
      if (data.hasOwnProperty('certifications'))
        obj.certifications = ApiClient.convertToType(data['certifications'], [Certification]);
      if (data.hasOwnProperty('skills'))
        obj.skills = ApiClient.convertToType(data['skills'], [Skill]);
      if (data.hasOwnProperty('projectDetails'))
        obj.projectDetails = ApiClient.convertToType(data['projectDetails'], [ProjectDetail]);
      if (data.hasOwnProperty('candidateId'))
        obj.candidateId = ApiClient.convertToType(data['candidateId'], 'Number');
      if (data.hasOwnProperty('softSkills'))
        obj.softSkills = ApiClient.convertToType(data['softSkills'], [SoftSkill]);
      if (data.hasOwnProperty('hobbies'))
        obj.hobbies = ApiClient.convertToType(data['hobbies'], [Hobby]);
      if (data.hasOwnProperty('companiesDuration'))
        obj.companiesDuration = ApiClient.convertToType(data['companiesDuration'], [CompanyDuration]);
      if (data.hasOwnProperty('websites'))
        obj.websites = Website.constructFromObject(data['websites']);
      if (data.hasOwnProperty('requestId'))
        obj.requestId = ApiClient.convertToType(data['requestId'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {module:model/Overview} overview
 */
ParsedData.prototype.overview = undefined;

/**
 * @member {Array.<module:model/Trait>} traits
 */
ParsedData.prototype.traits = undefined;

/**
 * @member {Array.<module:model/Education>} education
 */
ParsedData.prototype.education = undefined;

/**
 * @member {module:model/ContactInfo} contactInfo
 */
ParsedData.prototype.contactInfo = undefined;

/**
 * @member {Array.<module:model/Certification>} certifications
 */
ParsedData.prototype.certifications = undefined;

/**
 * @member {Array.<module:model/Skill>} skills
 */
ParsedData.prototype.skills = undefined;

/**
 * @member {Array.<module:model/ProjectDetail>} projectDetails
 */
ParsedData.prototype.projectDetails = undefined;

/**
 * @member {Number} candidateId
 */
ParsedData.prototype.candidateId = undefined;

/**
 * @member {Array.<module:model/SoftSkill>} softSkills
 */
ParsedData.prototype.softSkills = undefined;

/**
 * @member {Array.<module:model/Hobby>} hobbies
 */
ParsedData.prototype.hobbies = undefined;

/**
 * @member {Array.<module:model/CompanyDuration>} companiesDuration
 */
ParsedData.prototype.companiesDuration = undefined;

/**
 * @member {module:model/Website} websites
 */
ParsedData.prototype.websites = undefined;

/**
 * @member {Number} requestId
 */
ParsedData.prototype.requestId = undefined;

