/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {MPathwayOutcomeAttributeCompactDtoApiResponse} from '../model/MPathwayOutcomeAttributeCompactDtoApiResponse';
import {MPathwayOutcomeAttributeCompactDtoIEnumerableApiResponse} from '../model/MPathwayOutcomeAttributeCompactDtoIEnumerableApiResponse';
import {MPathwayOutcomeAttributeCompactDtoPagedListApiResponse} from '../model/MPathwayOutcomeAttributeCompactDtoPagedListApiResponse';
import {MPathwayOutcomeAttributeCreateDto} from '../model/MPathwayOutcomeAttributeCreateDto';
import {MPathwayOutcomeAttributeDtoApiResponse} from '../model/MPathwayOutcomeAttributeDtoApiResponse';
import {MPathwayOutcomeAttributeEditDto} from '../model/MPathwayOutcomeAttributeEditDto';

/**
* MPathwayOutcomeAttribute service.
* @module api/MPathwayOutcomeAttributeApi
* @version v3.76(UAT)
*/
export class MPathwayOutcomeAttributeApi {

    /**
    * Constructs a new MPathwayOutcomeAttributeApi. 
    * @alias module:api/MPathwayOutcomeAttributeApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiMPathwayOutcomeAttributeGet operation.
     * @callback moduleapi/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayOutcomeAttributeCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayOutcomeAttributeGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayOutcomeAttributeCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayOutcomeAttribute', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayOutcomeAttributeGetAllByPageGet operation.
     * @callback moduleapi/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayOutcomeAttributeCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayOutcomeAttributeGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayOutcomeAttributeCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayOutcomeAttribute/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayOutcomeAttributeGetAllPageByEntityGuidEntityIdGet operation.
     * @callback moduleapi/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeGetAllPageByEntityGuidEntityIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayOutcomeAttributeCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} entityId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeGetAllPageByEntityGuidEntityIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayOutcomeAttributeGetAllPageByEntityGuidEntityIdGet(entityId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'entityId' is set
      if (entityId === undefined || entityId === null) {
        throw new Error("Missing the required parameter 'entityId' when calling apiMPathwayOutcomeAttributeGetAllPageByEntityGuidEntityIdGet");
      }

      let pathParams = {
        'entityId': entityId
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayOutcomeAttributeCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayOutcomeAttribute/GetAllPageByEntityGuid/{entityId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayOutcomeAttributeGetAllPathwayOutcomeAttributeByPathwayOutcomeIdPathwayOutcomeIdGet operation.
     * @callback moduleapi/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeGetAllPathwayOutcomeAttributeByPathwayOutcomeIdPathwayOutcomeIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayOutcomeAttributeCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} pathwayOutcomeId 
     * @param {module:api/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeGetAllPathwayOutcomeAttributeByPathwayOutcomeIdPathwayOutcomeIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayOutcomeAttributeGetAllPathwayOutcomeAttributeByPathwayOutcomeIdPathwayOutcomeIdGet(pathwayOutcomeId, callback) {
      
      let postBody = null;
      // verify the required parameter 'pathwayOutcomeId' is set
      if (pathwayOutcomeId === undefined || pathwayOutcomeId === null) {
        throw new Error("Missing the required parameter 'pathwayOutcomeId' when calling apiMPathwayOutcomeAttributeGetAllPathwayOutcomeAttributeByPathwayOutcomeIdPathwayOutcomeIdGet");
      }

      let pathParams = {
        'pathwayOutcomeId': pathwayOutcomeId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayOutcomeAttributeCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayOutcomeAttribute/GetAllPathwayOutcomeAttributeByPathwayOutcomeId/{pathwayOutcomeId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayOutcomeAttributeGetByGuidGuidGet operation.
     * @callback moduleapi/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayOutcomeAttributeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayOutcomeAttributeGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiMPathwayOutcomeAttributeGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayOutcomeAttributeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayOutcomeAttribute/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayOutcomeAttributeIdDelete operation.
     * @callback moduleapi/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayOutcomeAttributeCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayOutcomeAttributeIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiMPathwayOutcomeAttributeIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayOutcomeAttributeCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayOutcomeAttribute/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayOutcomeAttributeIdGet operation.
     * @callback moduleapi/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayOutcomeAttributeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributeIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayOutcomeAttributeIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiMPathwayOutcomeAttributeIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayOutcomeAttributeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayOutcomeAttribute/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayOutcomeAttributePost operation.
     * @callback moduleapi/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributePostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayOutcomeAttributeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/MPathwayOutcomeAttributeCreateDto} opts.body 
     * @param {module:api/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayOutcomeAttributePost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayOutcomeAttributeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayOutcomeAttribute', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiMPathwayOutcomeAttributePut operation.
     * @callback moduleapi/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributePutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MPathwayOutcomeAttributeDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/MPathwayOutcomeAttributeEditDto} opts.body 
     * @param {module:api/MPathwayOutcomeAttributeApi~apiMPathwayOutcomeAttributePutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiMPathwayOutcomeAttributePut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MPathwayOutcomeAttributeDtoApiResponse;

      return this.apiClient.callApi(
        '/api/MPathwayOutcomeAttribute', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}