/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ApiClientContractBody1 model module.
 * @module model/ApiClientContractBody1
 * @version v3.76(UAT)
 */
export class ApiClientContractBody1 {
  /**
   * Constructs a new <code>ApiClientContractBody1</code>.
   * @alias module:model/ApiClientContractBody1
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ApiClientContractBody1</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ApiClientContractBody1} obj Optional instance to populate.
   * @return {module:model/ApiClientContractBody1} The populated <code>ApiClientContractBody1</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ApiClientContractBody1();
      if (data.hasOwnProperty('StartDate'))
        obj.startDate = ApiClient.convertToType(data['StartDate'], 'Date');
      if (data.hasOwnProperty('EndDate'))
        obj.endDate = ApiClient.convertToType(data['EndDate'], 'Date');
      if (data.hasOwnProperty('ApprovedBy'))
        obj.approvedBy = ApiClient.convertToType(data['ApprovedBy'], 'String');
      if (data.hasOwnProperty('ApprovedByUserId'))
        obj.approvedByUserId = ApiClient.convertToType(data['ApprovedByUserId'], 'Number');
      if (data.hasOwnProperty('CreatedBy'))
        obj.createdBy = ApiClient.convertToType(data['CreatedBy'], 'String');
      if (data.hasOwnProperty('CreatedByUserId'))
        obj.createdByUserId = ApiClient.convertToType(data['CreatedByUserId'], 'Number');
      if (data.hasOwnProperty('DateOfInvoice'))
        obj.dateOfInvoice = ApiClient.convertToType(data['DateOfInvoice'], 'Date');
      if (data.hasOwnProperty('uploadedFile'))
        obj.uploadedFile = ApiClient.convertToType(data['uploadedFile'], 'Blob');
      if (data.hasOwnProperty('ClientId'))
        obj.clientId = ApiClient.convertToType(data['ClientId'], 'Number');
      if (data.hasOwnProperty('CreatedDate'))
        obj.createdDate = ApiClient.convertToType(data['CreatedDate'], 'Date');
    }
    return obj;
  }
}

/**
 * @member {Date} startDate
 */
ApiClientContractBody1.prototype.startDate = undefined;

/**
 * @member {Date} endDate
 */
ApiClientContractBody1.prototype.endDate = undefined;

/**
 * @member {String} approvedBy
 */
ApiClientContractBody1.prototype.approvedBy = undefined;

/**
 * @member {Number} approvedByUserId
 */
ApiClientContractBody1.prototype.approvedByUserId = undefined;

/**
 * @member {String} createdBy
 */
ApiClientContractBody1.prototype.createdBy = undefined;

/**
 * @member {Number} createdByUserId
 */
ApiClientContractBody1.prototype.createdByUserId = undefined;

/**
 * @member {Date} dateOfInvoice
 */
ApiClientContractBody1.prototype.dateOfInvoice = undefined;

/**
 * @member {Blob} uploadedFile
 */
ApiClientContractBody1.prototype.uploadedFile = undefined;

/**
 * @member {Number} clientId
 */
ApiClientContractBody1.prototype.clientId = undefined;

/**
 * @member {Date} createdDate
 */
ApiClientContractBody1.prototype.createdDate = undefined;

