/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {InterviewStatus} from './InterviewStatus';
import {JDClientInterviewRoundPanelDto} from './JDClientInterviewRoundPanelDto';

/**
 * The JdClientInterviewRoundsEditDto model module.
 * @module model/JdClientInterviewRoundsEditDto
 * @version v3.76(UAT)
 */
export class JdClientInterviewRoundsEditDto {
  /**
   * Constructs a new <code>JdClientInterviewRoundsEditDto</code>.
   * @alias module:model/JdClientInterviewRoundsEditDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>JdClientInterviewRoundsEditDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/JdClientInterviewRoundsEditDto} obj Optional instance to populate.
   * @return {module:model/JdClientInterviewRoundsEditDto} The populated <code>JdClientInterviewRoundsEditDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new JdClientInterviewRoundsEditDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('date'))
        obj._date = ApiClient.convertToType(data['date'], 'Date');
      if (data.hasOwnProperty('status'))
        obj.status = InterviewStatus.constructFromObject(data['status']);
      if (data.hasOwnProperty('roundName'))
        obj.roundName = ApiClient.convertToType(data['roundName'], 'String');
      if (data.hasOwnProperty('remarks'))
        obj.remarks = ApiClient.convertToType(data['remarks'], 'String');
      if (data.hasOwnProperty('mode'))
        obj.mode = ApiClient.convertToType(data['mode'], 'String');
      if (data.hasOwnProperty('link'))
        obj.link = ApiClient.convertToType(data['link'], 'String');
      if (data.hasOwnProperty('addressId'))
        obj.addressId = ApiClient.convertToType(data['addressId'], 'Number');
      if (data.hasOwnProperty('time'))
        obj.time = ApiClient.convertToType(data['time'], 'Date');
      if (data.hasOwnProperty('duration'))
        obj.duration = ApiClient.convertToType(data['duration'], 'String');
      if (data.hasOwnProperty('jdClientInterviewId'))
        obj.jdClientInterviewId = ApiClient.convertToType(data['jdClientInterviewId'], 'Number');
      if (data.hasOwnProperty('jdClientInterviewRoundPanels'))
        obj.jdClientInterviewRoundPanels = ApiClient.convertToType(data['jdClientInterviewRoundPanels'], [JDClientInterviewRoundPanelDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
JdClientInterviewRoundsEditDto.prototype.id = undefined;

/**
 * @member {Date} _date
 */
JdClientInterviewRoundsEditDto.prototype._date = undefined;

/**
 * @member {module:model/InterviewStatus} status
 */
JdClientInterviewRoundsEditDto.prototype.status = undefined;

/**
 * @member {String} roundName
 */
JdClientInterviewRoundsEditDto.prototype.roundName = undefined;

/**
 * @member {String} remarks
 */
JdClientInterviewRoundsEditDto.prototype.remarks = undefined;

/**
 * @member {String} mode
 */
JdClientInterviewRoundsEditDto.prototype.mode = undefined;

/**
 * @member {String} link
 */
JdClientInterviewRoundsEditDto.prototype.link = undefined;

/**
 * @member {Number} addressId
 */
JdClientInterviewRoundsEditDto.prototype.addressId = undefined;

/**
 * @member {Date} time
 */
JdClientInterviewRoundsEditDto.prototype.time = undefined;

/**
 * @member {String} duration
 */
JdClientInterviewRoundsEditDto.prototype.duration = undefined;

/**
 * @member {Number} jdClientInterviewId
 */
JdClientInterviewRoundsEditDto.prototype.jdClientInterviewId = undefined;

/**
 * @member {Array.<module:model/JDClientInterviewRoundPanelDto>} jdClientInterviewRoundPanels
 */
JdClientInterviewRoundsEditDto.prototype.jdClientInterviewRoundPanels = undefined;

