/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {ParserDocumentCompactDtoApiResponse} from '../model/ParserDocumentCompactDtoApiResponse';
import {ParserDocumentCompactDtoIEnumerableApiResponse} from '../model/ParserDocumentCompactDtoIEnumerableApiResponse';
import {ParserDocumentCompactDtoPagedListApiResponse} from '../model/ParserDocumentCompactDtoPagedListApiResponse';
import {ParserDocumentDtoApiResponse} from '../model/ParserDocumentDtoApiResponse';
import {ParserDocumentEditDto} from '../model/ParserDocumentEditDto';

/**
* ParserDocument service.
* @module api/ParserDocumentApi
* @version v3.76(UAT)
*/
export class ParserDocumentApi {

    /**
    * Constructs a new ParserDocumentApi. 
    * @alias module:api/ParserDocumentApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiParserDocumentGet operation.
     * @callback moduleapi/ParserDocumentApi~apiParserDocumentGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ParserDocumentCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/ParserDocumentApi~apiParserDocumentGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiParserDocumentGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ParserDocumentCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/ParserDocument', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiParserDocumentGetAllByPageGet operation.
     * @callback moduleapi/ParserDocumentApi~apiParserDocumentGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ParserDocumentCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/ParserDocumentApi~apiParserDocumentGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiParserDocumentGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ParserDocumentCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/ParserDocument/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiParserDocumentGetByGuidGuidGet operation.
     * @callback moduleapi/ParserDocumentApi~apiParserDocumentGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ParserDocumentDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/ParserDocumentApi~apiParserDocumentGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiParserDocumentGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiParserDocumentGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ParserDocumentDtoApiResponse;

      return this.apiClient.callApi(
        '/api/ParserDocument/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiParserDocumentIdDelete operation.
     * @callback moduleapi/ParserDocumentApi~apiParserDocumentIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ParserDocumentCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/ParserDocumentApi~apiParserDocumentIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiParserDocumentIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiParserDocumentIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ParserDocumentCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/ParserDocument/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiParserDocumentIdGet operation.
     * @callback moduleapi/ParserDocumentApi~apiParserDocumentIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ParserDocumentDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/ParserDocumentApi~apiParserDocumentIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiParserDocumentIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiParserDocumentIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ParserDocumentDtoApiResponse;

      return this.apiClient.callApi(
        '/api/ParserDocument/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiParserDocumentParseRChilliJSONPost operation.
     * @callback moduleapi/ParserDocumentApi~apiParserDocumentParseRChilliJSONPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ParserDocumentDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Blob} opts.uploadedFile 
     * @param {module:api/ParserDocumentApi~apiParserDocumentParseRChilliJSONPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiParserDocumentParseRChilliJSONPost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'uploadedFile': opts['uploadedFile']
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ParserDocumentDtoApiResponse;

      return this.apiClient.callApi(
        '/api/ParserDocument/ParseRChilliJSON', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiParserDocumentPost operation.
     * @callback moduleapi/ParserDocumentApi~apiParserDocumentPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ParserDocumentDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Blob} opts.uploadedFile 
     * @param {module:api/ParserDocumentApi~apiParserDocumentPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiParserDocumentPost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'uploadedFile': opts['uploadedFile']
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ParserDocumentDtoApiResponse;

      return this.apiClient.callApi(
        '/api/ParserDocument', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiParserDocumentPut operation.
     * @callback moduleapi/ParserDocumentApi~apiParserDocumentPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ParserDocumentDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/ParserDocumentEditDto} opts.body 
     * @param {module:api/ParserDocumentApi~apiParserDocumentPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiParserDocumentPut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ParserDocumentDtoApiResponse;

      return this.apiClient.callApi(
        '/api/ParserDocument', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}