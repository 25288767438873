/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The Website model module.
 * @module model/Website
 * @version v3.76(UAT)
 */
export class Website {
  /**
   * Constructs a new <code>Website</code>.
   * @alias module:model/Website
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>Website</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Website} obj Optional instance to populate.
   * @return {module:model/Website} The populated <code>Website</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Website();
      if (data.hasOwnProperty('github'))
        obj.github = ApiClient.convertToType(data['github'], 'String');
      if (data.hasOwnProperty('twitter'))
        obj.twitter = ApiClient.convertToType(data['twitter'], 'String');
      if (data.hasOwnProperty('linkedIn'))
        obj.linkedIn = ApiClient.convertToType(data['linkedIn'], 'String');
      if (data.hasOwnProperty('instagram'))
        obj.instagram = ApiClient.convertToType(data['instagram'], 'String');
      if (data.hasOwnProperty('others'))
        obj.others = ApiClient.convertToType(data['others'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} github
 */
Website.prototype.github = undefined;

/**
 * @member {String} twitter
 */
Website.prototype.twitter = undefined;

/**
 * @member {String} linkedIn
 */
Website.prototype.linkedIn = undefined;

/**
 * @member {String} instagram
 */
Website.prototype.instagram = undefined;

/**
 * @member {String} others
 */
Website.prototype.others = undefined;

