/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CandidateAndAddressDto} from './CandidateAndAddressDto';
import {CandidateAndLanguageDto} from './CandidateAndLanguageDto';
import {CandidateCertificateDto} from './CandidateCertificateDto';
import {CandidateEmailDto} from './CandidateEmailDto';
import {CandidatePhoneDto} from './CandidatePhoneDto';
import {CandidateSkillDto} from './CandidateSkillDto';
import {CandidateTraitDto} from './CandidateTraitDto';
import {CandidateWebsiteDto} from './CandidateWebsiteDto';
import {DocumentDto} from './DocumentDto';
import {EducationInstituteDto} from './EducationInstituteDto';
import {EmployerDto} from './EmployerDto';
import {EmploymentStatusType} from './EmploymentStatusType';
import {GenderType} from './GenderType';
import {NormalizationStatus} from './NormalizationStatus';
import {ProjectDto} from './ProjectDto';
import {QualificationDto} from './QualificationDto';
import {SalaryDto} from './SalaryDto';

/**
 * The CandidateDto model module.
 * @module model/CandidateDto
 * @version v3.76(UAT)
 */
export class CandidateDto {
  /**
   * Constructs a new <code>CandidateDto</code>.
   * @alias module:model/CandidateDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CandidateDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CandidateDto} obj Optional instance to populate.
   * @return {module:model/CandidateDto} The populated <code>CandidateDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CandidateDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('normalizationStatus'))
        obj.normalizationStatus = NormalizationStatus.constructFromObject(data['normalizationStatus']);
      if (data.hasOwnProperty('skillNormalizationStatus'))
        obj.skillNormalizationStatus = NormalizationStatus.constructFromObject(data['skillNormalizationStatus']);
      if (data.hasOwnProperty('projectNormalizationStatus'))
        obj.projectNormalizationStatus = NormalizationStatus.constructFromObject(data['projectNormalizationStatus']);
      if (data.hasOwnProperty('candidateCode'))
        obj.candidateCode = ApiClient.convertToType(data['candidateCode'], 'String');
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
      if (data.hasOwnProperty('contactNumber'))
        obj.contactNumber = ApiClient.convertToType(data['contactNumber'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = ApiClient.convertToType(data['status'], 'String');
      if (data.hasOwnProperty('slug'))
        obj.slug = ApiClient.convertToType(data['slug'], 'String');
      if (data.hasOwnProperty('isFresher'))
        obj.isFresher = ApiClient.convertToType(data['isFresher'], 'Boolean');
      if (data.hasOwnProperty('profileHeadLine'))
        obj.profileHeadLine = ApiClient.convertToType(data['profileHeadLine'], 'String');
      if (data.hasOwnProperty('about'))
        obj.about = ApiClient.convertToType(data['about'], 'String');
      if (data.hasOwnProperty('isAttachedtoJD'))
        obj.isAttachedtoJD = ApiClient.convertToType(data['isAttachedtoJD'], 'Boolean');
      if (data.hasOwnProperty('isStudent'))
        obj.isStudent = ApiClient.convertToType(data['isStudent'], 'Boolean');
      if (data.hasOwnProperty('primaryEducationInstituteId'))
        obj.primaryEducationInstituteId = ApiClient.convertToType(data['primaryEducationInstituteId'], 'Number');
      if (data.hasOwnProperty('primaryEducationInstitute'))
        obj.primaryEducationInstitute = EducationInstituteDto.constructFromObject(data['primaryEducationInstitute']);
      if (data.hasOwnProperty('profileImageId'))
        obj.profileImageId = ApiClient.convertToType(data['profileImageId'], 'Number');
      if (data.hasOwnProperty('profileImage'))
        obj.profileImage = DocumentDto.constructFromObject(data['profileImage']);
      if (data.hasOwnProperty('resumeId'))
        obj.resumeId = ApiClient.convertToType(data['resumeId'], 'Number');
      if (data.hasOwnProperty('resume'))
        obj.resume = DocumentDto.constructFromObject(data['resume']);
      if (data.hasOwnProperty('backGroundImageId'))
        obj.backGroundImageId = ApiClient.convertToType(data['backGroundImageId'], 'Number');
      if (data.hasOwnProperty('backGroundImage'))
        obj.backGroundImage = DocumentDto.constructFromObject(data['backGroundImage']);
      if (data.hasOwnProperty('collegeImageId'))
        obj.collegeImageId = ApiClient.convertToType(data['collegeImageId'], 'Number');
      if (data.hasOwnProperty('collegeImage'))
        obj.collegeImage = DocumentDto.constructFromObject(data['collegeImage']);
      if (data.hasOwnProperty('employmentStatus'))
        obj.employmentStatus = EmploymentStatusType.constructFromObject(data['employmentStatus']);
      if (data.hasOwnProperty('firstName'))
        obj.firstName = ApiClient.convertToType(data['firstName'], 'String');
      if (data.hasOwnProperty('middleName'))
        obj.middleName = ApiClient.convertToType(data['middleName'], 'String');
      if (data.hasOwnProperty('lastName'))
        obj.lastName = ApiClient.convertToType(data['lastName'], 'String');
      if (data.hasOwnProperty('fullName'))
        obj.fullName = ApiClient.convertToType(data['fullName'], 'String');
      if (data.hasOwnProperty('hobbiesAndInterests'))
        obj.hobbiesAndInterests = ApiClient.convertToType(data['hobbiesAndInterests'], 'String');
      if (data.hasOwnProperty('strengths'))
        obj.strengths = ApiClient.convertToType(data['strengths'], 'String');
      if (data.hasOwnProperty('dateOfBirth'))
        obj.dateOfBirth = ApiClient.convertToType(data['dateOfBirth'], 'Date');
      if (data.hasOwnProperty('gender'))
        obj.gender = GenderType.constructFromObject(data['gender']);
      if (data.hasOwnProperty('bloodGroup'))
        obj.bloodGroup = ApiClient.convertToType(data['bloodGroup'], 'String');
      if (data.hasOwnProperty('fatherName'))
        obj.fatherName = ApiClient.convertToType(data['fatherName'], 'String');
      if (data.hasOwnProperty('motherName'))
        obj.motherName = ApiClient.convertToType(data['motherName'], 'String');
      if (data.hasOwnProperty('maritalStatus'))
        obj.maritalStatus = ApiClient.convertToType(data['maritalStatus'], 'String');
      if (data.hasOwnProperty('candidateLanguages'))
        obj.candidateLanguages = ApiClient.convertToType(data['candidateLanguages'], [CandidateAndLanguageDto]);
      if (data.hasOwnProperty('candidateEmails'))
        obj.candidateEmails = ApiClient.convertToType(data['candidateEmails'], [CandidateEmailDto]);
      if (data.hasOwnProperty('candidatePhones'))
        obj.candidatePhones = ApiClient.convertToType(data['candidatePhones'], [CandidatePhoneDto]);
      if (data.hasOwnProperty('candidateWebsites'))
        obj.candidateWebsites = ApiClient.convertToType(data['candidateWebsites'], [CandidateWebsiteDto]);
      if (data.hasOwnProperty('candidateAddresses'))
        obj.candidateAddresses = ApiClient.convertToType(data['candidateAddresses'], [CandidateAndAddressDto]);
      if (data.hasOwnProperty('nationality'))
        obj.nationality = ApiClient.convertToType(data['nationality'], 'String');
      if (data.hasOwnProperty('qualifications'))
        obj.qualifications = ApiClient.convertToType(data['qualifications'], [QualificationDto]);
      if (data.hasOwnProperty('employers'))
        obj.employers = ApiClient.convertToType(data['employers'], [EmployerDto]);
      if (data.hasOwnProperty('projects'))
        obj.projects = ApiClient.convertToType(data['projects'], [ProjectDto]);
      if (data.hasOwnProperty('note'))
        obj.note = ApiClient.convertToType(data['note'], 'String');
      if (data.hasOwnProperty('isMentor'))
        obj.isMentor = ApiClient.convertToType(data['isMentor'], 'Boolean');
      if (data.hasOwnProperty('isApplicableForJDSearch'))
        obj.isApplicableForJDSearch = ApiClient.convertToType(data['isApplicableForJDSearch'], 'Boolean');
      if (data.hasOwnProperty('currentEmployer'))
        obj.currentEmployer = ApiClient.convertToType(data['currentEmployer'], 'String');
      if (data.hasOwnProperty('totalExperienceInMonths'))
        obj.totalExperienceInMonths = ApiClient.convertToType(data['totalExperienceInMonths'], 'String');
      if (data.hasOwnProperty('totalExperienceInYears'))
        obj.totalExperienceInYears = ApiClient.convertToType(data['totalExperienceInYears'], 'Number');
      if (data.hasOwnProperty('totalExperienceRange'))
        obj.totalExperienceRange = ApiClient.convertToType(data['totalExperienceRange'], 'String');
      if (data.hasOwnProperty('gapPeriod'))
        obj.gapPeriod = ApiClient.convertToType(data['gapPeriod'], 'String');
      if (data.hasOwnProperty('averageStay'))
        obj.averageStay = ApiClient.convertToType(data['averageStay'], 'String');
      if (data.hasOwnProperty('longestStay'))
        obj.longestStay = ApiClient.convertToType(data['longestStay'], 'String');
      if (data.hasOwnProperty('summary'))
        obj.summary = ApiClient.convertToType(data['summary'], 'String');
      if (data.hasOwnProperty('executiveSummary'))
        obj.executiveSummary = ApiClient.convertToType(data['executiveSummary'], 'String');
      if (data.hasOwnProperty('managementSummary'))
        obj.managementSummary = ApiClient.convertToType(data['managementSummary'], 'String');
      if (data.hasOwnProperty('currentDesignation'))
        obj.currentDesignation = ApiClient.convertToType(data['currentDesignation'], 'String');
      if (data.hasOwnProperty('noticePeriodDuration'))
        obj.noticePeriodDuration = ApiClient.convertToType(data['noticePeriodDuration'], 'String');
      if (data.hasOwnProperty('currentSalaryId'))
        obj.currentSalaryId = ApiClient.convertToType(data['currentSalaryId'], 'Number');
      if (data.hasOwnProperty('currentSalary'))
        obj.currentSalary = SalaryDto.constructFromObject(data['currentSalary']);
      if (data.hasOwnProperty('expectedSalaryId'))
        obj.expectedSalaryId = ApiClient.convertToType(data['expectedSalaryId'], 'Number');
      if (data.hasOwnProperty('expectedSalary'))
        obj.expectedSalary = SalaryDto.constructFromObject(data['expectedSalary']);
      if (data.hasOwnProperty('skills'))
        obj.skills = ApiClient.convertToType(data['skills'], [CandidateSkillDto]);
      if (data.hasOwnProperty('personalTotal'))
        obj.personalTotal = ApiClient.convertToType(data['personalTotal'], 'Number');
      if (data.hasOwnProperty('personalFilled'))
        obj.personalFilled = ApiClient.convertToType(data['personalFilled'], 'Number');
      if (data.hasOwnProperty('personalCompletionPercentage'))
        obj.personalCompletionPercentage = ApiClient.convertToType(data['personalCompletionPercentage'], 'Number');
      if (data.hasOwnProperty('contactDetailsTotal'))
        obj.contactDetailsTotal = ApiClient.convertToType(data['contactDetailsTotal'], 'Number');
      if (data.hasOwnProperty('contactDetailsFilled'))
        obj.contactDetailsFilled = ApiClient.convertToType(data['contactDetailsFilled'], 'Number');
      if (data.hasOwnProperty('contactDetailsCompletionPercentage'))
        obj.contactDetailsCompletionPercentage = ApiClient.convertToType(data['contactDetailsCompletionPercentage'], 'Number');
      if (data.hasOwnProperty('qualificationsTotal'))
        obj.qualificationsTotal = ApiClient.convertToType(data['qualificationsTotal'], 'Number');
      if (data.hasOwnProperty('qualificationsFilled'))
        obj.qualificationsFilled = ApiClient.convertToType(data['qualificationsFilled'], 'Number');
      if (data.hasOwnProperty('qualificationsCompletionPercentage'))
        obj.qualificationsCompletionPercentage = ApiClient.convertToType(data['qualificationsCompletionPercentage'], 'Number');
      if (data.hasOwnProperty('companyDetailsTotal'))
        obj.companyDetailsTotal = ApiClient.convertToType(data['companyDetailsTotal'], 'Number');
      if (data.hasOwnProperty('companyDetailsFilled'))
        obj.companyDetailsFilled = ApiClient.convertToType(data['companyDetailsFilled'], 'Number');
      if (data.hasOwnProperty('companyDetailsCompletionPercentage'))
        obj.companyDetailsCompletionPercentage = ApiClient.convertToType(data['companyDetailsCompletionPercentage'], 'Number');
      if (data.hasOwnProperty('skillsTotal'))
        obj.skillsTotal = ApiClient.convertToType(data['skillsTotal'], 'Number');
      if (data.hasOwnProperty('skillsFilled'))
        obj.skillsFilled = ApiClient.convertToType(data['skillsFilled'], 'Number');
      if (data.hasOwnProperty('skillsCompletionPercentage'))
        obj.skillsCompletionPercentage = ApiClient.convertToType(data['skillsCompletionPercentage'], 'Number');
      if (data.hasOwnProperty('profileCompletionTotal'))
        obj.profileCompletionTotal = ApiClient.convertToType(data['profileCompletionTotal'], 'Number');
      if (data.hasOwnProperty('profileCompletionFilled'))
        obj.profileCompletionFilled = ApiClient.convertToType(data['profileCompletionFilled'], 'Number');
      if (data.hasOwnProperty('profileCompletionPercentage'))
        obj.profileCompletionPercentage = ApiClient.convertToType(data['profileCompletionPercentage'], 'Number');
      if (data.hasOwnProperty('certificates'))
        obj.certificates = ApiClient.convertToType(data['certificates'], [CandidateCertificateDto]);
      if (data.hasOwnProperty('candidateTraits'))
        obj.candidateTraits = ApiClient.convertToType(data['candidateTraits'], [CandidateTraitDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
CandidateDto.prototype.id = undefined;

/**
 * @member {String} uniqueGuid
 */
CandidateDto.prototype.uniqueGuid = undefined;

/**
 * @member {module:model/NormalizationStatus} normalizationStatus
 */
CandidateDto.prototype.normalizationStatus = undefined;

/**
 * @member {module:model/NormalizationStatus} skillNormalizationStatus
 */
CandidateDto.prototype.skillNormalizationStatus = undefined;

/**
 * @member {module:model/NormalizationStatus} projectNormalizationStatus
 */
CandidateDto.prototype.projectNormalizationStatus = undefined;

/**
 * @member {String} candidateCode
 */
CandidateDto.prototype.candidateCode = undefined;

/**
 * @member {String} email
 */
CandidateDto.prototype.email = undefined;

/**
 * @member {String} contactNumber
 */
CandidateDto.prototype.contactNumber = undefined;

/**
 * @member {String} status
 */
CandidateDto.prototype.status = undefined;

/**
 * @member {String} slug
 */
CandidateDto.prototype.slug = undefined;

/**
 * @member {Boolean} isFresher
 */
CandidateDto.prototype.isFresher = undefined;

/**
 * @member {String} profileHeadLine
 */
CandidateDto.prototype.profileHeadLine = undefined;

/**
 * @member {String} about
 */
CandidateDto.prototype.about = undefined;

/**
 * @member {Boolean} isAttachedtoJD
 */
CandidateDto.prototype.isAttachedtoJD = undefined;

/**
 * @member {Boolean} isStudent
 */
CandidateDto.prototype.isStudent = undefined;

/**
 * @member {Number} primaryEducationInstituteId
 */
CandidateDto.prototype.primaryEducationInstituteId = undefined;

/**
 * @member {module:model/EducationInstituteDto} primaryEducationInstitute
 */
CandidateDto.prototype.primaryEducationInstitute = undefined;

/**
 * @member {Number} profileImageId
 */
CandidateDto.prototype.profileImageId = undefined;

/**
 * @member {module:model/DocumentDto} profileImage
 */
CandidateDto.prototype.profileImage = undefined;

/**
 * @member {Number} resumeId
 */
CandidateDto.prototype.resumeId = undefined;

/**
 * @member {module:model/DocumentDto} resume
 */
CandidateDto.prototype.resume = undefined;

/**
 * @member {Number} backGroundImageId
 */
CandidateDto.prototype.backGroundImageId = undefined;

/**
 * @member {module:model/DocumentDto} backGroundImage
 */
CandidateDto.prototype.backGroundImage = undefined;

/**
 * @member {Number} collegeImageId
 */
CandidateDto.prototype.collegeImageId = undefined;

/**
 * @member {module:model/DocumentDto} collegeImage
 */
CandidateDto.prototype.collegeImage = undefined;

/**
 * @member {module:model/EmploymentStatusType} employmentStatus
 */
CandidateDto.prototype.employmentStatus = undefined;

/**
 * @member {String} firstName
 */
CandidateDto.prototype.firstName = undefined;

/**
 * @member {String} middleName
 */
CandidateDto.prototype.middleName = undefined;

/**
 * @member {String} lastName
 */
CandidateDto.prototype.lastName = undefined;

/**
 * @member {String} fullName
 */
CandidateDto.prototype.fullName = undefined;

/**
 * @member {String} hobbiesAndInterests
 */
CandidateDto.prototype.hobbiesAndInterests = undefined;

/**
 * @member {String} strengths
 */
CandidateDto.prototype.strengths = undefined;

/**
 * @member {Date} dateOfBirth
 */
CandidateDto.prototype.dateOfBirth = undefined;

/**
 * @member {module:model/GenderType} gender
 */
CandidateDto.prototype.gender = undefined;

/**
 * @member {String} bloodGroup
 */
CandidateDto.prototype.bloodGroup = undefined;

/**
 * @member {String} fatherName
 */
CandidateDto.prototype.fatherName = undefined;

/**
 * @member {String} motherName
 */
CandidateDto.prototype.motherName = undefined;

/**
 * @member {String} maritalStatus
 */
CandidateDto.prototype.maritalStatus = undefined;

/**
 * @member {Array.<module:model/CandidateAndLanguageDto>} candidateLanguages
 */
CandidateDto.prototype.candidateLanguages = undefined;

/**
 * @member {Array.<module:model/CandidateEmailDto>} candidateEmails
 */
CandidateDto.prototype.candidateEmails = undefined;

/**
 * @member {Array.<module:model/CandidatePhoneDto>} candidatePhones
 */
CandidateDto.prototype.candidatePhones = undefined;

/**
 * @member {Array.<module:model/CandidateWebsiteDto>} candidateWebsites
 */
CandidateDto.prototype.candidateWebsites = undefined;

/**
 * @member {Array.<module:model/CandidateAndAddressDto>} candidateAddresses
 */
CandidateDto.prototype.candidateAddresses = undefined;

/**
 * @member {String} nationality
 */
CandidateDto.prototype.nationality = undefined;

/**
 * @member {Array.<module:model/QualificationDto>} qualifications
 */
CandidateDto.prototype.qualifications = undefined;

/**
 * @member {Array.<module:model/EmployerDto>} employers
 */
CandidateDto.prototype.employers = undefined;

/**
 * @member {Array.<module:model/ProjectDto>} projects
 */
CandidateDto.prototype.projects = undefined;

/**
 * @member {String} note
 */
CandidateDto.prototype.note = undefined;

/**
 * @member {Boolean} isMentor
 */
CandidateDto.prototype.isMentor = undefined;

/**
 * @member {Boolean} isApplicableForJDSearch
 */
CandidateDto.prototype.isApplicableForJDSearch = undefined;

/**
 * @member {String} currentEmployer
 */
CandidateDto.prototype.currentEmployer = undefined;

/**
 * @member {String} totalExperienceInMonths
 */
CandidateDto.prototype.totalExperienceInMonths = undefined;

/**
 * @member {Number} totalExperienceInYears
 */
CandidateDto.prototype.totalExperienceInYears = undefined;

/**
 * @member {String} totalExperienceRange
 */
CandidateDto.prototype.totalExperienceRange = undefined;

/**
 * @member {String} gapPeriod
 */
CandidateDto.prototype.gapPeriod = undefined;

/**
 * @member {String} averageStay
 */
CandidateDto.prototype.averageStay = undefined;

/**
 * @member {String} longestStay
 */
CandidateDto.prototype.longestStay = undefined;

/**
 * @member {String} summary
 */
CandidateDto.prototype.summary = undefined;

/**
 * @member {String} executiveSummary
 */
CandidateDto.prototype.executiveSummary = undefined;

/**
 * @member {String} managementSummary
 */
CandidateDto.prototype.managementSummary = undefined;

/**
 * @member {String} currentDesignation
 */
CandidateDto.prototype.currentDesignation = undefined;

/**
 * @member {String} noticePeriodDuration
 */
CandidateDto.prototype.noticePeriodDuration = undefined;

/**
 * @member {Number} currentSalaryId
 */
CandidateDto.prototype.currentSalaryId = undefined;

/**
 * @member {module:model/SalaryDto} currentSalary
 */
CandidateDto.prototype.currentSalary = undefined;

/**
 * @member {Number} expectedSalaryId
 */
CandidateDto.prototype.expectedSalaryId = undefined;

/**
 * @member {module:model/SalaryDto} expectedSalary
 */
CandidateDto.prototype.expectedSalary = undefined;

/**
 * @member {Array.<module:model/CandidateSkillDto>} skills
 */
CandidateDto.prototype.skills = undefined;

/**
 * @member {Number} personalTotal
 */
CandidateDto.prototype.personalTotal = undefined;

/**
 * @member {Number} personalFilled
 */
CandidateDto.prototype.personalFilled = undefined;

/**
 * @member {Number} personalCompletionPercentage
 */
CandidateDto.prototype.personalCompletionPercentage = undefined;

/**
 * @member {Number} contactDetailsTotal
 */
CandidateDto.prototype.contactDetailsTotal = undefined;

/**
 * @member {Number} contactDetailsFilled
 */
CandidateDto.prototype.contactDetailsFilled = undefined;

/**
 * @member {Number} contactDetailsCompletionPercentage
 */
CandidateDto.prototype.contactDetailsCompletionPercentage = undefined;

/**
 * @member {Number} qualificationsTotal
 */
CandidateDto.prototype.qualificationsTotal = undefined;

/**
 * @member {Number} qualificationsFilled
 */
CandidateDto.prototype.qualificationsFilled = undefined;

/**
 * @member {Number} qualificationsCompletionPercentage
 */
CandidateDto.prototype.qualificationsCompletionPercentage = undefined;

/**
 * @member {Number} companyDetailsTotal
 */
CandidateDto.prototype.companyDetailsTotal = undefined;

/**
 * @member {Number} companyDetailsFilled
 */
CandidateDto.prototype.companyDetailsFilled = undefined;

/**
 * @member {Number} companyDetailsCompletionPercentage
 */
CandidateDto.prototype.companyDetailsCompletionPercentage = undefined;

/**
 * @member {Number} skillsTotal
 */
CandidateDto.prototype.skillsTotal = undefined;

/**
 * @member {Number} skillsFilled
 */
CandidateDto.prototype.skillsFilled = undefined;

/**
 * @member {Number} skillsCompletionPercentage
 */
CandidateDto.prototype.skillsCompletionPercentage = undefined;

/**
 * @member {Number} profileCompletionTotal
 */
CandidateDto.prototype.profileCompletionTotal = undefined;

/**
 * @member {Number} profileCompletionFilled
 */
CandidateDto.prototype.profileCompletionFilled = undefined;

/**
 * @member {Number} profileCompletionPercentage
 */
CandidateDto.prototype.profileCompletionPercentage = undefined;

/**
 * @member {Array.<module:model/CandidateCertificateDto>} certificates
 */
CandidateDto.prototype.certificates = undefined;

/**
 * @member {Array.<module:model/CandidateTraitDto>} candidateTraits
 */
CandidateDto.prototype.candidateTraits = undefined;

