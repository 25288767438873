/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {EducationInstituteCompactDtoApiResponse} from '../model/EducationInstituteCompactDtoApiResponse';
import {EducationInstituteCompactDtoIEnumerableApiResponse} from '../model/EducationInstituteCompactDtoIEnumerableApiResponse';
import {EducationInstituteCompactDtoPagedListApiResponse} from '../model/EducationInstituteCompactDtoPagedListApiResponse';
import {EducationInstituteCreateDto} from '../model/EducationInstituteCreateDto';
import {EducationInstituteDtoApiResponse} from '../model/EducationInstituteDtoApiResponse';
import {EducationInstituteEditDto} from '../model/EducationInstituteEditDto';

/**
* EducationInstitute service.
* @module api/EducationInstituteApi
* @version v3.76(UAT)
*/
export class EducationInstituteApi {

    /**
    * Constructs a new EducationInstituteApi. 
    * @alias module:api/EducationInstituteApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiEducationInstituteGet operation.
     * @callback moduleapi/EducationInstituteApi~apiEducationInstituteGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EducationInstituteCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/EducationInstituteApi~apiEducationInstituteGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiEducationInstituteGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EducationInstituteCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/EducationInstitute', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiEducationInstituteGetAllByNameGet operation.
     * @callback moduleapi/EducationInstituteApi~apiEducationInstituteGetAllByNameGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EducationInstituteCompactDtoIEnumerableApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {module:api/EducationInstituteApi~apiEducationInstituteGetAllByNameGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiEducationInstituteGetAllByNameGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'name': opts['name']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EducationInstituteCompactDtoIEnumerableApiResponse;

      return this.apiClient.callApi(
        '/api/EducationInstitute/GetAllByName', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiEducationInstituteGetAllByPageGet operation.
     * @callback moduleapi/EducationInstituteApi~apiEducationInstituteGetAllByPageGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EducationInstituteCompactDtoPagedListApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/EducationInstituteApi~apiEducationInstituteGetAllByPageGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiEducationInstituteGetAllByPageGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EducationInstituteCompactDtoPagedListApiResponse;

      return this.apiClient.callApi(
        '/api/EducationInstitute/GetAllByPage', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiEducationInstituteGetByGuidGuidGet operation.
     * @callback moduleapi/EducationInstituteApi~apiEducationInstituteGetByGuidGuidGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EducationInstituteDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} guid 
     * @param {module:api/EducationInstituteApi~apiEducationInstituteGetByGuidGuidGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiEducationInstituteGetByGuidGuidGet(guid, callback) {
      
      let postBody = null;
      // verify the required parameter 'guid' is set
      if (guid === undefined || guid === null) {
        throw new Error("Missing the required parameter 'guid' when calling apiEducationInstituteGetByGuidGuidGet");
      }

      let pathParams = {
        'guid': guid
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EducationInstituteDtoApiResponse;

      return this.apiClient.callApi(
        '/api/EducationInstitute/GetByGuid/{guid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiEducationInstituteIdDelete operation.
     * @callback moduleapi/EducationInstituteApi~apiEducationInstituteIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EducationInstituteCompactDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/EducationInstituteApi~apiEducationInstituteIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiEducationInstituteIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiEducationInstituteIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EducationInstituteCompactDtoApiResponse;

      return this.apiClient.callApi(
        '/api/EducationInstitute/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiEducationInstituteIdGet operation.
     * @callback moduleapi/EducationInstituteApi~apiEducationInstituteIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EducationInstituteDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/EducationInstituteApi~apiEducationInstituteIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiEducationInstituteIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiEducationInstituteIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EducationInstituteDtoApiResponse;

      return this.apiClient.callApi(
        '/api/EducationInstitute/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiEducationInstitutePost operation.
     * @callback moduleapi/EducationInstituteApi~apiEducationInstitutePostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EducationInstituteDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/EducationInstituteCreateDto} opts.body 
     * @param {module:api/EducationInstituteApi~apiEducationInstitutePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiEducationInstitutePost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EducationInstituteDtoApiResponse;

      return this.apiClient.callApi(
        '/api/EducationInstitute', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiEducationInstitutePut operation.
     * @callback moduleapi/EducationInstituteApi~apiEducationInstitutePutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EducationInstituteDtoApiResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/EducationInstituteEditDto} opts.body 
     * @param {module:api/EducationInstituteApi~apiEducationInstitutePutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiEducationInstitutePut(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer', 'X-RS-Key'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EducationInstituteDtoApiResponse;

      return this.apiClient.callApi(
        '/api/EducationInstitute', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}