/*
 * Extractor Engine API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v3.76(UAT)
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.50
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {PathwayAttributeDto} from './PathwayAttributeDto';
import {PathwayOutcomeDto} from './PathwayOutcomeDto';
import {PathwayStepDto} from './PathwayStepDto';
import {SkillLevel} from './SkillLevel';
import {StatusType} from './StatusType';

/**
 * The PathwayCompactDto model module.
 * @module model/PathwayCompactDto
 * @version v3.76(UAT)
 */
export class PathwayCompactDto {
  /**
   * Constructs a new <code>PathwayCompactDto</code>.
   * @alias module:model/PathwayCompactDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PathwayCompactDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PathwayCompactDto} obj Optional instance to populate.
   * @return {module:model/PathwayCompactDto} The populated <code>PathwayCompactDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PathwayCompactDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('uniqueGuid'))
        obj.uniqueGuid = ApiClient.convertToType(data['uniqueGuid'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = StatusType.constructFromObject(data['status']);
      if (data.hasOwnProperty('isLive'))
        obj.isLive = ApiClient.convertToType(data['isLive'], 'Boolean');
      if (data.hasOwnProperty('isChargeable'))
        obj.isChargeable = ApiClient.convertToType(data['isChargeable'], 'Boolean');
      if (data.hasOwnProperty('amount'))
        obj.amount = ApiClient.convertToType(data['amount'], 'Number');
      if (data.hasOwnProperty('skillLevel'))
        obj.skillLevel = SkillLevel.constructFromObject(data['skillLevel']);
      if (data.hasOwnProperty('mPathwayTypeId'))
        obj.mPathwayTypeId = ApiClient.convertToType(data['mPathwayTypeId'], 'Number');
      if (data.hasOwnProperty('skillId'))
        obj.skillId = ApiClient.convertToType(data['skillId'], 'Number');
      if (data.hasOwnProperty('jdId'))
        obj.jdId = ApiClient.convertToType(data['jdId'], 'Number');
      if (data.hasOwnProperty('companyId'))
        obj.companyId = ApiClient.convertToType(data['companyId'], 'Number');
      if (data.hasOwnProperty('pathwayStep'))
        obj.pathwayStep = ApiClient.convertToType(data['pathwayStep'], [PathwayStepDto]);
      if (data.hasOwnProperty('pathwayAttribute'))
        obj.pathwayAttribute = ApiClient.convertToType(data['pathwayAttribute'], [PathwayAttributeDto]);
      if (data.hasOwnProperty('pathwayOutcome'))
        obj.pathwayOutcome = ApiClient.convertToType(data['pathwayOutcome'], [PathwayOutcomeDto]);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
PathwayCompactDto.prototype.id = undefined;

/**
 * @member {String} uniqueGuid
 */
PathwayCompactDto.prototype.uniqueGuid = undefined;

/**
 * @member {String} title
 */
PathwayCompactDto.prototype.title = undefined;

/**
 * @member {String} description
 */
PathwayCompactDto.prototype.description = undefined;

/**
 * @member {module:model/StatusType} status
 */
PathwayCompactDto.prototype.status = undefined;

/**
 * @member {Boolean} isLive
 */
PathwayCompactDto.prototype.isLive = undefined;

/**
 * @member {Boolean} isChargeable
 */
PathwayCompactDto.prototype.isChargeable = undefined;

/**
 * @member {Number} amount
 */
PathwayCompactDto.prototype.amount = undefined;

/**
 * @member {module:model/SkillLevel} skillLevel
 */
PathwayCompactDto.prototype.skillLevel = undefined;

/**
 * @member {Number} mPathwayTypeId
 */
PathwayCompactDto.prototype.mPathwayTypeId = undefined;

/**
 * @member {Number} skillId
 */
PathwayCompactDto.prototype.skillId = undefined;

/**
 * @member {Number} jdId
 */
PathwayCompactDto.prototype.jdId = undefined;

/**
 * @member {Number} companyId
 */
PathwayCompactDto.prototype.companyId = undefined;

/**
 * @member {Array.<module:model/PathwayStepDto>} pathwayStep
 */
PathwayCompactDto.prototype.pathwayStep = undefined;

/**
 * @member {Array.<module:model/PathwayAttributeDto>} pathwayAttribute
 */
PathwayCompactDto.prototype.pathwayAttribute = undefined;

/**
 * @member {Array.<module:model/PathwayOutcomeDto>} pathwayOutcome
 */
PathwayCompactDto.prototype.pathwayOutcome = undefined;

